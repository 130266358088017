<app-nav-bar></app-nav-bar>
<br />
<div  >

<div class="col-md-12 row">
    <div class="col-md-2">
        <app-composemail></app-composemail>
    </div>
    <div class="col-md-10" id="printSectionId">
        <aside class="lg-side">

            <div  class="inbox-body" *ngFor="let mail of gmaildata" >
                <div class="col-md-10 mailreadmedia" style="    padding: 30px 1px 50px 70px;" id="dashboard">

                    <div class="col-lg-12 row">
                        <div class="col-lg-6" >
                            <span>
                                <h5>From: {{ mail.fromMail }}</h5>
                            </span>
                            <p>
                                To:
                                <!-- <span *ngFor="let mailmem of mail.memlist">
                                    {{ mailmem.useName }},
                                </span> -->
                                <span>
                                    {{ mail.toMail }},
                                </span>
                            </p>
                            <p *ngIf="mail.showccusers">
                                Cc:
                                <!-- <span *ngFor="let mailmem of mail.memlist">
                                    {{ mailmem.useName }},
                                </span> -->
                                <span>
                                    {{ mail.ccMail }},
                                </span>
                            </p>
                        </div>

                        <div class="col-lg-6 ">
                            <span>
                                <p style="float: right; font-size: smaller">
                                    {{ mail.mailDateTime }}
                                </p>
                            </span>
                         
                        </div>
                    </div>

                    <div class="col-lg-12 row">
                        <div class="col-lg-12 ">
                            <h5>Sub: {{ mail.subject }}</h5>
                        </div>
                        <div class="col-lg-12">
                            <!-- <p class="line-breaks" style="text-align: justify;line-height: 1.8rem;font-size: 15px;">{{ mail.body }}</p> -->
                            <!-- <angular-editor  [ngModel]="mail.body" #editor readonly></angular-editor> -->
                            <div [innerHTML]="safeHtmlContent"></div>
                        </div>
                        <div class="col-lg-12 page" *ngFor="let ret of mail.attachments">
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType == 'image/jpeg' || ret.documentType == 'png'||  ret.documentType == 'PNG' ||ret.documentType=='jpeg'||ret.documentType=='jpg'||
                    ret.documentType == 'image/png' 
                  " (click)="ViewImageDoc(ret, content)">
                                <img src="{{ ret.filebloblink }}" style="cursor: pointer; width: 60%; height: 80px;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType == 'application/pdf' ||
                    ret.documentType == 'pdf'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/pdf.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div  class="col-md-4 row" *ngIf="
                    ret.documentType == 'audio/mp3' ||
                    ret.documentType == 'mp3'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/video.png" style="cursor: pointer; width: 100%; height: 100%" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType ==
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    ret.documentType == 'xlsx' ||
                    ret.documentType == 'application/octet-stream'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/Excel.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType ==
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                    ret.documentType == 'pptx' ||
                    ret.documentType == 'ppt'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/ppt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType == 'video/mp4' ||
                    ret.documentType == 'mp4'
                  " (click)="ViewVideo(ret, videocontent)">
                                <img src="assets/img/nvideo.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType ==
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    ret.documentType == 'application/octet-stream' ||
                    ret.documentType == 'doc' ||
                    ret.documentType == 'docx'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/word.jpg" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                    ret.documentType == 'text/plain' ||
                    ret.documentType == 'txt'
                  " (click)="ViewDocument(ret, pdftesting)">
                                <img src="assets/img/textt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                <div id="foldername" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      cursor: pointer;
                      font-variant: unicase;
                    " title="{{ ret.documentName }}">
                                    {{ ret.documentName }}
                                </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                            ret.documentType == 'application/x-zip-compressed' ||
                            ret.documentType == 'x-zip-compressed' || ret.documentType == 'zip'
                          " (click)="ViewDocument(ret, zipfile)">
                                        <img src="assets/img/zip.jpg" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
    
                                        <div id="foldername" style="
                              white-space: nowrap;
                              width: auto;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              cursor: pointer;
                              font-variant: unicase;
                            " title="{{ ret.documentName }}">
                                            {{ ret.documentName }}
                                        </div>
                            </div>
                            <div class="col-md-4 row" *ngIf="
                                    ret.documentType == 'sql'
                                  " (click)="ViewDocument(ret, zipfile)">
                                                <img src="assets/img/sql.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
            
                                                <div id="foldername" style="
                                      white-space: nowrap;
                                      width: auto;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      cursor: pointer;
                                      font-variant: unicase;
                                    " title="{{ ret.documentName }}">
                                                    {{ ret.documentName }}
                                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <hr />
            </div>
        </aside>
    </div>


    <div class="col-md-12 row">
        <div class="col-md-6">  </div>
    <span>
        <button class="btn btn-success" (click)="print2('printSectionId')">Download Mail</button>
    </span>
</div>
<br><br><br>
</div>



<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer">
            {{ imgdisplay.documentName }}
        </h6>
        <h6 style="float: right">Date &nbsp;: &nbsp;{{ date | date: "short" }}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.filebloblink }}" class="img-responsive" alt="" style="height: 300px; width: 400px" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">

                <!-- <button class="btn btn-info" (click)="abc(imgdisplay)">
                    <i class="fa fa-download"></i>Download
                </button> -->
            </div>
        </div>
    </div>
</ng-template>
<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white">
        <div>&nbsp;</div>
        <h6 style="text-align: center">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh"></ngx-doc-viewer>
        <br /><br />


        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
   
                <!-- <button class="btn btn-info" (click)="abc(zzz)">
                    <i class="fa fa-download"></i>Download
                </button> -->
            </div>
        </div>
    </div>
</ng-template>

<!-- Zipfile -->
<ng-template #zipfile let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white">
        <div>&nbsp;</div>
        <h6 style="text-align: center">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

<br>
    <div class="modal-body" style="background-color: white">
    
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">

                <!-- <button class="btn btn-info" (click)="abc(zzz)">
                    <i class="fa fa-download"></i>Download
                </button> -->
            </div>
        </div>
        <br>
    </div>

</ng-template>



<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white">
        <div>&nbsp;</div>
        <h6 style="float: left">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>

    <div class="modal-body" style="width: 883px; background-color: white">
        <video controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">

    <div class="modal-body" style="width: 883px; background-color: white">
        <audio controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>

</div>