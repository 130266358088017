import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { GmailmigrationService } from 'src/app/Shared/GmailMigration/gmailmigration.service';
import { GroupService } from 'src/app/Shared/Group/group.service';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MovesService } from 'src/app/Shared/MoveService/moves.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { Friend } from 'src/app/ViewModels/Friend';
import { ShareIdShow } from 'src/app/ViewModels/shareidshow';

@Component({
  selector: 'app-composemail',
  templateUrl: './composemail.component.html',
  styleUrls: ['./composemail.component.css']
})
export class ComposemailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  useridformail: any;
  selectedticketid: any;
  TicketsList: any;
  ToUsersForMail: any;
  mailsubject: any;
  message: any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selected = []; CurrentFiles: any;
  Friends: any;
  frndslist: any = [];
  accid: any;
  folderdisaply: boolean;
  subfolderdisaply: boolean;
  allfoldersdisplay: boolean;
  subfolderdetails: any;
  currentfoldername: any;
  FileFoldID: any;
  allSubFolders: any;
  isvisible: boolean;
  selectedSelected: any = [];
  AttachmnetsList: any;
  HaspitFiles: string;
  SubFoldersList: any;
  foldernamelist: any;
  allFolders: any;
  sharedfolderdisaply: boolean;
  morefiles: boolean;
  allFoldersList: any;
  fileslist: any;
  fid: any;
  allFolders1: any;
  subf: any;
  remove: boolean;
  blocdriveFiles: any;
  email: any = [];
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  serchfind: boolean;
  errorMessages: string;
  install: boolean;
  friends: boolean = false;
  friend: any;

  expands: boolean = true;
  compress: boolean = false;
  filename: any;
  filenameone: any;
  filenametwo: any;
  selectedattachments: any = [];
  docname2: any;
  docname1: any;
  docname3: any;
  docname5: any;
  docname4: any;
  docname6: any;
  docname7: any;
  docname8: any;
  docname9: any;
  docname10: any;
  docname11: any;
  docname12: any;
  docname13: any;
  docname14: any;
  docname15: any;
  docname16: any;
  docname17: any;
  docname18: any;
  docname19: any;
  docname20: any;
  dat: any;
  color: boolean;
  filenamethree: any;
  filenamefour: any;
  filenamefive: any;
  filenamesix: any;
  filenameseven: any;
  filenameeigth: any;
  filenamenine: any;
  filenameten: any;
  filenameeleven: any;
  filenametwelve: any;
  filenamethirteen: any;
  filenamefourteen: any;
  filenamefifteen: any;
  filenamesixteen: any;
  filenameseventeen: any;
  filenameeighteen: any;
  filenamenineteen: any;
  filenametwenty: any;
  progress: boolean;
  fnamee: any;
  filenam: any;
  fnameeintrnl: any;
  filenamintrnl: any;
  frnlist: any = [];
  selsel: any = [];
  closeResult: string;
  hideupload: boolean = true;
  selgp: any;
  showtwobut: boolean;
  select: boolean = true;
  GroupList: any;
  guidd: any;
  grpusers: any=[];
  frnlistgp: any = [];
  notenterprise:boolean=false;
  notify: string;
  dismissalert: boolean = false;
  showccmail: boolean;
  emailq: any=[];
  selectedFriend1: any =[];
  frndslist1: any = [];
  selselcc: any = [];
  signdata: any;
  showeditsign: boolean = false;
  mail: any;
  urlread: string;
  parts: string[];
  routeindex: string;

  constructor(private router: ActivatedRoute, private auditortransactionservice: AuditorTransactionService, private userservice: UserDetailsService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private spinner: NgxSpinnerService,
    private datepipe: DatePipe, private http: HttpClient, private mailservice: MailService, private domSanitizer: DomSanitizer,
    private groupservice: GroupService,   private gmailservice : GmailmigrationService,) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentID");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      if(this.roleid!= "1"){
        this.select = false;
        this.notenterprise = true;
      }
    }
    if (window.innerWidth <= 1200) {
      setTimeout(() => {
        this.expands = false;
        this.compress = false;
      }, 10);
    }
    this.folderdisaply = true;
    TagInputModule.withDefaults({
      tagInput: {
        placeholder: 'Add email address',
        secondaryPlaceholder: 'Add email address',
      },

    });
  }

  ngOnInit(): void {
    //this.GetUserTickets();
    this.GetFriendDetails();
    this.GetAllFolders();
    this.GetAllFolders1();
    this.GetMailStatus();
    this.UserGroup();
    this.GetSignatures();
    this.urlread =  window.location.href;
  }
  UserGroup() {
    this.spinner.show();
    this.groupservice.getgroup(this.GUID ).subscribe((Data: any) => {
      this.GroupList = Data;
      this.spinner.hide();
    });
  }
  
  GetSignatures() {
    this.userservice.getsigns(this.uid ).subscribe((Data: any) => {
      if(Data  != null){
        this.signdata = Data.signature;
        this.showeditsign = true;
      }
      else 
      this.showeditsign = false;
    });
  }

  expand() {
    document.getElementById("myOverlay").style.width = "200%";
    document.getElementById("myOverlay").style.marginLeft = "-42%";
    document.getElementById("myOverlay").style.height = "600px;";
    this.expands = false;
    this.compress = true;
    if (window.innerWidth <= 1000) {
      setTimeout(() => {
        this.expands = false;
        this.compress = false;
        document.getElementById("myOverlay").style.width = "100%";
        document.getElementById("myOverlay").style.marginLeft = "0";
      }, 10);
    }
  }
  compres() {
    document.getElementById("myOverlay").style.width = "100%";
    document.getElementById("myOverlay").style.marginLeft = "0";
    // document.getElementById("myOverlay").style.height = "0";
    this.expands = true;
    this.compress = false;
  }
  GetUserTickets() {
    this.spinner.show()
    this.mailservice.getUserTickets(this.uid).subscribe((data: any) => {
      this.TicketsList = data;
      this.spinner.hide()
    })
  }
  onSelectTicket(data) {
    this.selectedticketid = data;
  }
  GetUsersforMail() {
    this.spinner.show()
    this.mailservice.getUsersformail(this.uid).subscribe((data: any) => {
      this.ToUsersForMail = data;
      console.log(this.ToUsersForMail);
      this.spinner.hide()
    })
  }
  //......   onItemRemoved(value: any) {
  //     //this.selecteduserlist.pop(value.display);
  //     for (var i = 0; i < this.frndslist.length; i++) {
  // //......    if (this.frndslist[i].Email == value.display) {
  //         if (this.frndslist[i].email == value.display) {
  //         var index = this.selectedFriend.indexOf(value);
  //         this.selectedFriend.splice(index, 1);
  //       }
  //     }
  //     this.errorMessages = "";
  //......   }

  //......
  Selectallmail() {
    this.selectedFriend = [];
    this.email = [];
    this.frnlist = this.frndslist;
    for (var i = 0; i < this.frnlist.length; i++) {
      if (this.frnlist[i].email != undefined && this.frnlist[i].email != null) {
        this.email.push(this.frnlist[i].email)
        this.selectedFriend.push(this.frnlist[i].email)
        //for removing duplicates from array list
        this.selectedFriend = Array.from(new Set(this.selectedFriend));
        this.email = Array.from(new Set(this.email));
      }
      else if (this.frnlist[i].includes('@')) {
        this.email.push(this.frnlist[i])
        this.selectedFriend.push(this.frnlist[i])
        //for removing duplicates from array list
        this.selectedFriend = Array.from(new Set(this.selectedFriend));
        this.email = Array.from(new Set(this.email));
      }
    }
  }

  Select() {
    this.showtwobut = true;
    this.select = false;

  }

  selectgrpname(selectgrpname) {
    // this.selectedFriend = [];
    // this.email = [];
    this.selgp = selectgrpname.id;
    this.groupservice.getgrpusers(this.selgp).subscribe((data: any) => {
      this.grpusers = data;
      this.frnlistgp = this.frndslist;

      for (var i = 0; i < this.frnlistgp.length; i++) {
        for (var j = 0; j < this.grpusers.length; j++) {
          if (this.frnlistgp[i].uid == this.grpusers[j].userId) {
            this.email.push(this.frnlistgp[i].email)
            this.selectedFriend.push(this.frnlistgp[i].email)
              //to remove duplicates from array
              this.email = Array.from(new Set(this.email));
              this.selectedFriend = Array.from(new Set(this.selectedFriend));
          }

        }
      }



      console.log(this.grpusers);
      this.spinner.hide()
    })

  }




  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      //......    if (this.frndslist[i].Email == value.display) {
      if (this.frndslist[i].email == value.display && value.email != undefined) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
      else if (value == this.frndslist[i].email) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }
  //......
  onItemAdded(value: any) {
    if (value.email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else {
      alert("Oops!! This person not in your Friends list.Let us add him/her,click Friend Request.");
      this.friends = true;
      this.friend = this.email;
      this.selectedFriend = "";
      // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }
  onItemRemoved1(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      //......    if (this.frndslist[i].Email == value.display) {
      if (this.frndslist[i].email1 == value.display && value.email1 != undefined) {
        var index = this.selectedFriend1.indexOf(value);
        this.selectedFriend1.splice(index, 1);
      }
      else if (value == this.frndslist[i].email1) {
        var index = this.selectedFriend1.indexOf(value);
        this.selectedFriend1.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }
  //......
  onItemAdded1(value: any) {
    if (value.email1 == undefined) {
      this.emailq = value.value;
    }
    else {
      this.emailq = value.email1;
    }
    this.serchfind = this.regexp.test(this.emailq);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend1.push(value);
    }
    else {
      alert("Oops!! This person not in your Friends list.Let us add him/her,click Friend Request.");
      this.friends = true;
      this.friend = this.emailq;
      this.selectedFriend1 = "";
      // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }


  GetFriendDetails() {
    this.userservice.GetFriendRequests(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends;
      interface MyObject {
        email: string;
        uid: string;
        email1:string
      }
      let newObject: MyObject = { email: this.usermail, uid: this.usermail,email1 : this.usermail };

      this.frndslist.push(newObject);
      //  this.frndslist.push(this.usermail);
      console.log(this.frndslist);
      // console.log(this.frndslist);
      var idd = this.accid;
      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your Friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }
  selectedFriend: any = [];
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  onSelectUserformail(data) {
    this.useridformail = data;
  }
  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
      this.color = true;
    }
    var usr = {
      UserId: this.uid
    }
    this.frmDataa.append("UserIDD", JSON.stringify(usr));
    // if(files.length == 1){
    this.progress = true;
    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSavesinglefile/', this.frmDataa).subscribe((data: any) => {
      this.progress = false;
      this.dat = data;
      this.color = true;
      // this.delfile = true;
      this.spinner.hide()
    })

    // }

    // this.docname1 = this.SelectedFiles[0].name ;
    // this.docname2 = this.SelectedFiles[1].name ;
    // this.docname3 = this.SelectedFiles[2].name ;
    // this.docname4 = this.SelectedFiles[3].name ;
    // this.docname5 = this.SelectedFiles[4].name ;
    // this.docname6 = this.SelectedFiles[5].name ;
    // this.docname7 = this.SelectedFiles[6].name ;
    // this.docname8 = this.SelectedFiles[7].name ;
    // this.docname9 = this.SelectedFiles[8].name ;
    // this.docname10 = this.SelectedFiles[9].name ;
    // this.docname11 = this.SelectedFiles[10].name ;
    // this.docname12 = this.SelectedFiles[11].name ;
    // this.docname13 = this.SelectedFiles[12].name ;
    // this.docname14 = this.SelectedFiles[13].name ;
    // this.docname15 = this.SelectedFiles[14].name ;
    // this.docname16 = this.SelectedFiles[15].name ;
    // this.docname17 = this.SelectedFiles[16].name ;
    // this.docname18 = this.SelectedFiles[17].name ;
    // this.docname19 = this.SelectedFiles[18].name ;
    // this.docname20 = this.SelectedFiles[19].name ;
  }

  RemoveFile(data) {
    var det = {
      FileName: data.name,
      UserId: this.uid,
    }

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostRemoveFile/', det).subscribe((data: any) => {
      this.fnamee = data;
      this.filenam = this.fnamee.documentName;
      for (let ri = 0; ri < this.SelectedFiles.length; ri++) {
        if (this.filenam == this.SelectedFiles[ri].name) {
          const index1 = this.SelectedFiles.indexOf(this.SelectedFiles[ri], 0);
          this.SelectedFiles.splice(index1, 1);
        }
      }

    })

  }
  RemoveInternalFile(data) {
    var det = {
      FileName: data.documentName,
      UserId: this.uid,
    }

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostRemoveInternalFile/', det).subscribe((data: any) => {
      this.fnameeintrnl = data;
      this.filenamintrnl = this.fnameeintrnl.documentName;
      for (let ri = 0; ri < this.selectedattachments.length; ri++) {
        if (this.filenamintrnl == this.selectedattachments[ri].documentName) {
          const index1 = this.selectedattachments.indexOf(this.selectedattachments[ri], 0);
          this.selectedattachments.splice(index1, 1);
        }
      }

    })

  }
  addSign(addsign) {
    this.modalService.open(addsign).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

addGmail(addgmail) {
  this.modalService.open(addgmail).result.then((result) => {
  this.closeResult = `Closed with: ${result}`;
}, (reason) => {
  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
});
}
addsignature(){
  const signinfo={
      Id: 0,
      UserId : this.uid,
      Signature : this.signdata
  }
  this.spinner.show();
  this.userservice.postsigndata(signinfo).subscribe((data:any) => {
    if(data != null){
      this.notify ="Signature added successfully";
    }
    else
    this.notify ="SOmething went wrong";
    this.spinner.hide();
  })
}

editsignature(){
  const signinfo={
    Id: 0,
    UserId : this.uid,
    Signature : this.signdata
}
this.spinner.show();
this.userservice.posteditsigndata(signinfo).subscribe((data:any) => {
  if(data == "Success"){
    alert("Signature updated successfully");
  }
  else
  this.notify ="SOmething went wrong";
  this.spinner.hide();
})
}

  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['composemsg']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }

  cancelmail() {
    this.route.navigate(['inboxmails']);

  }
  gmailallmail(){
    this.route.navigate(['gmailmail']);
  }

  composemail1(addbranch) {
    this.modalService.open(addbranch).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  attapop(addbranch1) {
    this.modalService.open(addbranch1).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  attapop1(addbranch2) {
    this.modalService.open(addbranch2).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }


  savegmail(){
    const signinfo={
      EmailId : this.mail,
      UserId : this.uid
  }
  this.spinner.show();
  this.gmailservice.postgmailmigrate(signinfo).subscribe((data:any) => {
    if(data != null){
      this.notify ="Gmail mails migrated successfully";
    }
    else
    this.notify ="Something went wrong";
    this.spinner.hide();
  })
  }




  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.friend,
      UserId: this.uid,
      Status: 4,
      IsEnabled: true,
      GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please enter your Friend mailid!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already Friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using Blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request sent successfully!!!");
        const audit = {
          TransactionName: "Friend Request",
          TransactionType: "Send",
          TransactionDesc: "Friend Request",

        }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";

    });
  }
  sendmail() {
    //......
    for (var j = 0; j < this.selectedFriend.length; j++) {

      if (this.selectedFriend[j].email == undefined) {
        for (var m = 0; m < this.frndslist.length; m++) {
          if (this.frndslist[m].email == this.selectedFriend[j]) {
            this.selsel.push(this.frndslist[m])
            break;
          }
        }
      }
      else if (this.selectedFriend[j].email != undefined) {
        for (var n = 0; n < this.frndslist.length; n++) {
          if (this.frndslist[n].email == this.selectedFriend[j].email) {
            this.selsel.push(this.frndslist[n])
            break;
          }
        }
      }
    }
    //for removing duplicates from an array
    this.selsel = Array.from(new Set(this.selsel));
    //to add new field to existing array
       this.selsel.forEach(item => {
      item.ccopy = "no";
    });
    //......

 //......
 for (var j = 0; j < this.selectedFriend1.length; j++) {

  if (this.selectedFriend1[j].email1 == undefined) {
    for (var m = 0; m < this.frndslist.length; m++) {
      if (this.frndslist[m].email1 == this.selectedFriend1[j]) {
        this.selselcc.push(this.frndslist[m]);
        break;
      }
    }
  }
  else if (this.selectedFriend1[j].email1 != undefined) {
    for (var n = 0; n < this.frndslist.length; n++) {
      if (this.frndslist[n].email1 == this.selectedFriend1[j].email1) {
        this.selselcc.push(this.frndslist[n])
        break;
      }
    }
  }
}
//for removing duplicates from an array
this.selselcc = Array.from(new Set(this.selselcc));
this.selselcc.forEach(item => {
  item.ccopy = "yes";
});
console.log(this.selselcc);


this.selsel.push.apply(this.selsel, this.selselcc);
console.log(this.selsel);

//......


    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      TicketId: this.selectedticketid,
      FromId: this.uid,
      //...... MultiToUser: this.selectedFriend,
      MultiToUser: this.selsel,
      Blocdrivedoc: this.selectedSelected,
      blocdriveattachments: this.selectedattachments,
      Subject: this.mailsubject,
      Message: this.message,
      FromuserGUID: this.GUID
    }
    //this.frmDataa.append("Drive", this.selectedSelected[i]);
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.frmDataa.append("Internal", "Internal");
    this.spinner.show();
    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSaveNewMail/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        this.selectedFriend = this.selectedSelected;
     //......   alert("Mail sent successfully!!!");
        const audit = {
          TransactionName: "Mail",
          TransactionType: "Send",
          TransactionDesc: "Compose Mail",

        }
        this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
        // this.mailsubject = "";
        // this.message = "";

        // window.location.reload();
        // this.route.navigate(['sentmails']);
        // Show the notification
this.notify = "Mail sent successfully!!!";
this.dismissalert = true;

// Delay for 3 seconds and then hide the notification and reload the page
setTimeout(() => {
  this.dismissalert = false;
  this.parts = this.urlread.split('/#/');
  if (this.parts.length === 2) {
    this.routeindex = this.parts[1];
   if(this.routeindex == "inboxmails"){
    window.location.reload();
   }
   else{
    localStorage.setItem("Showinbox","True");
    this.route.navigate(['inboxmails']);
   }
 

  } 

}, 2000);
//

      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });

  }
  ccmail(){
    this.showccmail = true;
  }

  GetMailStatus() {

    this.mailservice.getmailstatus(this.uid).subscribe((response: any) => {
      this.install = true;
      if (this.install == response) {
        this.install = true;
      }
      else if (false == response) {
        this.install = false;
      }
    })

  }


  MailFriend() {
    this.route.navigate(['/friendrequest']).then(() => {
      window.location.reload();
    })
  }
  // haspit section
  onClick(data) {
    this.CurrentFiles = data;

    if (data != null) {
      if (this.selectedattachments.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedattachments.push(data);
      }
      else {
        const index = this.selectedattachments.indexOf(data, 0);
        if (index > -1) {
          this.selectedattachments.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedattachments.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }

    }

  }
  // onClick(data) {
  //   this.CurrentFiles = data;
  //   if (data != null) {
  //     for (var i = 0; i < this.selectedSelected.length; i++) {
  //       if (this.selectedSelected[i].Id == data.Id) {
  //         var index = this.selected.indexOf(data);
  //         this.selectedSelected.splice(index, 1);
  //         this.remove = true;
  //       }
  //     }
  //     this.selectedSelected.push(data);
  //   }

  // }
  uploadmypersonalfiles() {

    this.frmDataa = new FormData();
    var filedata = {
      InternalBdrive: this.selectedattachments
    }
    var usr = {
      UserId: this.uid,
    }
    this.frmDataa.append("UserIDD", JSON.stringify(usr));
    this.frmDataa.append("Attach", JSON.stringify(filedata));

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSavesinglefileInternal/', this.frmDataa).subscribe((data: any) => {
      // this.hideupload = false;
    })

    // this.filename = this.selectedattachments[0].documentName;
    // this.filenameone = this.selectedattachments[1].documentName;
    // this.filenametwo = this.selectedattachments[2].documentName;
    // this.filenamethree = this.selectedattachments[3].documentName;
    // this.filenamefour = this.selectedattachments[4].documentName;
    // this.filenamefive = this.selectedattachments[5].documentName;
    // this.filenamesix = this.selectedattachments[6].documentName;
    // this.filenameseven = this.selectedattachments[7].documentName;
    // this.filenameeigth = this.selectedattachments[8].documentName;
    // this.filenamenine = this.selectedattachments[9].documentName;
    // this.filenameten = this.selectedattachments[10].documentName;
    // this.filenameeleven = this.selectedattachments[11].documentName;
    // this.filenametwelve = this.selectedattachments[12].documentName;
    // this.filenamethirteen = this.selectedattachments[13].documentName;
    // this.filenamefourteen = this.selectedattachments[14].documentName;
    // this.filenamefifteen = this.selectedattachments[15].documentName;
    // this.filenamesixteen = this.selectedattachments[16].documentName;
    // this.filenameseventeen = this.selectedattachments[17].documentName;
    // this.filenameeighteen = this.selectedattachments[18].documentName;
    // this.filenamenineteen = this.selectedattachments[19].documentName;
    // this.filenametwenty = this.selectedattachments[20].documentName;



    // this.isvisible = true;
    // this.frmDataa = new FormData();
    // for (var i = 0; i < this.selectedSelected.length; i++) {
    //   // this.frmData = new FormData();
    //   this.AttachmnetsList.push(this.selectedSelected[i].documentName);
    //   this.frmDataa.append("Drive", this.selectedSelected[i]);
    //   this.blocdriveFiles = this.selectedSelected[i].documentName;

    // }
  }

  GetSubfolderdetails(data) {
    this.folderdisaply = false;
    this.subfolderdisaply = true;
    this.allfoldersdisplay = false;
    this.subfolderdetails = data;
    this.currentfoldername = data.folderName;
    this.FileFoldID = data.folderId;
    this.GetPFolderlist(data.folderId);
    this.spinner.show();
    const dataaa: ShareIdShow = {
      EID: "",
      Fid: data.folderId,
      LoginUID: this.uid
    }
    this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.spinner.hide();
      for (let i = 0; i < this.allSubFolders.retData.length; i++) {
        if (this.allSubFolders.retData[i].file != null) {
          //......   this.allSubFolders.retData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.retData[i].file)
          this.allSubFolders.retData[i].file = this.allSubFolders.retData[i].file;

          if (this.allSubFolders.retData[i].DocumentType == 'image/jpeg' || this.allSubFolders.retData[i].DocumentType == 'image/png'
            || this.allSubFolders.retData[i].DocumentType == 'png' || this.allSubFolders.retData[i].DocumentType == 'jpeg' || this.allSubFolders.retData[i].DocumentType == 'jpg'
            || this.allSubFolders.retData[i].DocumentType == 'PNG') {
            this.SubFoldersList.push(this.allSubFolders.retData[i]);
          }
        }
      }

    });
  }
  GetPFolderlist(id) {
    // this.spinner.show();
    this.mailservice.getpfoldernames(id).subscribe((respose: any) => {
      // this.spinner.hide();
      this.foldernamelist = respose;
      console.log(this.foldernamelist);
    });
  }

  GetMypersonalfolders() {
    this.foldernamelist = null;
    this.currentfoldername = null;
    this.mailservice.getfolders(this.uid).subscribe((respose: any) => {
      this.allFolders = respose;
      this.fileslist = this.allFolders.retData;
      for (var i = 0; i < this.fileslist.length; i++) {
        if (this.fileslist[i].DocumentType == 'image/jpeg' || this.fileslist[i].DocumentType == 'image/png'
          || this.fileslist[i].DocumentType == 'png' || this.fileslist[i].DocumentType == 'jpeg' || this.fileslist[i].DocumentType == 'jpg'
          || this.fileslist[i].DocumentType == 'PNG') {
          var dataa = this.fileslist[i];
          this.allFoldersList.push(dataa);
        }
      }
      this.folderdisaply = true;
      this.subfolderdisaply = false;
      this.spinner.hide();
    });
    this.folderdisaply = true;
    this.sharedfolderdisaply = true;
  }

  closecurrentpopup() {
    var a = document.getElementById("closetwo").click();
    var a = document.getElementById("closeshare").click();
    var a = document.getElementById("closedelete").click();
    this.folderdisaply = true;
    this.subfolderdisaply = false;
    this.sharedfolderdisaply = true;
    this.sharedfolderdisaply = false;
    //this.GetAllSharingFolders();
  }

  selectfile(data) {
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
        document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          document.getElementById(data.Id.toString()).style.backgroundColor = "";
        }
        else {
          this.selectedSelected.push(data);
          document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
      }
    }
    if (this.selectedSelected.length <= 1) {
      this.morefiles = false;
    }
    else if (this.selectedSelected.length >= 2) {
      this.morefiles = true;
    }
  }
  GetAllFolders() {
    //this.spinner.show();
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders = respose;
      // console.log("user", this.allFolders)
    });
  }
  GetAllFolders1() {
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders1 = respose;
      // console.log("user", this.allFolders);
      // this.spinner.hide();
    });
  }

  GetDetails(details) {
    this.folderdisaply = false;
    this.subfolderdisaply = true;
    this.FileFoldID = details.folderID;
    this.currentfoldername = details.folderName;
    // this.spinner.show();
    this.allfoldersdisplay = false;
    this.GetPFolderlist(details.folderID);
    const dataaa: ShareIdShow = {
      EID: "",
      Fid: details.folderID,
      LoginUID: this.uid
    }
    this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.currentfoldername = this.allSubFolders.FileName;
      this.fid = this.allSubFolders.ParentId;
      // this.spinner.hide();
      for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
        if (this.allSubFolders.RetData[i].file != null) {
          this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file);
          if (this.allSubFolders.RetData[i].DocumentType == 'image/jpeg' || this.allSubFolders.RetData[i].DocumentType == 'image/png'
            || this.allSubFolders.RetData[i].DocumentType == 'png' || this.allSubFolders.RetData[i].DocumentType == 'jpeg' || this.allSubFolders.RetData[i].DocumentType == 'jpg'
            || this.allSubFolders.RetData[i].DocumentType == 'PNG') {
            this.SubFoldersList.push(this.allSubFolders.RetData[i]);
          }
        }
      }
    });
  }
}
