<div class="header-container">
  <a class="navbar-brand" href="#">
    <img src="assets/img/blocdriverz.png" class="img-fluid" alt="Blocdriverz Logo" />
    <h6>Securing Data</h6>
  </a>

  <button class="nav-toggle" (click)="toggleNav()" aria-label="Toggle navigation">
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </button>

  <nav class="navigation navmedia" [class.open]="isNavOpen">
    <ul>
      <li class="nav-item active">
        <a class="nav-link" href="" [routerLink]="['/howto']">Contact Us</a>
      </li>
      <li class="nav-item active" *ngIf="!isLoggedIn">
        <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" [routerLink]="['/help']">Help</a>
      </li>
      <li class="nav-item active" *ngIf="!isLoggedIn">
        <a class="nav-link" [routerLink]="['/login']">Sign In</a>
      </li>
      <li class="active" *ngIf="isLoggedIn">
        <a href="#/dashboard" class="text-white">MySpace</a>
      </li>
      <li class="active" *ngIf="isLoggedIn">
        <div class="dropdown">
          <p *ngIf="roleid != 1">{{username}}<i class="fa fa-caret-down" aria-hidden="true"></i></p>
          <p *ngIf="roleid == 1">{{firstname}}<i class="fa fa-caret-down" aria-hidden="true"></i></p>
          <div class="dropdown-content">
            <p [routerLink]="['/profile']">My Account</p>
            <p (click)="logout()">Logout</p>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>






<div class="video-container">
  <video src="assets/img/blocks12.mp4" [muted]="'muted'" loop autoplay style=" left:0 !important;
  right:0 !important;"></video>
  <div class="content">
      <div class="row col-12"
          style="height: auto; display: flex; align-items: center; justify-content: center; padding: 10px 10px;">
          <h2 class="text-center">Secured by Smart Contracts</h2>
          <h5 class="text-center" style="padding: 10px;">
              The blocdrive system protects both personal and business data. Every piece of data is saved in a block. As a result, there is no need for data protection or assurance. Overall, the system will be beneficial in all sectors.
          </h5>
      </div>
  </div>
</div>


<!-- 3nd nav -->
<div class="row knowmoremedia bg-light">
  <div class="fadeInDelay">
    <div class="row align-items-center g-5">
      <div class="col-lg-6 knowmoremedia1 text-center">
        <img src="assets/img/blocks41.png" loading="lazy" class="fadeInDelay img-fluid" alt="Card Image">
      </div>
      <div class="col-lg-5 knowmoremedia2">
        <div class="text-center text-lg-start">
          <p class="para display-4 font-weight-bold">Why Blocdrive?</p>
          <p class="para h5">Secured by Smart Contracts</p>
          <p class="para justify-content">The blocdrive system protects both personal and business data.<br>
            Every piece of data is saved in a block.<br>
            As a result, there is no need for data protection or assurance.<br>
            Overall, the system will be beneficial in all sectors.
          </p>
          <br>
          <button class="btn btn-primary btn-lg" (click)="knowabtblocdrive()" href="#showhidevalue">Know more</button>
        </div>
      </div>
    </div>
    <br>
  </div>
</div>



<!-- 4rd nav -->
<div class="container my-5">
  <div class="row text-center mb-5">
    <h2 class="col-12 font-weight-bold">Features</h2>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/store1.png" class="card-img-top" alt="store">
        <div class="card-body">
          <h5 class="card-title">STORE</h5>
          <p class="card-text text-muted">Blocdrive provides a service to store your folder with high security.</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/restore1.png" class="card-img-top" alt="restore">
        <div class="card-body">
          <h5 class="card-title">RESTORE</h5>
          <p class="card-text text-muted">If you delete a folder or object by mistake, No worries you can restore it.</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/organizer1.png" class="card-img-top" alt="organize">
        <div class="card-body">
          <h5 class="card-title">ORGANISE</h5>
          <p class="card-text text-muted">Blocdrive user can also organise his Folder/Object as he wishes.</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/chat11.png" class="card-img-top" alt="chat">
        <div class="card-body">
          <h5 class="card-title">CHAT</h5>
          <p class="card-text text-muted">Your friend and you are connected! Now you can share objects/folders or chat with them!</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/share1.png" class="card-img-top" alt="share">
        <div class="card-body">
          <h5 class="card-title">SHARE</h5>
          <p class="card-text text-muted">Just click and share your objects and folders.</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/download1.png" class="card-img-top" alt="download">
        <div class="card-body">
          <h5 class="card-title">DOWNLOAD</h5>
          <p class="card-text text-muted">Download all your files and chat backup</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/edit1.png" class="card-img-top" alt="edit">
        <div class="card-body">
          <h5 class="card-title">EDIT</h5>
          <p class="card-text text-muted">Edit your object name and also edit your document by editor</p>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-3 mb-3 d-flex">
      <div class="card mx-auto">
        <img src="assets/img/objreq1.png" class="card-img-top" alt="objectrequest">
        <div class="card-body">
          <h5 class="card-title">OBJECT REQUEST</h5>
          <p class="card-text text-muted">Invite anyone to upload files to your blocdrive, even if they don't have an account.</p>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 5th div -->

<div class="row showmore" id="showhidevalue" style="background-color: #f2f2f2;">
  <div class="col-lg-6 col-md-8 col-sm-12 showmore1">
      <p class="enterprise-title">Enterprise Benefits</p>
      <p class="enterprise-subtitle">
          Create a team to assist you in assigning tasks<br>
          and dividing responsibilities.
      </p>
      <p class="enterprise-description">
          It is a framework which allows initiating, planning,
          implementing, prioritizing duties,<br> monitoring tasks,
          influencing, and completing a team's work to achieve<br>
          specific goals and specific requirements.
      </p>
  </div>
  <div class="col-lg-4 col-md-8 col-sm-12 showmore2">
      <img src="assets/img/blocks511.png" loading="lazy" class="fadeInDelay" alt="Card Image">
  </div>

  <div class="container">
    <div class="row cardmedia">
        <div class="col-md-6 col-lg-3 mb-4" style="border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;">
            <img src="assets/img/emailinternal.png" loading="lazy" alt="Mail System">
            <h2>Mail System</h2>
            <p>A mailing system is a piece of software that automates the planning, computation, outcome, and distribution of your company's outgoing mail.</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4" style="border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;">
            <img src="assets/img/electronic-signature1.png" loading="lazy" alt="E-Signature">
            <h2>E-Signature</h2>
            <p>It offers the best PDF tools for quickly preparing documents and collecting electronic signatures digitally.</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4" style="border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;">
            <img src="assets/img/srichiddrive1.png" loading="lazy" alt="Srichid Drive">
            <h2>Srichid Drive</h2>
            <p>Windows Based Drive can be used offline by downloading to your local system. So any changes made in a local system it will reflect as it is in the Blocdrive.</p>
        </div>
        <div class="col-md-6 col-lg-3 mb-4" style="border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;">
            <img src="assets/img/gdtobd1.png" loading="lazy" alt="Google Drive">
            <h2>Google Drive</h2>
            <p>Migrating data from Googledrive to Blocdrive</p>
        </div>
    </div>
  </div>
</div>


<div class="text-center p-5">
  <h1>Plans</h1>
  <div class="planmedia">
      <div class="block d-inline-flex">
          <div class="p-4 p-sm-5" style="margin-top: 0;">
              <sup class="text-sm" style="top: -0.5em;">₹</sup>
              <span class="h1">1</span>
              <div class="text-muted">Individuals Plan</div>
              <div class="py-4">
                  <a href="/payment" class="btn" data-abc="true">Purchase</a>
              </div>
              <small class="text-muted">Start <strong>for</strong> free</small>
          </div>
          <div class="p-4 p-sm-5" style="margin-top: 0;">
              <sup class="text-sm" style="top: -0.5em;">₹</sup>
              <span class="h1">4500</span>
              <div class="text-muted">Enterprise Plan</div>
              <div class="py-4">
                  <a href="/payment" class="btn" data-abc="true">Purchase</a>
              </div>
              <small class="text-muted">Buy the Plan</small>
          </div>
      </div>
  </div>
  <br>
  <!-- <app-support-chat></app-support-chat> -->
</div>
