import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoveFolderOrDocument } from 'src/app/ViewModels/MoveFolderOrDocument';
import { MoveDocData } from 'src/app/ViewModels/MoveDocData';

@Injectable({
  providedIn: 'root'
})
export class MovesService {
  readonly url = "https://blocdrive.com/blocdriveapi/api/";
  constructor(private http: HttpClient) {  }

  saveMoveFolderOrDocument(data: MoveFolderOrDocument) {
    return this.http.post(this.url + "MoveFolderOrDocument/PostMoveFolderOrDocument", data);
  }
  moveDocument(data: MoveDocData){
    return this.http.post(this.url + "MoveFolderOrDocument/PostMoveDocument", data);
  }
}
