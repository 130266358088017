<app-nav-bar></app-nav-bar>
<br />
<div class="col-md-12 row">
    <div class="col-md-3">
        <app-composemail></app-composemail>
    </div>
    <div class="col-md-9">
        <aside class="lg-side">
            <div class="inbox-head">
                <div class="col-md-8">
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                    <br />
                </div>

            </div>
            <div  style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                Name
                <span class="fa fa-stack">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                </span>
            </div>
            <div class="inbox-body">
                <table class="table table-hover">
                    <div *ngIf="showcheckbox">
                        <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                        <td (click)="Deletecheck()">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </td>
                        <td (click)="archiveselected()">
                            <img src="assets/img/download.png" alt="" width="18px" />
                        </td>
                    </div>

                    <tbody style="background: #ecf0f1">
                        <tr class="unread" id="{{ ret.Id }}" style="cursor: pointer" *ngFor="let ret of sentList| filter: searchText |paginate: { itemsPerPage: 10, currentPage: p };index as i">

                            <!-- <td >
                                <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                            </td> -->
                            <td >
                                <input type="checkbox" style="height: 16px;" class="mail-checkbox" value="{{ret.id}}" [(ngModel)]="sentList[i].checked" (click)="toggleSelect(ret)" >
                            </td>
                            <td class="view-message dont-show" (click)="ReadMessage(ret)">
                                {{ ret.toName }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.subject }}
                            </td>
                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.date }}
                            </td>
                            <td (click)="singlearchive(ret)">
                                <img src="assets/img/download.png" alt="" width="18px" />
                            </td>
                            <td (click)="singleDelete(ret)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br /><br />
                <div class="padginationmedia">
                <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
            </div>
                <br /><br />
                <br /><br />
            </div>
        </aside>
    </div>
</div>