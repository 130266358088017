import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InwardModel } from 'src/app/ViewModels/Inward';
import { UploadData } from 'src/app/ViewModels/uploaddata';

@Injectable({
  providedIn: 'root'
})
export class InwardServiceService {
  readonly baseAppUrl: string = 'https://blocdrive.com/blocdriveapi'
  constructor(private http:HttpClient) { }
  getDivisions(){
    return this.http.get(this.baseAppUrl + '/api/DivisionMasters/GetDivisionMasters/');
  }
  GetFilePlanList(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetFilePlanSeq/');
  }
  GetDocumentTypeList(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetDocumentType/');
  }
  GetInwardTypeList(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetInwardType/');
  }
  GetWorkflowStage(id){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetWorkflowStageMaster/' + id);
  }
  GetExhibitsFiles(id){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetExhibits/' + id);
  }
  GetFolders(id){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetFolder/' + id);
  }
  GetPriority(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetSensitivity/');
  }
  GetInwardNumber(){
    return this.http.get(this.baseAppUrl + '/api/Inward/GetNewInwardNumber/');
  }
  SaveInward(data:InwardModel){
    return this.http.post(this.baseAppUrl +"/api/Inward/SaveInwardRegistration",data);
  }
  GetCirculationGroup(id){
    return this.http.get(this.baseAppUrl + '/api/Inward/GetcgBydoc/' + id);
  }
  SaveDocuments(data:UploadData){
    return this.http.post(this.baseAppUrl + '/api/WorkFlowFileUpload/PostUploadInwardDocument',data);
  }
  GetFiles(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetFiles/');
  }
  getFolder(data){
    return this.http.post(this.baseAppUrl + '/api/custom/PostFilesByFIle',data);
  }
  GetOutWardTransactionWFId(){
    return this.http.get(this.baseAppUrl + '/api/GeneralTransaction/GetOutWardTransactionWFId/');
  }
  GetDocumentsByDocumentID(id){
    return this.http.get(this.baseAppUrl + '/api/GeneralTransaction/GetGeneralTransactionDocumentId/' + id);
  }
  getInwardNumber(data){
    return this.http.post(this.baseAppUrl + '/api/custom/InwardNumber',data);

  }
  GetInwardFiles(id){
    return this.http.get(this.baseAppUrl + '/api/UploadDocument/GetInwardFiles/' + id);

  }
  GetInwardFolders(id){
    return this.http.get(this.baseAppUrl + '/api/UploadDocument/GetInwardOnlyFolders/' + id);

  }
  DownloadInwardForm(data){
    return this.http.post(this.baseAppUrl + '/api/Inward/DownloadInwardForm',data);

  }
  PrintInwardForm(data){
    return this.http.post(this.baseAppUrl + '/api/Documents/PostPrintFile',data);

  }
}
