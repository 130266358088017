import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gateway } from 'src/app/ViewModels/clientcreation';
import { MailboxVM } from 'src/app/ViewModels/Email';
import { ShareIdShow } from 'src/app/ViewModels/shareidshow';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http:HttpClient) { }
  readonly url = "https://blocdrive.com/blocdriveapi";


  getInboxlist(cid) {
    return this.http.post(this.url + "/api/UploadFile/postGetAllMails" , cid);
  }
  deletechecklist(cid) {
    return this.http.post(this.url + "/api/UploadFile/allDeleteMessages" , cid);
  }
  deletesentchecklist(cid) {
    return this.http.post(this.url + "/api/UploadFile/MultiDeletesentMessages" , cid);
  }
  archiveselect(data: any) {
    return this.http.post(this.url + '/api/UploadFile/MultiarchiveMessages', data);
  }
  archivesentselect(data: any) {
    return this.http.post(this.url + '/api/UploadFile/MultiarchivesentMessages', data);
  }
  getUsers(cid) {
    return this.http.get(this.url + "/api/FriendList/GetFriendList/" + cid);
  }
  deleteallmessages(info:any) {
    return this.http.post(this.url+ "/api/UploadFile/allDeleteMessages", info);
  }
  cleartrash(info:any) {
    return this.http.post(this.url+ "/api/UploadFile/trashempty", info);
  }

  getmaildetails(id){
    return this.http.post(this.url + "/api/UploadFile/PostGetMailDetails", id);
  }
  getmaildetailsaudit(id){
    return this.http.post(this.url + "/api/UploadFile/PostGetMailDetailsAudit", id);
  }
  getdeletelist(cid) {
    return this.http.post(this.url + "/api/UploadFile/postGetAllDeletedMails", cid);
  }
  getsentlist(cid) {
    return this.http.post(this.url + "/api/UploadFile/PostGetAllSentMails" , cid);
  }
  permanentlydeletemessages(det: MailboxVM) {
    return this.http.post(this.url+ "/api/UploadFile/parnamentdeleteMessages", det);
  }
  getarchivelist(cid) {
    return this.http.post(this.url + "/api/UploadFile/postGetAllArchiveMails", cid);
  }

  // postmakeitArchive(data: any) {
  //   return this.http.post(this.url + '/api/UploadFile/PostMakeAllArchiveMails', data);
  // }
  postmakeitArchive(data: any) {
    return this.http.post(this.url + '/api/UploadFile/MultiarchiveMessages', data);
  }
  postmakeitsentArchive(data: any) {
    return this.http.post(this.url + '/api/UploadFile/MultiarchivesentMessages', data);
  }
  postupdatemailread(cid) {
    return this.http.post(this.url + "/api/UploadFile/postupdatemailread", cid);
  }

  multideletesentmessages(det: MailboxVM) {
    return this.http.post(this.url+ "/api/UploadFile/MultiDeletesentMessages", det);
  }
  multideletemsgs(det: MailboxVM) {
    return this.http.post(this.url+ "/api/UploadFile/MultiDeletefromarchive", det);
  }
  restoredeletemessages(data: any) {
    return this.http.post(this.url + '/api/UploadFile/restoreDeletesentMessages', data);
  }
  multideletemessages(det: MailboxVM) {
    return this.http.post(this.url+ "/api/UploadFile/MultiDeleteMessages", det);
  }
 
  GetInboxMessages(cid) {
    return this.http.get(this.url + "/api/UploadFile/GetInboxMessages/" + cid);    
  }
  getParticularUsers(rid){
    return this.http.get(this.url + "/api/UploadFile/GetParticularUsers/" + rid);
  }
  getUsersformail(cid){
    return this.http.get(this.url + "/api/UploadFile/GetUsersforMail/" + cid);
  }
  deletedmail(cid) {
    return this.http.get(this.url + "/api/UploadFile/DeleteSelectedMail/" + cid);
  }
  getUserTickets(cid){
    return this.http.get(this.url + "/api/UploadFile/GetUserTickets/" + cid);
  }
  getdeletedmails(cid) {
    return this.http.get(this.url + "/api/UploadFile/GetDeletedItems/" + cid);
  }
  getsentmails(cid) {
    return this.http.get(this.url + "/api/UploadFile/GetSentItems/" + cid);
  }

  GetStatusList(rid){
    return this.http.get(this.url + "/api/MailBox/GetStatusList/" + rid);
  }
  getParticularUsersforEmp(ticketid){
    return this.http.get(this.url + "/api/MailBox/GetParticularUsersforEMP/" + ticketid);
  }
  saveAcceptTasks(id){
    return this.http.post(this.url +"/api/CustomerRequests/PostAcceptTasks/", id);
  }


   //haspit

   getsubfolders(ids: ShareIdShow) {
    return this.http.post(this.url + "/api/UploadDocument/Posttakedocument", ids);
  }
  getpfoldernames(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetFoldernames/" + id);
  }
  getfolders(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetallFolderone/" + id);
  }

  // gateway
  deactiveateuser(data:gateway){
    return this.http.post(this.url +"/api/Gateway/PostdeactiveMails/",data);
  }
  Getgatewaymaillist(cid) {
    return this.http.post(this.url + "/api/Gateway/postGetgatewayAllMails" , cid);
  }
  getmailstatus(id){
    return this.http.get(this.url + "/api/Gateway/EnableMailStatus/" + id);
  }

  // audit
  getchatdetailsaudit(id){
    return this.http.get(this.url + "/api/AuditorTransaction/GetChatDetailsAudit/"+ id);
  }
  getclientdetailsaudit(id){
    return this.http.get(this.url + "/api/AuditorTransaction/GetClientDetailsAudit/"+ id);
  }
  getuserdetailsaudit(id){
    return this.http.get(this.url + "/api/AuditorTransaction/GetUserDetailsAudit/"+ id);
  }
}
