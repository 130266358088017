import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExaminationService {
  readonly baseAppUrl: string = 'https://blocdrive.com/blocdriveapi'

  constructor(private http:HttpClient) { }
  GetExhibit(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostExhibitsDetails/',data);
  }
  getOpinions(id){
    return this.http.get(this.baseAppUrl + '/api/Examinations/GetOpinions/' + id);
  }
  saveOpinion(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostSaveOpinion/',data);
  }
  SaveExamss(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostExamination1/',data);
  }
  SaveExamdoc(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostExamDocuments/',data);
  }
  uploadForwardedDocument(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostForwardedDocument/',data);
  }
  SaveExam(data){
    return this.http.post(this.baseAppUrl + '/api/Examinations/PostExamination/',data);
  }
}
