import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../Shared/Login/login.service';
import { FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { tst } from '../ViewModels/Test';
import { OtpViewModel } from '../ViewModels/OtpViewModel';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { Signup } from '../ViewModels/Signup';
import { Socialusers } from '../ViewModels/Socialusers';
import { AuthService } from "angularx-social-login";
import { MessageService } from '../Shared/MessageService';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-indregister',
  templateUrl: './indregister.component.html',
  styleUrls: ['./indregister.component.css']
})
export class IndregisterComponent implements OnInit {
  otp = false;
  UserDetails: any;
  socialusers: any;
  response: any;
  phone: any;
  IsConfirm:any;
  userId: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phonev = new RegExp(/^[6-9]\d{9}$/);
  serchfind: boolean;
  phonetwo: boolean;
  files1: any;
  SelectedFiles: string;
  checkemail: boolean;
  Email: any;
  confirm: boolean=false;
  userrole: number;
  constructor(private userService: LoginService, private router: Router,
    public OAuth: AuthService,
    // public OAuth: AuthService,  
    private SocialloginService: LoginService, private route: ActivatedRoute,
    //file upload
    public fb: FormBuilder, private spinner: NgxSpinnerService,
    private authService: AuthService,private auditortransactionservice:AuditorTransactionService,
    private messageService:MessageService,

    private sanitizer: DomSanitizer, private http: HttpClient) {
    // this.spinner.show();
    route.params.subscribe((id: any) => {
      console.log(id);
      localStorage.ref = id.id;

    })
    window.scrollTo(0, 0);

    let url = window.location.href;
    if (url.includes('?')) {
      let spd = url.split('?');
      let acstsp = spd[1].split('=');
      let accesstoken = acstsp[1];

      let acst = accesstoken.split('&')[0];

      // let authURL ="https://www.linkedin.com/oauth/v2/accessToken?client_id=81fsghpn98dxwx&client_secret=ECnoIag5FdZ9hE0e&grant_type=authorization_code&code="+acst+"&redirect_uri=http://localhost:4200/loginf";

      let authURL = "https://bsafeapi.azurewebsites.net/api/Test/postacct";
      const abc: tst = {
        val: acst
      }
      this.http.post(authURL, abc).subscribe((res: any) => {
        if (res != "1") {


          const inputRequest = {
            Id: 0,
            Email: res.email,
            Otp: "2",
            FirstName: res.fname,
            LastName: null,
            UserName: null,
            PhoneNumber: null,
            Password: null,
            ConfirmPassword: null,
            ProvinceId: null,
            PlanId: null,
            AccessToken: res.actk
          }
          this.userService.LoginVerification(inputRequest).subscribe(
            (data: any) => {
              if (data != "1") {
                localStorage.setItem('IsLoggedIn', "true");
                localStorage.setItem('userToken', data.accessToken);
                localStorage.setItem("UserName", data.firstName);
                localStorage.setItem("storage", data.storage);
                localStorage.setItem("role", data.roleName);
                localStorage.setItem("userId", data.userID);
                localStorage.setItem("area", data.area);
                localStorage.setItem("isMember", data.IsMember);

                alert("Registration Successful");
                localStorage.loadingpage = "true";
                this.router.navigate(['/allfolders']);
              }

              else {
                this.router.navigate(['/newregister']);
              }
            });





          // this.router.navigate(['/login']);
        }
        else {
          this.router.navigate(['/home']);
        }
      });
    }




  }

  ngOnInit() {

    this.getrole();
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer.style.display == "none") {
        footer.style.display = "block";
      }
    }, 1000);
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }
  expired = false;
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {
      console.log(socialusers);
      let name = socialusers.name.split(" ");
      let fname = name[0];
      let lname = name[1];
      let email = socialusers.email;
      let actk = socialusers.authToken;
      this.savefromsocio(fname, lname, email, actk);

      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });
  }
  resendotp(form: NgForm) {
    this.otp = null;
    this.expired = false;
    this.OnSubmit(form);
  }
  onSelectIsConfirm(event) {
    if (event.target.checked) {
      this.confirm = true;
    }
    else {
      this.confirm = false;

    }
  }
  OnSubmit(form: NgForm) {
    if (this.onlyphone) {
      this.phonetwo = this.phonev.test(form.controls["phone"].value);
    }

    else if (!this.onlyphone) {
      this.checkemail = this.regexp.test(form.controls["Email"].value);
    }

    if (this.otp) {
      this.OnSubmitOtp(form);
      // this.spinner.hide();
    }

    else {
      if (form.controls["Username"].value == null || form.controls["Username"].value == "") {
        this.spinner.hide();
        alert("Please enter your UserName");

      }
      else if (form.controls["Password"].value == null || form.controls["Password"].value == "") {
        this.spinner.hide();
        alert("Please enter your Password");
      }
      // else if (form.controls["phone"].value == undefined || form.controls["phone"].value == "" || form.controls["phone"].value == null) {
      //   this.spinner.hide();
      //   alert("Please enter phone number");
      // }
      else if (!this.onlyphone && !this.checkemail) {
        alert('Please enter valid Email');
      }
      else if (this.onlyphone && !this.phonetwo) {
        alert('Please enter valid Mobile Number');
      }
      else if (this.SelectedFiles == null || this.SelectedFiles == undefined) {
        alert('Please upload your Picture');
      }
      else {
        if (!this.onlyphone) {

          const inputRequest1: Signup = {
            Id: 0,
            FirstName: form.controls["Username"].value,
            LastName: null,
            UserName: null,
            Email: form.controls["Email"].value,
            PhoneNumber: null,
            Password: form.controls["Password"].value,
            ConfirmPassword: form.controls["Password"].value,
            ProvinceId: null,
            PlanId: +localStorage.ref
          }
          this.UserDetails = inputRequest1;



        }
        else {


          const inputRequest1: Signup = {
            Id: 0,
            FirstName: form.controls["Username"].value,
            LastName: null,
            UserName: null,
            Email: null,
            PhoneNumber: form.controls["phone"].value,
            Password: form.controls["Password"].value,
            ConfirmPassword: form.controls["Password"].value,
            ProvinceId: null,
            PlanId: +localStorage.ref
          }
          this.UserDetails = inputRequest1;

        }
        this.spinner.show();
        this.userService.saveDetails(this.UserDetails).subscribe((data: any) => {
          console.log(data)
          if (data == "1") {
            if (this.onlyphone) {
              alert("Otp has been sent to your Registered Phone Number..!!! Please Enter the otp to complete registration process");
            }
            else {
              alert("Otp has been sent to your Registered Email..!!! Please Enter the otp to complete registration process");

            }
            this.otp = true;
          }
          else if (data == "0") {
            if (this.onlyphone) {
              alert("Phone Number already exists");
            }
            else {
              alert("Email Id already exists");

            }
            location.reload();
          }
          else {
            alert(data);
          }
          this.spinner.hide();
        });

      }
    }
  }
  OnSubmitOtp(form: any) {
    this.userrole = 1;
    if(this.confirm==true){
      this.userrole = 9;
    }

    if (!this.onlyphone) {
      if (form.controls["otp"].value == null || form.controls["otp"].value == "") {
        this.spinner.hide();
        alert("Please enter your Otp");
      }
      else if (form.controls["otp"].value.length <= 5 || form.controls["otp"].value.length > 10) {
        this.spinner.hide();
        alert("Please enter valid Otp");
      }
      else {
        const inputRequest: OtpViewModel = {
          Id: 0,
          Email: this.UserDetails.Email,
          Otp: form.controls["otp"].value,
          FirstName: this.UserDetails.FirstName,
          LastName: null,
          UserName: null,
          PhoneNumber: this.UserDetails.PhoneNumber,
          Password: this.UserDetails.Password,
          ConfirmPassword: this.UserDetails.ConfirmPassword,
          ProvinceId: this.role,
          PlanId: 0,
          Auditor: this.confirm,
          AccessToken: null
        }
        this.spinner.show();
        const frmData = new FormData();
        frmData.append("credentials", JSON.stringify(inputRequest));
        frmData.append("picture", this.SelectedFiles);
        this.spinner.show();

        this.http.post('https://blocdrive.com/blocdriveapi/api/Account/OTPValidation/', frmData).subscribe(

          // this.userService.postVerifyOTP(inputRequest).subscribe(
          (data: any) => {
            if (data == "0") {
              alert("Registered Succesfully");
              const audit = {
                TransactionName:"Indvidual Registration",
                TransactionType:"Registration",
                TransactionDesc:"Registration",
      
                }
                this.auditortransactionservice.AuditorTransactionDetails(audit);
              this.router.navigate(['/login']);
            }
            else if (data == "1") {
              alert("Incorrect OTP");
            }
            else if (data == "2") {
              alert("OTP Expired");
              this.expired = true;
            }
            else {
              alert("Something went wrong!!! Please Try Again....");
            }
            this.spinner.hide();
          });

      }
    }
    else {
      if (form.controls["otpp"].value == null || form.controls["otpp"].value == "") {
        this.spinner.hide();
        alert("Please enter your Otp");
      }
      else if (form.controls["otpp"].value.length <= 5 || form.controls["otpp"].value.length > 10) {
        this.spinner.hide();
        alert("Please enter valid Otp");
      }
      else {
        const inputRequest: OtpViewModel = {
          Id: 0,
          Email: this.UserDetails.Email,
          Otp: form.controls["otpp"].value,
          FirstName: this.UserDetails.FirstName,
          LastName: null,
          UserName: null,
          Auditor: this.confirm,
          PhoneNumber: this.UserDetails.PhoneNumber,
          Password: this.UserDetails.Password,
          ConfirmPassword: this.UserDetails.ConfirmPassword,
          ProvinceId:  this.role,
          PlanId:0,
          AccessToken: null
        }
        const frmData = new FormData();
        frmData.append("credentials", JSON.stringify(inputRequest));
        frmData.append("picture", this.SelectedFiles);
        this.spinner.show();

        this.http.post('https://blocdrive.com/blocdriveapi/api/Account/OTPValidation/', frmData).subscribe(

          // this.userService.postVerifyOTP(inputRequest).subscribe(
          (data: any) => {
            if (data == "0") {
              alert("Registered Successfully");
              const audit = {
                TransactionName:"Indvidual Registration",
                TransactionType:"Registration",
                TransactionDesc:"Registration",
      
                }
                this.auditortransactionservice.AuditorTransactionDetails(audit);
              this.router.navigate(['/login']);
            }
            else if (data == "1") {
              alert("Incorrect OTP");
            }
            else if (data == "2") {
              alert("OTP Expired");
            }
            else {
              alert("Something went wrong!!! Please Try Again...");
            }
            this.spinner.hide();
          });
      }
    }


  }
  onlyphone = false;
  changetophone() {
    if (this.onlyphone) {
      this.onlyphone = false;
    }
    else {
      this.onlyphone = true;
    }
  }

  public socialSignIn(socialProvider: string) {
    let socialPlatformProvider;
    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      // this.OAuth.signIn(socialPlatformProvider).then(socialusers => {  
      //   console.log(socialProvider, socialusers);  
      //   console.log(socialusers);  
      //   let name=socialusers.name.split(" ");
      //   let fname=name[0];
      //   let lname=name[1];
      //   let email=socialusers.email;
      //   let actk=socialusers.token;
      //   this.savefromsocio(fname,lname,email,actk);
      //  // this.router.navigate(['/home']);  

      //   //this.Savesresponse(socialusers);  
      // }); 
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      // this.OAuth.signIn(socialPlatformProvider).then(socialusers => {  
      //   console.log(socialProvider, socialusers);  
      //   console.log(socialusers);  
      //   let name=socialusers.name.split(" ");
      //   let fname=name[0];
      //   let lname=name[1];
      //   let email=socialusers.email;
      //   let actk=socialusers.token;
      //   this.savefromsocio(fname,lname,email,actk);
      //   //this.router.navigate(['/home']);  

      //   //this.Savesresponse(socialusers);  
      // });  
    }


    else if (socialProvider === "linkedin") {
      window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=http://localhost:4200/newregister&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social", "_self");


    }

  }
  Savesresponse(socialusers: Socialusers) {
    this.SocialloginService.Savesresponse(socialusers).subscribe((res: any) => {
      debugger;
      console.log(res);
      this.socialusers = res;
      this.response = res.userDetail;
      localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
      console.log(localStorage.setItem('socialusers', JSON.stringify(this.socialusers)));
      this.router.navigate(['/']);
    })
  }


  savefromsocio(fname, lname, email, actk) {
    const inputRequest = {
      Id: 0,
      Email: email,
      Otp: "1",
      FirstName: fname,
      LastName: null,
      UserName: null,
      PhoneNumber: null,
      Password: null,
      ConfirmPassword: null,
      ProvinceId: null,
      PlanId: null,
      AccessToken: actk
    }
    this.userService.LoginVerification(inputRequest).subscribe(
      (data: any) => {
        if (data != "1") {
          localStorage.setItem('IsLoggedIn', "true");
          localStorage.setItem('userToken', data.accessToken);
          localStorage.setItem("UserName", data.firstName);
          localStorage.storage = data.storage;

          localStorage.setItem("role", data.roleName);
          localStorage.setItem("userId", data.userID);
          localStorage.setItem("area", data.area);
          localStorage.setItem("isMember", data.IsMember);

          localStorage.loadingpage = "true";
          localStorage.setItem("GUID",data.guid);
          this.messageService.sendMessage('true');
          this.router.navigate(['/allfolders']);
        }

        else {
          this.router.navigate(['/indregister']);
        }
      });
  }
  // savefromsocio(fname,lname,email,actk)
  // {
  //   const inputRequest: OtpViewModel = {
  //     Id: 0,
  //     Email: email,
  //     Otp: "1",
  //     FirstName: fname,
  //     LastName: lname,
  //     UserName: null,
  //     PhoneNumber: null,
  //     Password: null,
  //     ConfirmPassword: null,
  //     ProvinceId :null ,
  //     PlanId: +localStorage.ref,
  //     AccessToken:actk
  //   }
  //   this.userService.postVerifyOTP(inputRequest).subscribe(
  //     (data: any) => {
  //       if (data == "0") {
  //         alert("Registered Succesfully");
  //         this.router.navigate(['/newlogin']);
  //       }

  //       else {
  //         alert("Something Went Wrong. Try again.!!");
  //       }
  //     });
  // }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialusers => {
      console.log(socialusers);
      let name = socialusers.name.split(" ");
      let fname = name[0];
      let lname = name[1];
      let email = socialusers.email;
      let actk = socialusers.authToken;
      this.savefromsocio(fname, lname, email, actk);

      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });;
  }
  signInWithLI(): void {
    window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=https://blockchainsafe.in/newlogin&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social","_self");
    }
  roles: any;
  role = 0;
  getrole() {
    this.userService.getroles().subscribe((dat: any) => {
      this.roles = dat;
    })
  }
  onchangerole(event) {
    const value = event.target.value;
    this.role = value;
  }
  OnSubmitrole(form: NgForm) {

    this.OnSubmit(form);

  }
  OnSkip(form: NgForm) {
    this.role = 0;
    this.OnSubmit(form);
  }
  onChange(event: any) {
    var fileslist = "";
    this.files1 = [].slice.call(event.target.files);
    console.log(this.files1);
    fileslist = this.files1[0];

    this.SelectedFiles = fileslist;

  }
  
}
