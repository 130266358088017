import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { streamstore } from 'src/app/ViewModels/streamstore';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  readonly url="https://blocdrive.com/blocdriveapi/api/";

  constructor(private http:HttpClient) { }
  saveHostId(data){
    return this.http.post(this.url+"VideoCall/ScheduleCall",data);
  }
  getusermailid(email){
    return this.http.post(this.url+"VideoCall/getUserId",email);
  }
  storePeer(hostid,myid,peerid){
    const data:streamstore={
      peerid:peerid,
      qid:hostid,
      uid:myid
    }
    return this.http.post(this.url+"VideoCall/storePeerClient",data);
  }
  storePeerHost(hostid,myid,peerid){
    const data:streamstore={
      peerid:peerid,
      qid:hostid,
      uid:myid
    }
    return this.http.post(this.url+"VideoCall/storePeerHost",data);
  }

}
