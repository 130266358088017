import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { MovesService } from 'src/app/Shared/MoveService/moves.service';
import { Friend } from 'src/app/ViewModels/Friend';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { Search } from 'src/app/ViewModels/Search';
import { Delete } from 'src/app/VIewmodels/Delete';
import { MoveFolderOrDocument } from 'src/app/ViewModels/MoveFolderOrDocument';
import { MoveDocData } from 'src/app/ViewModels/MoveDocData';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { MoveMultiFolder } from 'src/app/ViewModels/MoveMultiFolder';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { UserIdVM } from 'src/app/ViewModels/UserIdVM';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-allfiles',
  templateUrl: './allfiles.component.html',
  styleUrls: ['./allfiles.component.scss']
})
export class AllfilesComponent implements OnInit {
  public imagePath;
  imgURL: any;
  public message: string;
  allSubFolders: any;
  SelectedFiles: any;
  public isLoggedIn: boolean = false;
  public remove: any = false;
  uid: any; roleid: any;
  fileupload: File = null; fid: any;
  userName: string; sMsg: any;
  ID: any; pdfUrl: any; closeResult: any; pdfUrl1: any; pdfUrl2: any; pdfUrl3: any;
  msg: any; m: string; zzz: any;
  details: any;
  updatemsg: any; u: any; fl: any;
  pid: any; FormData: any; subf: any; Press: any;
  Pid: any; zoom_to: any;
  Friends: any; shareitem: any;
  selected = []; CurrentFiles: any;
  friendid: number; R: any;
  Transcations: any; totaltrans: any;
  FoldID: any; FileFoldID: any;
  searchvalue: any;
  val: any;
  public SearchEnabled: boolean = false;
  public ShowResult: boolean = false;
  SearchResult: any;
  temp: any; doc: string;
  current_url: any; displayUrl: any;
  current_url1: any;
  mail: boolean; inboxmsgs: any;
  MailMessage: string; frmmailpwd: any;
  mailmsg: any; usermail: any;
  FromMailID: string; FromPassword: any;
  ToMailID: any; tomail: any;
  responsemail: any; sub: any;
  alldata: any; importmailpwd: any;
  Particularusermail: string;
  videoplay: any; videodate: any;
  gridvalue: any;
  listvalue: boolean;
  txtUrl: any;
  files1: any;
  deleteitem: boolean;
  selectedDelete = [];
  getMoveData: any;
  getDocData: any;
  destinationFolder: any;
  sourceFolder: any;
  notify: string;
  foladdednotify: boolean;
  storedData: any;
  IsDOCorFolder: any;
  Hash: any;
  DocumentName: any;
  ImageType: any;
  docsize: any;
  DateCreated: any;
  CustId: any;
  FolderName: any;
  CreatedOn: any;
  Res: any;
  downpath: any;
  Docname: any
  shareSubFolderFolderId: any;
  shareSubFolderFolderName: any;
  shareSubFolderFolderType: any;
  shareSubFolderParentID: any;
  shareSubFolderId: any;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  createdmsg: string;
  foldercreated: boolean;
  statuschange: string;
  ShareOnlyDoc: any;
  BlockId: any;
  FolderID: any;
  ShareOnlyDocName: any;
  sendFID: any;
  sendBID: any;
  checkenable: boolean;
  multi: boolean;
  sfiles: number;
  sfolder: number;
  deletemulticontent: string;
  allFolders1: any;
  destinationFold: any;
  Showmoveitem: boolean;
  shareUniqueID: any;
  dismissalert: boolean;
  copied1 = false;
  Movetofold: any
  ppopup: any;
  allSubFolders1: any;
  folderid: string;
  DateTime: any;
  allFolders: any;
  frmData1: FormData;
  uploadfolderclicked: boolean;
  sameNames: any;
  SelectedFolderandFiles: any[] = [];
  folderlist: any[] = [];
  sameFolderName: any[] = [];
  replaceFolderid: any[] = [];
  relaceFolderlist: any;
  SelectedFolderName: any;
  SelectedLevel: any;
  crthr: any;
  pathdt: any;
  subf1: any;
  url: string | ArrayBuffer;
  mvlfd: boolean;
  mobile = false;
  accid: any;
  frndrqst: boolean;
  userid: string;
  frndintr: any;
  role: any;
  folderview: any
  client: any
  errormsg: any
  GUID: string;
  imgdisplay: any;
  seleteddownload: boolean;
  dnsIP: any;
  IPAddress: any;
  dname: string;
  mm: string;
  workflw: boolean=false;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  errorMessages:any;
  email: any;
  serchfind: boolean;
  doctype: any;
  RD : any;
  SF : any;
  folid: any;
  docname: any;
  folidd: string;
  showbutton: boolean =true;
  foldid: any;
  selectedItems: any;
  dropdownSettings: IDropdownSettings;
  selfrnds: any =[];
  frndslistnames: any = [];
  setid: string | Blob;
  CHUNK_SIZE: any;
  constructor(private router: ActivatedRoute, private userservice: UserDetailsService,
     private moveService: MovesService,private auditortransactionservice:AuditorTransactionService,
    private route: Router, private httpService: HttpClient,
    private modalService: NgbModal, private domSanitizer: DomSanitizer, private spinner: NgxSpinnerService,
    private http: HttpClient) {
    this.spinner.show();
    window.scrollTo(0, 0);

    this.role = localStorage.getItem("role");

    this.mvlfd = false;
    this.ppopup = true;
    if (localStorage.getItem("copied")) {
      this.copied1 = true;
    }
    this.router.params.subscribe(params => {
      if (params["id"]) {
        localStorage.folderid = params['id'];
        this.GetDetails(params["id"]);
        this.GetAllFolders1();

      }
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");

    }

    //vishal
    this.userid = localStorage.getItem("userId");


    this.frndrqst = false;

    this.frndintr = setInterval(() => {
      this.GetFriendDetails();
    }, 30000);
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
      this.dnsIP = data['ip'];
    });

    this.http.get('https://jsonip.com/').subscribe(data => {
      this.IPAddress = data['ip'];
    });
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'assignRigntType',
      selectAllText: 'Select Users',
      unSelectAllText: 'Selected Users',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      searchPlaceholderText: 'Search for Users',
      noDataAvailablePlaceholderText: 'No data available',
    }

    this.resize();
    window.addEventListener('resize', this.resize);
    this.GetFriendDetails();
    this.GetAllFolders1();
    if (this.gridvalue == "true") {
      this.gridvalue = true;
      this.listvalue = false;
    }
    else {
      this.listvalue = true;
      this.gridvalue = false;
    }
    setTimeout(() => {
      if (this.copied1) {
        var moveid = document.getElementById("moveid");
        if (moveid) {
          moveid.style.display = "block";
        }
        //document.getElementById("moveid").style.display = "block";
      }
      else {
        var moveid = document.getElementById("moveid");
        if (moveid) {
          moveid.style.display = "none";
        }
      }
    }, 1000);

    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer) {
        footer.style.display = "none";
      }
    }, 1000);

    if (window.innerWidth < 500) {
      setTimeout(() => {
        var tree = document.getElementById("treeview");

        if (tree) {
          tree.style.display = "none";

        }

        var upload = document.getElementById("view");

        if (upload) {
          upload.style.display = "none";

        }

      }, 100);

      this.mobile = true;
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }

  //vishal
  ngOnDestroy() {
    clearInterval(this.frndintr);
  }




  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  cancelshareall = false;
  shareitem1 = false;
  cancelshare() {
    if (this.cancelshareall == true) {
      this.cancelshareall = false;
      this.shareitem1 = false;

    }
    else {
      this.cancelshareall = true;
      this.shareitem1 = true;

    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
      }
      else if(data == "Cannot"){
        alert("You cannot send friend request!!");
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    });
  }

  mob = false;

  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }
  treeviewba() {
    var tree = document.getElementById("treeview");
    if (tree) {
      if (tree.style.display == "none") {
        tree.style.display = "block";
      }
      else {
        tree.style.display = "none";
      }
    }
  }
  uploadview() {
    var tree = document.getElementById("view");
    if (tree) {
      if (tree.style.display == "none") {
        tree.style.display = "block";
      }
      else {
        tree.style.display = "none";
      }
    }
  }
  // GetDetails(id) {
  //   this.spinner.show();
  //   this.getpath(id);
  //   this.FileFoldID = id;
  //   this.userservice.getsubfolders(id).subscribe((respose: any) => {
  //     this.allSubFolders = respose;
  //     this.subf = this.allSubFolders.fileName;
  //     this.fid = this.allSubFolders.parentId;
  //     // console.log("user", this.allSubFolders);
  //     localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //     localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //     //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
  //   });
  //   this.spinner.hide();
  // }

  GetDetails(id) {
    this.spinner.show();
    this.getpath(id);
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
    
      this.subf = this.allSubFolders.FileName;
      this.fid = this.allSubFolders.ParentId;
      // console.log("user", this.allSubFolders);
      this.RD = this.allSubFolders.RetData;
      for(var i = 0;i<this.RD.length;i++){
        this.RD[i].DocumentSize = Math.floor((this.RD[i].DocumentSize/1024)) ; 
        if(this.RD[i].DocumentSize - parseInt(this.RD[i].DocumentSize) >= 0.000005){
          this.RD[i].DocumentSize = parseInt(this.RD[i].DocumentSize) + 1
        }     
      else if(this.RD[i].DocumentSize - parseInt(this.RD[i].DocumentSize) <= 0.000005){
        this.RD[i].DocumentSize = parseInt(this.RD[i].DocumentSize)
      }    
      if(this.RD[i].DocumentName.includes('.png') || this.RD[i].DocumentName.includes('.PNG') ||
      this.RD[i].DocumentName.includes('.jpg') || this.RD[i].DocumentName.includes('.JPG') ||
      this.RD[i].DocumentName.includes('.jpeg') || this.RD[i].DocumentName.includes('.JPEG')){
        this.RD[i].DocumentType="image";
      }
      else if(this.RD[i].DocumentName.includes('.txt') || this.RD[i].DocumentName.includes('.TXT') ||
      this.RD[i].DocumentName.includes('.doc') || this.RD[i].DocumentName.includes('.DOC') ||
      this.RD[i].DocumentName.includes('.docx') || this.RD[i].DocumentName.includes('.DOCX') ||
      this.RD[i].DocumentName.includes('.ppt') || this.RD[i].DocumentName.includes('.PPT') ||
      this.RD[i].DocumentName.includes('.pptx') || this.RD[i].DocumentName.includes('.PPTX') ||
      this.RD[i].DocumentName.includes('.xls') || this.RD[i].DocumentName.includes('.XLS') ||
      this.RD[i].DocumentName.includes('.xlsx') || this.RD[i].DocumentName.includes('.XLSX') ||
      this.RD[i].DocumentName.includes('.pdf') || this.RD[i].DocumentName.includes('.PDF') ||
      this.RD[i].DocumentName.includes('.html') || this.RD[i].DocumentName.includes('.HTML') ||
      this.RD[i].DocumentName.includes('.exe') || this.RD[i].DocumentName.includes('.EXE') ||
      this.RD[i].DocumentName.includes('.bak') || this.RD[i].DocumentName.includes('.BAK') ||
      this.RD[i].DocumentName.includes('.sql') || this.RD[i].DocumentName.includes('.SQL') ||
      this.RD[i].DocumentName.includes('.zip') || this.RD[i].DocumentName.includes('.ZIP') ){
        this.RD[i].DocumentType = this.RD[i].DocumentType;
      }
      else{
        this.RD[i].DocumentType = 'other';
      }
       }
       console.log(this.RD);
      this.SF = this.allSubFolders.SubFolders;
      localStorage.setItem('retData', JSON.stringify(this.RD));
      localStorage.setItem('subFolders', JSON.stringify(this.SF));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
    this.spinner.hide();

  }

  getpath(id) {
    this.userservice.getpath(id).subscribe((respose: any) => {
      this.pathdt = respose;
    });
  }

  // getfolders1(id1) {
  //   let id = id1.folderID;

  //   let elem = document.getElementById(id);
  //   if (elem.childNodes.length < 2) {

  //     var icn = document.getElementById(id1.id);
  //     if (icn != null) {
  //       icn.setAttribute("class", "fa fa-caret-down");

  //     }

  //     this.FileFoldID = id;
  //     this.userservice.getsubfolders(id).subscribe((respose: any) => {
  //       this.allSubFolders1 = respose;
  //       this.subf = this.allSubFolders1.fileName;
  //       this.fid = this.allSubFolders1.parentId;
  //       // console.log("user", this.allSubFolders1);
  //       // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //       //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //       //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

  //       var mdv1 = document.createElement("div");
  //       for (let i = 0; i < this.allSubFolders1.subFolders.length; i++) {
  //         var mdv = document.createElement("div");
  //         mdv.setAttribute("id", this.allSubFolders1.subFolders[i].folderID);
  //         mdv.setAttribute("style", "padding-left:20px");
  //         var elm = document.createElement("span");
  //         var img = document.createElement("img");
  //         img.setAttribute("src", "./assets/img/folder.svg");
  //         img.setAttribute("width", "30px");
  //         img.setAttribute("height", "30px");
  //         var elm1 = document.createElement("span");
  //         elm1.innerHTML = this.allSubFolders1.subFolders[i].folderName;

  //         var elm12 = document.createElement("i");

  //         elm12.setAttribute("class", "fa fa-caret-right");
  //         elm12.setAttribute("id", this.allSubFolders1.subFolders[i].id);

  //         elm12.addEventListener('click', this.getfolders1.bind(this, this.allSubFolders1.subFolders[i]));



  //         elm.appendChild(elm12);
  //         elm.appendChild(img);
  //         elm.appendChild(elm1);

  //         mdv.appendChild(elm);

  //         img.addEventListener('click', this.gotoFolder2.bind(this, this.allSubFolders1.subFolders[i]));
  //         elm1.addEventListener('click', this.gotoFolder2.bind(this, this.allSubFolders1.subFolders[i]));


  //         mdv1.appendChild(mdv);
  //       }



  //       elem.appendChild(mdv1);


  //     });


  //   }
  //   else {

  //     document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
  //     let elem = document.getElementById(id);
  //     while (elem.childNodes.length > 1) {
  //       elem.removeChild(elem.lastChild);
  //     }
  //   }
  // }
  getfolders1(id1) {
    let id = id1.folderID;

    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      this.userservice.getsubfolders(id).subscribe((respose: any) => {
        this.allSubFolders1 = respose;
        this.subf = this.allSubFolders1.FileName;
        this.fid = this.allSubFolders1.ParentId;
        // console.log("user", this.allSubFolders1);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders1.SubFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders1.SubFolders[i].FolderID);
          mdv.setAttribute("style", "padding-left:20px");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");
          elm1.innerHTML = this.allSubFolders1.SubFolders[i].FolderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders1.SubFolders[i].id);

          elm12.addEventListener('click', this.getfolders1.bind(this, this.allSubFolders1.SubFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          img.addEventListener('click', this.gotoFolder2.bind(this, this.allSubFolders1.SubFolders[i]));
          elm1.addEventListener('click', this.gotoFolder2.bind(this, this.allSubFolders1.SubFolders[i]));


          mdv1.appendChild(mdv);
        }



        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }
  gotoFolder2(data) {
    this.GetDetails(data.folderID);
    this.GetAllFolders1();
    this.folderid = data.folderID;
  }

  gotoFolder1(data) {
    this.folderid = data.folderID;

  }

  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.allSubFolders.subFolders.sort((a, b) => a.folderName.toLowerCase() < b.folderName.toLowerCase() ? -1 : a.folderName.toLowerCase() > b.folderName.toLowerCase() ? 1 : 0)
      this.allSubFolders.retData.sort((a, b) => a.documentName.toLowerCase() < b.documentName.toLowerCase() ? -1 : a.documentName.toLowerCase() > b.documentName.toLowerCase() ? 1 : 0)

      this.sort = "2";

    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.allSubFolders.subFolders.sort((a, b) => a.folderName.toLowerCase() > b.folderName.toLowerCase() ? -1 : a.folderName.toLowerCase() < b.folderName.toLowerCase() ? 1 : 0)
      this.allSubFolders.retData.sort((a, b) => a.documentName.toLowerCase() > b.documentName.toLowerCase() ? -1 : a.documentName.toLowerCase() < b.documentName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.allSubFolders.subFolders.sort((a, b) => a.createdOn < b.createdOn ? -1 : a.createdOn > b.createdOn ? 1 : 0)
      this.allSubFolders.retData.sort((a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0)

      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.allSubFolders.subFolders.sort((a, b) => a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0)
      this.allSubFolders.retData.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)

      this.sortname = "1";

    }

  }
  gotoallfolders() {
    this.route.navigate(["/allfolders"]);
  }


  gridview(value) {
    if (value == "1") {
      this.gridvalue = true;
      this.listvalue = false;
      localStorage.setItem("gridvalue", "true");
      setTimeout(() => {
        if (this.copied1) {
          document.getElementById("moveid").style.display = "block";
        }
        else {
          document.getElementById("moveid").style.display = "none";
        }
      }, 500);

    }
    else if (value == "0") {
      this.listvalue = true;
      this.gridvalue = false;
      localStorage.setItem("gridvalue", "false");
      setTimeout(() => {
        if (this.copied1) {
          document.getElementById("moveid").style.display = "block";
        }
        else {
          document.getElementById("moveid").style.display = "none";
        }
      }, 500);

    }
  }


  // GetSubfolderdetails(data) {
  //   this.temp = data;
  //   this.subf = data.folderName;
  //   this.FileFoldID = data.folderID;
  //   this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
  //     this.allSubFolders = respose;
  //     // this.subf = this.allSubFolders.FolderName;
  //     var Pid = this.allSubFolders.parentId;
  //     //localStorage.setItem(this.allSubFolders);
  //     localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //     localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //     localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
  //     this.getpath(data.folderID);
  //     // console.log("user", this.allSubFolders);
  //   });
  // }

  GetSubfolderdetails(data) {
    this.temp = data;
    this.subf = data.folderName;
    this.FileFoldID = data.folderID;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;

      // this.subf = this.allSubFolders.FolderName;
      var Pid = this.allSubFolders.ParentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.ParentId));
      this.getpath(data.FolderID);
      // console.log("user", this.allSubFolders);
    });
  }



  GetBack(allSubFolders) {
    if (this.gridvalue == true) {
      localStorage.setItem("gridvalue", "true");
    }
    else {
      localStorage.setItem("gridvalue", "false");
    }
    this.userservice.getbackdata(allSubFolders.parentId).subscribe((respose: any) => {
      this.Press = true;
      this.allSubFolders = respose;
      this.Pid = this.allSubFolders.parentId;
      this.FileFoldID = this.allSubFolders.parentId;
      this.allSubFolders.folderID = this.allSubFolders.parentId;
      if (this.allSubFolders.parentId == "0") {
        localStorage.setItem('formdata', JSON.stringify(this.allSubFolders));
        this.route.navigate(['/allfolders']);
      }
    });
  }


  addSubFolder(allSubFolders, subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // savenewSubFolder() {
  //   if (this.allSubFolders.subFolders.length == 0) {
  //     this.fl = "0";
  //   }
  //   else {
  //     this.fl = this.allSubFolders.subFolders[0].folderID;
  //   }
  //   var offset = new Date().getTimezoneOffset();
  //   var nd = 0.0;
  //   nd = -(parseFloat(offset.toString()));
  //   const inputRequest: AddNewFolder = {
  //     Id: 0,
  //     FolderID: this.fl,
  //     RoleId: this.roleid,
  //     FolderName: this.msg,
  //     UserId: this.uid,
  //     GUID: this.GUID,
  //     Code: "SubFolder",
  //     ParentID: this.allSubFolders.parentId,
  //     Click: null,
  //     date: nd
  //   }
  //   this.spinner.show()

  //   this.userservice.addNewFolder(inputRequest).subscribe((data: any) => {
  //     this.spinner.hide()

  //     if (data != null) {


  //       this.GetDetails(this.FileFoldID);
  //       this.GetAllFolders1();
  //       const audit = {
  //          TransactionName:"File Management",
  //         TransactionType:"Sub Folder",
  //         TransactionDesc:"Sub Folder Creation",
  //         SubFolderName:this.msg,
  //         }
  //       this.auditortransactionservice.AuditorDetailsProject(audit);

  //     }
  //     else {
  //       alert("Something went Wrong");
  //       this.route.navigate(['/allfolders']);
  //     }
  //     this.msg = "";
  //   });
  // }

  savenewSubFolder() {
    if (this.allSubFolders.SubFolders.length == 0) {
      this.fl = "0";
    }
    else {
      this.fl = this.allSubFolders.SubFolders[0].FolderID;
    }
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: 0,
      FolderID: this.fl,
      RoleId: this.roleid,
      FolderName: this.msg,
      UserId: this.uid,
      GUID: this.GUID,
      Code: "SubFolder",
      ParentID: this.allSubFolders.ParentId,
      Click: null,
      date: nd
    }
    this.spinner.show()

    this.userservice.addNewFolder(inputRequest).subscribe((data: any) => {
      window.location.reload();
      this.spinner.hide()

      if (data != null) {


        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();
        const audit = {
           TransactionName:"File Management",
          TransactionType:"Sub Folder",
          TransactionDesc:"Sub Folder Creation",
          SubFolderName:this.msg,
          }
        this.auditortransactionservice.AuditorDetailsProject(audit);
       
        this.GetAllFolders1();
      }
      else {
        alert("Something went Wrong");
        this.route.navigate(['/allfolders']);
      }
      this.msg = "";
    });
  }

  UpdateSubFolder(folderview, editfolder) {
    this.details = folderview;
    this.updatemsg = folderview.folderName;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }

  UpdateSubFolderName() {
    //......
    if(this.details.folderID == undefined){
      this.foldid = this.details.FolderID;
      }
      if(this.details.FolderID == undefined){
      this.foldid = this.details.folderID;
      }
  
    //......
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: this.details.id,
//......   FolderID: this.details.folderID,
      FolderID: this.foldid,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      GUID: this.GUID,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null,
      date: nd
    }
    this.spinner.show()

    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      this.spinner.hide()

      if (data == true) {
        this.n = 100;
        this.createdmsg = "Updated Successfully";
        const audit = {
           TransactionName:"File Management",
          TransactionType:"Update",
          TransactionDesc:"File",
          }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        // alert("Updated Successfully");
        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();


      }
      else {
        alert("Something went Wrong");
        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();
      }
    });
  }
  shareallallcancel() {
    if (this.selectedFriendcancel == null || this.selectedFriendcancel == undefined || this.selectedFriendcancel == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: 0,
        ToUsers: null,
        USerId: this.uid,
        GUID: this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.selectedFriendcancel,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Withdrawing Shared Items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Withdrawing Shared Items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.withdraw(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Withdraw of Shared files successfull!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
            // const audit = {
            //    TransactionName:"File Management",
            //   TransactionType:"Share",
            //   TransactionDesc:"File Share",
            //   }
            // this.auditortransactionservice.AuditorTransactionDetails(audit);
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }
  shareallall() {
    if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
        ToUsers: this.selectedFriend,
        USerId: this.uid,
        GUID: this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: null,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.shareall(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
            const audit = {
               TransactionName:"File Management",
              TransactionType:"Share All",
              TransactionDesc:"Share",
              }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  GetFriendDetails() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      // console.log(this.frndslist);
      for (var i = 0; i < this.frndslist.length; i++) {
        this.frndslistnames.push(this.frndslist[i].friendName)
      }

      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }

  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }
  Deleteitems() {
    this.deleteitem = true;
  }
  CancelDelete() {
    this.deleteitem = false;
  }


  onItemAdded(value: any) {
    if (value.Email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.Email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    
    else {
      this.selectedFriend.push(value.value);
    }
  }

  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }


  onChange(event: any) {
    var fileslist = "";
    var files = [].slice.call(event.target.files);

    this.files1 = [].slice.call(event.target.files);
    // console.log(this.files1);
    for (let k = 0; k < this.files1.length; k++) {
      fileslist = this.files1[k].name + ",";
    }
    this.SelectedFiles = fileslist.split(',');
  }

  onDrop(event: any) {

    var fileslist = "";
    var files = event;

    this.files1 = files;
    for (let k = 0; k < this.files1.length; k++) {
      if (this.files1[k].type == "") {
        alert("Please Upload only Folders")
        this.files1 = "";
      }
      fileslist = this.files1[k].name + ",";
    }
    this.SelectedFiles = fileslist.split(',');

  }
  workflowupload(){
    this.workflw = true;
    this.Uploaddocslarge();
  }
  Uploaddocs() {
    document.getElementById("cancelUpload").click();

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Uploading File to blocdrive...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Uploading File to blocdrive...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const frmData = new FormData();


    for (var i = 0; i < this.files1.length; i++) {
      frmData.append("fileUpload", this.files1[i]);
    }

    //frmData.append("fileUpload", this.files1);
    frmData.append("userId", this.uid);
    frmData.append("GUID", this.GUID);
    frmData.append("username", this.userName);
    frmData.append("folderid", this.allSubFolders.ParentId);
  
    this.httpService.post('https://blocdrive.com/blocdriveapi/api/UploadDocument/NewDocumentUploadMS/', frmData).subscribe(
      data => {
       // this.GetAllFolders1();
       // window.location.reload();

        // SHOW A MESSAGE RECEIVED FROM THE WEB API.
        if (data != null) {


          this.sMsg = data as string;
          // console.log(this.sMsg);
          localStorage.setItem("InwardFileId", data.toString())
          localStorage.setItem("InwardTypeFile", "true")
          localStorage.removeItem('InwardFolderId');

          //document.getElementById("cancelUpload").click();
          this.n = 100;
          this.createdmsg = "File Uploaded Successfully to blocdrive";
          // if (this.workflw == true) {
          //   this.route.navigate(['/inward'])
          // }
         // this.GetAllFolders1();
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);
          const audit = {
            TransactionName:"File Management",
            TransactionType:"Upload",
            TransactionDesc:"File",
            }
          this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
         // this.GetAllFolders1();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
            this.SelectedFiles = null;
           this.GetDetails(this.FileFoldID);
           // this.GetAllFolders1();
            if (this.workflw == true) {
              this.route.navigate(['/inward'])
            }
          }.bind(this), 3000);



        }
        else {
        }
      },
      (err: HttpErrorResponse) => {
        alert("Something went wrong. Please try again..!! ")
        // console.log(err.message);    // Show error, if any.
      });
  }

  async Uploaddocslarge() {      
    document.getElementById("cancelUpload").click();
  
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Uploading File to blocdrive...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Uploading File to blocdrive...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const frmData = new FormData();
  
    // const chunks: Blob[] = [];
    // for (var i = 0; i < this.files1.length; i++) {
    //   frmData.append("fileUpload", this.files1[i]);
    //   //naan barditord illinda
  
    //   // const fileChunks: ArrayBuffer[] = [];
    //   // let offset = 0;
    //    this.chunkSize = 40000;
    //   // while (offset < this.files1[i].size) {
    //   //   const chunk = this.files1[i].slice(offset, offset + this.chunkSize);
    //   //   const reader = new FileReader();
    //   //   reader.readAsArrayBuffer(chunk);
  
  
    for (let i = 0; i < this.files1.length; i++) {
     
       // 1 MB chunk size
      const file = this.files1[i];
      if(file.size >= 800000000){
        this.CHUNK_SIZE = 800000000;
      }
      else
      this.CHUNK_SIZE = file.size;
      const chunks = [];
  
      for (let start = 0; start < file.size; start += this.CHUNK_SIZE) {
        const end = Math.min(start + this.CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        
        const frmData = new FormData();
        const blob = new Blob([chunk], { type: file.type });
        const file1 = new File([blob], file.name, { type: file.type });
        frmData.append("fileUpload", file1);
        frmData.append('userId', this.uid);
        frmData.append('GUID', this.GUID);
        frmData.append('username', this.userName);
        frmData.append('folderid', this.allSubFolders.ParentId);
      

        if(end == file.size) {
          frmData.append('Uploaddone', "Yes");
          frmData.append('Filesize', file.size);
        }

        if(this.files1.length-1 == i){
          frmData.append('CompleteFiles', "Yes");
        }

        this.setid = i.toString()
        frmData.append('SetIDD', this.setid);
        
        try {
          const response = await this.httpService.post('https://blocdrive.com/blocdriveapi/api/UploadDocument/largefileupload/', frmData).toPromise();
          // if (response == "SUCC") {
          //   console.log(`Chunk ${start}-${end} uploaded successfully`);
          // }
          
          if (response == "Finish") {
         
   //......      this.sMsg = data as string;
         // console.log(this.sMsg);
    //......     localStorage.setItem("InwardFileId", data.toString())
         localStorage.setItem("InwardTypeFile", "true")
         localStorage.removeItem('InwardFolderId');

         //document.getElementById("cancelUpload").click();
         this.n = 100;
         this.createdmsg = "File Uploaded Successfully to blocdrive";
         // if (this.workflw == true) {
         //   this.route.navigate(['/inward'])
         // }
        // this.GetAllFolders1();
     //......    this.pid = data;
         this.folderCreating = false;
         this.foldercreated = true;
         clearInterval(this.num);

         const audit = {
          TransactionName:"File Management",
          TransactionType:"Upload",
          TransactionDesc:"File",
          }
        this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.SelectedFiles = null;
         this.GetDetails(this.FileFoldID);
         // this.GetAllFolders1();
          if (this.workflw == true) {
            this.route.navigate(['/inward'])
          }
          else
          window.location.reload();
        }.bind(this), 3000);

        //  window.location.reload();
          }
        
  
        } catch (error) {
          console.log(`Error uploading chunk ${start}-${end}: ${error}`);
        }
      }
    }
 //  this.Uploadfilebychunk();
  }


  



  // Delete(data) {
  //   var res;
  //   var doc = data.documentName.split(".");
  //   var docname = doc[0];
  //   var doctype = doc[1];
  //   if (data.folderName == null) {
  //     res = confirm("Do You Want to Delete " + data.documentName + "!!!");
  //   }
  //   else {
  //     res = confirm("Do You Want to Delete " + data.folderName + "!!!");
  //   }
  //   if (res == true) {
  //     if (data.hash) {
  //       var info = {
  //         Id: data.id,
  //         FolderID: data.folderId,
  //         FolderName: data.folderName,
  //         UserId: this.uid,
  //         GUID : this.GUID,
  //         Code: "Document",
  //         ParentID: data.folderId,
  //         RoleId: this.roleid,
  //         Click: null
  //       };
  //     }
  //     else {
  //       info = data;
  //     }
  //   }
  //   this.userservice.deletefolder(info).subscribe((data: any) => {
  //     this.GetDetails(this.FileFoldID);
  //     this.GetAllFolders1();
  //     const audit = {
  //       TransactionName:"File Management",
  //      TransactionType:"Delete",
  //      TransactionDesc:"File",
  //       DocumentName:docname + "." + doctype ,
  //       DocumentType:doctype

  //      }
  //    this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);

  //   });
  // }
  Delete(data) {
    var res;
    if(data.DocumentName != null){
      var doc = data.DocumentName.split(".");
      var docname = doc[0];
      var doctype = doc[1];
    }
    if (data.FolderName == null) {
      res = confirm("Do You Want to Delete " + data.DocumentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.FolderName + "!!!");
    }
    if (res == true) {
      if (data.Hash) {
        var info = {
          Id: data.Id,
          FolderID: data.FolderId,
          FolderName: data.FolderName,
          UserId: this.uid,
          GUID : this.GUID,
          Code: "Document",
          ParentID: data.FolderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      this.GetDetails(this.FileFoldID);
      this.GetAllFolders1();
      const audit = {
        TransactionName:"File Management",
       TransactionType:"Delete",
       TransactionDesc:"File",
        DocumentName:docname + "." + doctype ,
        DocumentType:doctype

       }
     this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);

    });
  }

  ViewDocumentword(aa, wordcontent) {
    let guid = aa.Fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  
  }

  ViewPpt(aa, pptcontent) {
    let guid = aa.Fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  }

  ViewxlsDoc(aa, xlscontent) {
    let guid = aa.Fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  }
  ViewDoc(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSFILE");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSFILE");


    this.route.navigate(['fileview/'])
  }

  canceltree() {
    this.ppopup = true;
  }

  audioplay: any;
  audiodate: any;


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggle(data) {
    this.CurrentFiles = data;
    if (data != null) {
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].Id == data.Id) {
          var index = this.selected.indexOf(data);
          this.selected.splice(index, 1);
          this.remove = true;
        }
      }
      if (this.remove == false) {
        this.selected.push(data);
      }
    }
    this.remove = false;

  }

  toggleDelete(data) {
    this.CurrentFiles = data;
    if (data != null) {
      // for (var i = 0; i < this.selectedDelete.length; i++) {
      //   if (this.selectedDelete[i].Id == data.Id) {
      //     var index = this.selectedDelete.indexOf(data);
      //     this.selectedDelete.splice(index, 1);
      this.selectedDelete.push(data);
      // }
      // }

    }

  }

  Search() {
    this.val = this.searchvalue;
    if (this.searchvalue.length != 0) {
      this.SearchEnabled = true;
    }
    else {
      this.SearchEnabled = false;
    }
    if (this.searchvalue.length > 1) {
      const inputRequest: Search = {
        Value: this.searchvalue,
        UserId: this.uid
      }

      this.userservice.SearchFiles(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }



  DeleteFile(id) {
    const inputRequest: Delete = {
      SelectedFiles: this.selectedDelete,
      Message: "Delete"
    }
    var result;
    if (this.selected.length != 0) {
      result = confirm("Do You Want to Delete Selected Files !!!");
    }
    else {
      result = confirm("Do You Want to Delete Selected Files !!!");
    }

    if (result == true)
      this.userservice.DeleteFiles(inputRequest).subscribe((data: any) => {
        this.R = data;
        var fol = this.CurrentFiles;

        this.n = 100;
        this.createdmsg = "Deleted Successfully";
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        clearInterval(this.num);
        const audit = {
           TransactionName:"File Management",
          TransactionType:"Delete",
          TransactionDesc:"File",
          }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();

      });
    else {
      this.deleteitem = false;
    }
  }
  onSearchChange(searchValue: string) {

    this.val = searchValue;
    if (this.val.length == 0) {
      setTimeout(() => {
        document.getElementById("moveid").style.display = "none";

      }, 1000);

    }
    if (this.val.length != 0) {
      document.getElementById("moveid").style.display = "none";
      this.SearchEnabled = true;
    }
    else {
      document.getElementById("moveid").style.display = "none";
      this.SearchEnabled = false;
    }
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID: this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }


  getpdfdocument()
  {
    this.val = ".pdf";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getpngdocument()
  {
    this.val = ".png";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  gettxtdocument()
  {
    this.val = ".txt";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getzipdocument()
  {
    this.val = ".zip";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getsqldocument()
  {
    this.val = ".sql";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getmpdocument()
  {
    this.val = ".mp4";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  // folder data

  // GetSubfolder(data) {
  //   if (this.SearchEnabled) {
  //     this.SearchEnabled = false;
  //   }
  //   this.subf = data.folderName;
  //   this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
  //     this.allSubFolders = respose;
  //     this.subf = this.allSubFolders.folderName;
  //     var Pid = this.allSubFolders.parentId;
  //     this.ShowResult = true;
  //     //localStorage.setItem(this.allSubFolders);
  //     localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //     localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //     localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));

  //     // console.log("user", this.allSubFolders);
  //     this.route.navigate(['allsubfolders/' + data.folderID]);
  //   });
  // }

  GetSubfolder(data) {
    if (this.SearchEnabled) {
      this.SearchEnabled = false;
    }
    this.subf = data.FolderName;
    this.folid = data.folderID
    if(this.folid == undefined){
      this.folid = data.FolderID;
    }
    this.userservice.getsubfolders(this.folid).subscribe((respose: any) => {
      this.allSubFolders = respose;


      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      this.ShowResult = true;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.ParentId));

      // console.log("user", this.allSubFolders);
      this.route.navigate(['allsubfolders/' + this.folid]);
    });
  }







  MoveDash1() {
    // this.shareitem = true;
    //Folder or SubFolder Data
    var getdata = localStorage.getItem("MoveData");
    this.getMoveData = JSON.parse(getdata);
    var isDOC = localStorage.getItem("IsDOCorFolder");
    //Doc Data
    var docdata = localStorage.getItem("DocData");
    var parrrid = localStorage.getItem("PARRRID");
    this.getDocData = JSON.parse(docdata);
    if (isDOC == "Doc") {
      this.destinationFolder = this.FileFoldID;
//......     this.sourceFolder = this.getMoveData.folderID;
if(this.getMoveData.folderID != undefined && this.getMoveData.folderID != null)
{
  this.sourceFolder = this.getMoveData.folderID;
}
if(this.getMoveData.FolderID != undefined && this.getMoveData.FolderID != null)
{
  this.sourceFolder = this.getMoveData.FolderID;
}
      const inputRequest: MoveFolderOrDocument = {
        SourceId: this.sourceFolder,
        DestinationId: this.destinationFolder,
        Parentidd: parrrid

      }
      this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
        if (data == "1") {
          this.notify = "Moved Successfully";
          setTimeout(() => (this.foladdednotify1 = true), 100);
          setTimeout(
            function () {
              this.foladdednotify1 = false;
            }.bind(this),
            3000)

          document.getElementById("moveid").style.display = "none";
          localStorage.removeItem("copied");
          //this.notify = "Moved Successfully"
          // setTimeout(() => this.foladdednotify = true, 100);
          // setTimeout(function () {
          //   this.foladdednotify = false;
          // }.bind(this), 3000);
          this.pid = data.ParentID;
          // this.GetDetails(this.pid);
          this.GetDetails(this.FileFoldID);
          this.GetAllFolders1();


        }
        else {
          this.GetDetails(this.pid);
          this.GetAllFolders1();

          this.notify = "Something went Wrong"
          setTimeout(() => this.foladdednotify1 = true, 100);
          setTimeout(function () {
            this.foladdednotify1 = false;
          }.bind(this), 3000);
        }
      });
    } else if (isDOC == "Img") {
      const inputRequest1: MoveDocData = {
        AliasUid: this.getDocData.UserID,
        DId: this.FileFoldID,
        SId: this.getDocData.folderId,
        BId: this.getDocData.blockId
      }
      this.moveService.moveDocument(inputRequest1).subscribe((data: any) => {
        if (data == "1") {
          this.notify = "Moved Successfully";
          const audit = {
             TransactionName:"File Management",
            TransactionType:"Move",
            TransactionDesc:"File",
            }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.foladdednotify1 = true), 100);
          setTimeout(
            function () {
              this.foladdednotify1 = false;
            }.bind(this),
            3000)

          document.getElementById("moveid").style.display = "none";
          localStorage.removeItem("copied");
          this.GetDetails(this.pid);
          this.GetAllFolders1();

          // setTimeout(() => {
          //   this.route.navigate(['/allfolders'])
          // },500);
        }
        else {
          this.notify = "Something went Wrong"
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.foladdednotify = false;
          }.bind(this), 3000);
        }
      });
    }
  }

  MoveDash(data: any) {
    this.shareitem = true;
    var parrrid = localStorage.getItem("PARRRID");
    this.destinationFolder = this.storedData;
    this.sourceFolder = this.getMoveData.FolderID;
    const inputRequest: MoveFolderOrDocument = {
      SourceId: this.sourceFolder,
      DestinationId: this.destinationFolder,
      Parentidd:parrrid
    }
    this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.pid = data.ParentID;
        this.GetDetails(this.pid);
        this.GetAllFolders1();

        this.notify = "Moved Successfully"
        setTimeout(() => this.foladdednotify1 = true, 100);
        setTimeout(function () {
          this.foladdednotify1 = false;
        }.bind(this), 3000);
        localStorage.removeItem("copied");
      }
      else {
        this.GetDetails(this.pid);
        this.GetAllFolders1();

        this.notify = "Something went Wrong"
        setTimeout(() => this.foladdednotify1 = true, 100);
        setTimeout(function () {
          this.foladdednotify1 = false;
        }.bind(this), 3000);
      }
    });
  }
  copied: any;
  foladdednotify1: any;
  MoveTo(data: any, Id: any) {
    //this.shareitem = true;
    this.storedData = data;
    this.IsDOCorFolder = Id;
    localStorage.setItem("IsDOCorFolder", Id);
    localStorage.setItem("MoveData", JSON.stringify(data));
    this.copied = 1;
    localStorage.setItem("copied", this.copied);
    this.notify = "Copied to Clipboard";
    setTimeout(() => (this.foladdednotify1 = true), 100);
    setTimeout(
      function () {
        this.foladdednotify1 = false;
      }.bind(this),
      3000)
    document.getElementById("moveid").style.display = "block";

  }

  MoveDoc(data: any, id: any) {
    localStorage.setItem("IsDOCorFolder", id);
    localStorage.setItem("DocData", JSON.stringify(data));
    this.copied = 1;
    localStorage.setItem("copied", this.copied);
    this.notify = "Copied to Clipboard";
    setTimeout(() => (this.foladdednotify1 = true), 100);
    setTimeout(
      function () {
        this.foladdednotify1 = false;
      }.bind(this),
      3000)
    document.getElementById("moveid").style.display = "block";
  }
  docsize1: any;
  Details(data, folderdetails) {
    this.Hash = data.hash;
    if (this.Hash) {
      this.DocumentName = data.documentName;
      this.ImageType = data.documentType;
      this.docsize = data.documentSize;
      this.DateCreated = data.date;
      this.CustId = data.userID;
    }
    this.docsize1 = this.formatBytes(this.docsize, 2)
    this.FolderName = data.folderName;
    this.CreatedOn = data.createdOn;
    this.CustId = data.userId;
    //this.Type = "Folder";
    this.modalService.open(folderdetails).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  // Download Folder
  // DownloadFolder(data) {
  //   const inputRequest = {
  //     Id: data.id,
  //     AUID: this.uid,
  //     FolderId: data.folderID,
  //     FolderName: data.folderName,
  //     GUID: this.GUID,
  //     BlockID: this.BlockId

  //   }
  //   this.spinner.show()
  //   this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
  //     this.spinner.hide()

  //     if (data != null) {
  //       this.downpath = data;
  //       window.open(this.downpath);
  //     }
  //     const audit = {
  //        TransactionName:"File Management",
  //       TransactionType:"Download",
  //       TransactionDesc:"File",
  //       }
  //     this.auditortransactionservice.AuditorTransactionDetails(audit);

  //   });

  // }

  DownloadFolder(data) {
    const inputRequest = {
      Id: data.Id,
      AUID: this.uid,
      FolderId: data.FolderID,
      FolderName: data.FolderName,
      GUID: this.GUID,
      BlockID: this.BlockId

    }
    this.spinner.show()
    this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
      this.spinner.hide()

      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
      }
      const audit = {
         TransactionName:"File Management",
        TransactionType:"Download",
        TransactionDesc:"File",
        }
      this.auditortransactionservice.AuditorTransactionDetails(audit);

    });

  }

  cancel() {
    localStorage.removeItem("copied");
    document.getElementById("moveid").style.display = "none";
  }



  //subshare

  //Share 

  getPercent(per) {
    return 10;
  }
  ShareFolder(data, Sharecontent) {
    this.getSubFolderdata(data);
  this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  getSubFolderdata(data) {
    //...... this.shareSubFolderFolderId = data.folderID;
    //...... this.shareSubFolderFolderName = data.folderName;
    //...... this.shareSubFolderFolderType = data.folderType;
    //...... this.shareSubFolderParentID = data.parentID;
    //...... this.shareSubFolderId = data.id;


    if(data.folderID == undefined)
    {
      this.shareSubFolderFolderId = data.FolderID;
    }
    else
    this.shareSubFolderFolderId = data.folderID;

    if(data.folderName == undefined){
      this.shareSubFolderFolderName = data.FolderName;
    }
    else
    this.shareSubFolderFolderName = data.folderName;

    if(data.folderType == undefined){
      this.shareSubFolderFolderType = data.FolderType;
    }
    else
    this.shareSubFolderFolderType = data.folderType;

    if(data.parentID == undefined){
      this.shareSubFolderParentID = data.ParentID;
    }
    else
    this.shareSubFolderParentID = data.parentID;

    if(data.id == undefined){
      this.shareSubFolderId = data.Id;
    }
    else
    this.shareSubFolderId = data.id;




  }
  frndslist: any = [];
  selectedFriend: any = [];
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  selectedFriendcancel;

  ShareFileMembers1(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        if (this.frndslist[index].userGUID == this.GUID) {
          this.selectedFriendcancel = this.frndslist[index].friendGUID;
          break;
        }
        this.selectedFriendcancel = this.frndslist[index].userGUID;
        break;
      }
    }

  }
  public n: number = 20;
  num: any;
  ShareExsistingSubFolderToUsers() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }


    const inputRequest: FolderShareToUers = {
      Id: "0",
//...... ToUsers: this.selectedFriend,
      ToUsers: this.selfrnds,
      BodyContent: "",
      FolderType: this.shareSubFolderFolderType,
      FolderName: this.shareSubFolderFolderName,
      Code: "SubFolder",
      ParentID: this.shareSubFolderParentID,
      FolderID: this.shareSubFolderFolderId,
      UserId: this.uid,
      AccessType: null,
      UserName: this.userName,
      UserEmail: "",
      CreatedOn: "",
      GUID: this.GUID,

    }
    //this.spinner.show();

    // console.log(inputRequest);
    this.userservice.shareExsistingSubFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {

          this.n = 100;
          this.createdmsg = "Shared items successfully"
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          this.GetDetails(this.shareSubFolderParentID);
          this.GetAllFolders1();

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetDetails(this.shareSubFolderParentID);
          this.GetAllFolders1();

          this.selectedFriend = [];
          this.accid = "";
        }
      });
  }

  // ShareOnlyFile(data: any) {
  //   this.statuschange = "";
  //   this.statuschange = data;
  //   this.ShareOnlyDoc = data.file;
  //   this.BlockId = data.blockId;
  //   this.FolderID = data.folderId;
  //   this.ShareOnlyDocName = data.documentName;

  // }

  ShareOnlyFile(data: any) {
    this.statuschange = "";
    this.statuschange = data;
    this.ShareOnlyDoc = data.File;
    this.BlockId = data.BlockId;
    this.FolderID = data.FolderId;
    this.ShareOnlyDocName = data.DocumentName;

  }

  ShareoneFile(data, Sharecontent) {
    this.ShareOnlyFile(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  SharOnlyFileToUsers() {
    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }

    this.sendFID = this.FolderID;
    this.sendBID = this.BlockId;
    const inputRequest: ShareOnlyFileVM = {
      FolderID: this.FolderID,
      File: this.ShareOnlyDoc,
      BlockID: this.BlockId,
      FromUserId: this.uid,
      ToUsers: this.selfrnds,
      //......   ToUsers: this.selectedFriend,
      DocumentName: this.ShareOnlyDocName,
      UserId: this.uid,
      AccessType: null,
      UserName: this.userName,
      BodyContent: null,
      UserEmail: null,
      CreatedOn: null,
      FromUserGUID: this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
      }
    }, 3000);
    this.userservice.shareOnlyFile(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared item successfully within blockchain!";
          this.pid = data;
          this.GetDetails(this.sendFID);
          this.GetAllFolders1();

          this.folderCreating = false;
          this.foldercreated = true;
          this.selectedFriend = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          this.selectedFriend = [];
            const audit = {
            TransactionName:"File Management",
           TransactionType:"Share",
           TransactionDesc:"File Share",
           }
         this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedFriend = [];
        }
      });
  }
  // Share Ends

  // Multi Move
  selectedSelected: any = [];
  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedSelected.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
  }

  GetAllFolders1() {
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders1 = respose;
      this.clicked();
      this.spinner.hide();
    });
  }
  clicked() {
    document.getElementById("ppup12").click();

  }

  // getfolders(id1) {

  //   let id = id1.folderID;
  //   let elem = document.getElementById(id);
  //   if (elem.childNodes.length < 2) {

  //     var icn = document.getElementById(id1.id);
  //     if (icn != null) {
  //       icn.setAttribute("class", "fa fa-caret-down");

  //     }

  //     this.FileFoldID = id;
  //     this.userservice.getsubfolders(id).subscribe((respose: any) => {
  //       this.allSubFolders = respose;
  //       this.subf = this.allSubFolders.fileName;
  //       this.fid = this.allSubFolders.parentId;
  //       // console.log("user", this.allSubFolders);
  //       // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //       //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //       //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

  //       var mdv1 = document.createElement("div");
  //       for (let i = 0; i < this.allSubFolders.subFolders.length; i++) {
  //         var mdv = document.createElement("div");
  //         mdv.setAttribute("id", this.allSubFolders.subFolders[i].folderID);
  //         mdv.setAttribute("style", "padding-left:20px");
  //         var elm = document.createElement("span");
  //         var img = document.createElement("img");
  //         img.setAttribute("src", "./assets/img/folder.svg");
  //         img.setAttribute("width", "30px");
  //         img.setAttribute("height", "30px");
  //         var elm1 = document.createElement("span");
  //         elm1.innerHTML = this.allSubFolders.subFolders[i].folderName;

  //         var elm12 = document.createElement("i");

  //         elm12.setAttribute("class", "fa fa-caret-right");
  //         elm12.setAttribute("id", this.allSubFolders.subFolders[i].id);

  //         elm12.addEventListener('click', this.getfolders.bind(this, this.allSubFolders.subFolders[i]));



  //         elm.appendChild(elm12);
  //         elm.appendChild(img);
  //         elm.appendChild(elm1);

  //         mdv.appendChild(elm);

  //         elm.addEventListener('click', this.gotoFolder.bind(this, this.allSubFolders.subFolders[i]));


  //         mdv1.appendChild(mdv);
  //       }
  //       elem.appendChild(mdv1);


  //     });


  //   }
  //   else {

  //     document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
  //     let elem = document.getElementById(id);
  //     while (elem.childNodes.length > 1) {
  //       elem.removeChild(elem.lastChild);
  //     }
  //   }
  // }
  getfolders(id1) {

    let id = id1.folderID;
    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      this.userservice.getsubfolders(id).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.subf = this.allSubFolders.FileName;
        this.fid = this.allSubFolders.ParentId;
        // console.log("user", this.allSubFolders);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders.SubFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders.SubFolders[i].FolderID);
          mdv.setAttribute("style", "padding-left:20px");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");
          elm1.innerHTML = this.allSubFolders.SubFolders[i].FolderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders.SubFolders[i].Id);

          elm12.addEventListener('click', this.getfolders.bind(this, this.allSubFolders.SubFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          elm.addEventListener('click', this.gotoFolder.bind(this, this.allSubFolders.SubFolders[i]));


          mdv1.appendChild(mdv);
        }
        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }
  gotoFolder(data) {
    this.destinationFold = data;
  }

  gotoFolder12(data) {
 //......   this.folderid = data.FolderID;
 if(data.FolderID != undefined &&  data.FolderID != null){
  this.folderid = data.FolderID;
  this.showbutton = false;
}
if(data.folderID != undefined &&  data.folderID != null){
  this.folderid = data.folderID;
  this.showbutton = false;
}
  }

  Uploaddocs1() {
    this.UploadFolder();

  }

  UploadFolder() {

    if(this.folderid == null){
      alert("Please select the path to upload folder")
    }
    else{
      document.getElementById("cancelUpload1").click();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.Creatingmsg = "Uploading Folder to blocdrive...";
      this.getPercent(this.n);
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Uploading Folder to blocdrive...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.DateTime = new Date().getTimezoneOffset();
      // let latest_date = this.datepipe.transform(this.DateTime, 'MM-dd-yyyy hh:mm:ss a');
      this.uploadfolderclicked = true;
      this.frmData1.append("Level", this.SelectedLevel);
      this.frmData1.append("FolderId", this.folderid);
      this.frmData1.append("FolderName", this.SelectedFolderName);
      this.frmData1.append("UserId", this.uid);
      this.frmData1.append("GUID", this.GUID);
      this.frmData1.append("date", (-(this.DateTime)).toString());
  
      this.httpService.post('https://blocdrive.com/blocdriveapi/api/UploadFolder/NewAllFolderUpload/', this.frmData1).subscribe(
        data => {
          this.GetAllFolders1();
  window.location.reload();
          if (data != null) {
  
            localStorage.setItem("InwardFolderId", data.toString())
            localStorage.setItem("InwardTypeFile", "false")
            localStorage.removeItem('InwardFileId');
  
            this.n = 100;
            this.createdmsg = "Folder uploaded successfully to blocdrive";
            this.GetAllFolders1();
            this.pid = data;
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
  
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
              this.SelectedFiles = null;
              this.GetDetails(this.FileFoldID);
              this.GetAllFolders1();
  
              this.ppopup = true;
              if (this.roleid == 1 || this.roleid == 8) {
                this.route.navigate(['/inward'])
  
              }
            }.bind(this), 3000);
  
            const audit = {
               TransactionName:"File Management",
              TransactionType:"Upload",
              TransactionDesc:"Folder",
              }
            this.auditortransactionservice.AuditorDetailsProject(audit);
            this.GetAllFolders1();
            window.location.reload();
  
          }
        },
        (err: HttpErrorResponse) => {
          // console.log(err.message);
          this.ppopup = true;
  alert("Something went wrong!!! Please try again");
          this.GetDetails(this.FileFoldID);
          this.GetAllFolders1();
  
          // this.spinner.hide();
        });
    }
   
  }
  cancelupload() {
    this.ppopup = true;
  }
  uploadhere() {
    var uploadhere = document.getElementById("Uploadhere");
    if (uploadhere.style.backgroundColor == "blue") {
//......     uploadhere.style.backgroundColor = "transparent";
      uploadhere.style.backgroundColor = "blue";

    }
    else {
      uploadhere.style.backgroundColor = "blue";
    }
    let href = window.location.href.split('/');
    let val = href[href.length - 1];
    if (val != "allfolders" && val != "allsubfolders") {
//......  this.folderid = val;
      this.folderid = "0";

    }
    else {
      this.folderid = "0";
    }
  }

  // getfolders2(id1) {
  //   this.mvlfd = false;
  //   let id = id1.folderID;
  //   let elem = document.getElementById(id);
  //   if (elem.childNodes.length < 2) {

  //     var icn = document.getElementById(id1.id);
  //     if (icn != null) {
  //       icn.setAttribute("class", "fa fa-caret-down");

  //     }

  //     this.FileFoldID = id;
  //     this.userservice.getsubfolders(id).subscribe((respose: any) => {
  //       this.allSubFolders = respose;
  //       this.subf1 = this.allSubFolders.fileName;
  //       this.fid = this.allSubFolders.parentId;
  //       // console.log("user", this.allSubFolders);
  //       // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
  //       //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
  //       //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

  //       var mdv1 = document.createElement("div");
  //       for (let i = 0; i < this.allSubFolders.subFolders.length; i++) {
  //         var mdv = document.createElement("div");
  //         mdv.setAttribute("id", this.allSubFolders.subFolders[i].folderID);
  //         mdv.setAttribute("style", "padding-left:20px");
  //         var elm = document.createElement("span");
  //         var img = document.createElement("img");
  //         img.setAttribute("src", "./assets/img/folder.svg");
  //         img.setAttribute("width", "30px");
  //         img.setAttribute("height", "30px");
  //         var elm1 = document.createElement("span");
  //         elm1.innerHTML = this.allSubFolders.subFolders[i].folderName;

  //         var elm12 = document.createElement("i");

  //         elm12.setAttribute("class", "fa fa-caret-right");
  //         elm12.setAttribute("id", this.allSubFolders.subFolders[i].id);

  //         elm12.addEventListener('click', this.getfolders2.bind(this, this.allSubFolders.subFolders[i]));



  //         elm.appendChild(elm12);
  //         elm.appendChild(img);
  //         elm.appendChild(elm1);

  //         mdv.appendChild(elm);

  //         img.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.subFolders[i]));
  //         elm1.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.subFolders[i]));


  //         mdv1.appendChild(mdv);
  //       }




  //       elem.appendChild(mdv1);


  //     });


  //   }
  //   else {

  //     document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
  //     let elem = document.getElementById(id);
  //     while (elem.childNodes.length > 1) {
  //       elem.removeChild(elem.lastChild);
  //     }
  //   }
  // }
  getfolders2(id1) {
    this.mvlfd = false;
    //......
    this.folidd = "";
    if(id1.folderID == undefined){
      this.folidd = id1.FolderID; 
    }
    else if(id1.folderID != undefined && id1.folderID != null){
      this.folidd = id1.folderID;
    }
    let id = this.folidd;
    //......
//......    let id = id1.FolderID;

    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.Id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      this.userservice.getsubfolders(id).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.subf1 = this.allSubFolders.FileName;
        this.fid = this.allSubFolders.ParentId;
        // console.log("user", this.allSubFolders);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders.SubFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders.SubFolders[i].FolderID);
          mdv.setAttribute("style", "padding-left:20px");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");
          elm1.innerHTML = this.allSubFolders.SubFolders[i].FolderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders.SubFolders[i].Id);

          elm12.addEventListener('click', this.getfolders2.bind(this, this.allSubFolders.SubFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          img.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.SubFolders[i]));
          elm1.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.SubFolders[i]));


          mdv1.appendChild(mdv);
        }




        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }
  ShareFileViaMail() {

  }
  ImportFromMail() {

  }
  MotoselectedFolde(Movetofold) {
    this.ppopup = false;
    this.GetAllFolders1();
    this.mvlfd = true;

    document.getElementById("ppup12").click();
  }

  version(id) {
    this.route.navigate(['ver/' + id.BlockId]);
  }

  MoveMultiFolder(id) {
    document.getElementById("closemove").click();
    this.ppopup = true;
    const inputRequest: MoveMultiFolder = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      DestinationId: id.folderID,
    }
    // if (this.selectedSelected.length == 1) {
    //   this.alert = "Moved " + this.selectedSelected.length + " Item to " + id.FolderName;
    // }
    // else {
    //   this.alert = "Moved " + this.selectedSelected.length + " Items to " + id.FolderName;
    // }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Moving selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {

        this.Creatingmsg = "Moving selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.MoveMultiFileFolders(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      this.n = 100;
      this.createdmsg = "Moved items successfully!"
      this.Showmoveitem = true;
      this.folderCreating = false;
      this.checkenable = false;
      this.foldercreated = true;
      clearInterval(this.num);

      setTimeout(function () {
        this.folderCreating = false;
        this.foldercreated = false;
        this.generalprogressbar = false;
        this.errormsg = false;
        this.showper = false;
      }.bind(this), 3000);
      setTimeout(function () {
        this.Showmoveitem = false;
      }.bind(this), 6000);
      this.selectedSelected = [];
      this.selectedFriend = [];
      this.multi = false;
    });
  }


  DeleteMorecoin(Deletecontent) {
    this.modalService.open(Deletecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  Deleteselected() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Deleting selected items";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Deleting selected items";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const inputRequest: DeleteMulit = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      UserId: this.uid,
      GUID: this.GUID,
      ModifiedOn: null
    }
    // console.log(inputRequest);
    this.userservice.DeleteMore(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Deleted selected items successfully!"
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();

        const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Delete",
          TransactionDesc:"Folder",
          FolderName:this.selectedSelected[0].folderName
          }
          this.auditortransactionservice.AuditorDetailsProject(audit);

        //this.notify = "Deleted Successfully"
        // setTimeout(() => this.foladdednotify = true, 100);
        // setTimeout(function () {
        //   this.foladdednotify = false;
        // }.bind(this), 3000);


        this.selectedSelected = [];
        this.multi = false;
        this.checkenable = false;
        setTimeout(() => {
        }, 500);
        //this.GetAllFolders();
        //this.spinner.hide();
      }
      else {
        this.checkenable = false;
        this.multi = false;
        this.notify = "Something went Wrong"
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);

        this.GetDetails(this.FileFoldID);
        this.GetAllFolders1();
      }
    });

  }
  ShareExsistingMultiFolderToUsers() {
    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }
    const inputRequest: MultiFolderShareVM = {
      Id: this.shareUniqueID,
      //...... ToUsers: this.selectedFriend,
      ToUsers: this.selfrnds,
      USerId: this.uid,
      GUID: this.GUID,
      Code: "MainFolder",
      SelectedFolders: this.selectedSelected,
      AccessType: null,
      BodyContent: null,
      UserName: this.userName,
      ModifiedOn: null
    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Sharing selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared items successfully!"
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);
          // this.notify = "Folder Shared Succesfully";
          // setTimeout(() => this.dismissalert = true, 100);
          // setTimeout(function () {
          //   this.dismissalert = false;
          // }.bind(this), 3000);
          // this.spinner.hide();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedSelected = [];
          this.multi = false;

        }
        else {
          //alert("Something went wrong!!please try again");
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        this.spinner.hide();
      });
  }

  open(Sharecontent) {
    this.shareitem = true;

    this.selectedSelected = null;
    this.selectedSelected = this.allFolders1;
    this.selectedFriend = [];
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  filesPicked(files) {
    // this.getFoldersforCompare();
    this.frmData1 = null;
    this.sameNames = null;
    this.uploadfolderclicked = true;
    this.frmData1 = new FormData();
    for (let i = 0; i < files.length; i++) {
      this.SelectedFolderandFiles.push(files[i]);
      this.frmData1.append("fileUpload", files[i]);
    }
    //  console.log(files);
    //document.getElementById("OpenModalTreeStructure").click();
    this.uploadfolderclicked = false;
    var folname = files[0].webkitRelativePath.split('/');


    for (var i = 0; i < this.folderlist.length; i++) {
      if (this.folderlist[i].FolderName == folname[0]) {
        this.sameFolderName.push(this.folderlist[i].FolderName);
        this.replaceFolderid.push(this.folderlist[i])
        continue;
      }
    }

    if (this.sameFolderName.length != 0) {
      this.sameNames = this.sameFolderName[0];
    }

    if (this.sameNames) {
      for (let index = 0; index < this.replaceFolderid.length; index++) {
        if (index == 0) {
          this.relaceFolderlist = this.replaceFolderid[index].FolderId;
        }
        else {
          this.relaceFolderlist = this.relaceFolderlist + "," + this.replaceFolderid[index].FolderId;
        }
      }
      //  console.log(this.relaceFolderlist);
      document.getElementById("duplicateFolder1").click();
      //this.GetFolderandSubFolder();  
      this.frmData1.append("replaceFolderid", this.relaceFolderlist);
    }
    else {
      //  this.ViewVideo("folderview", "videocontent");
      //   this.addFriend("addfriendcontent");
      this.ppopup = false;
      var pp = document.getElementById("ppup");
      if (pp) {
        pp.click();
      }
      else {
        console.log("illa");
      }

    }
  }

  openmodal() {
    document.getElementById("openm").click();
  }
  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
  savenewClient() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    if (this.client == null || this.client == undefined || this.client == "") {
      alert("Please add your client name");
    }
    else {
      this.spinner.show();
      const inputRequest: AddNewFolder = {
        Id: 0,
        FolderID: "0",
        RoleId: this.roleid,
        FolderName: this.client,
        UserId: this.uid,
        GUID: this.GUID,
        Code: "Client",
        ParentID: "0",
        Click: null,
        date: nd
      }
      this.userservice.addclient(inputRequest).subscribe((dat: any) => {
        if (dat == 1) {
          alert("Added your client default folders successfully in the home page");
        }
        else {
          alert("Something went wrong! Please try again")
        }
        this.spinner.hide();
        this.msg = "";
      });
    }
  }
  EsignDocumentView(aa:any) {
    //localStorage.setItem("blockId",aa.blockId);
    //localStorage.setItem("PdfFile",aa.file);
    localStorage.setItem("blockId",aa.BlockId);
    localStorage.setItem("PdfFile",aa.file);
    this.route.navigate(['/viewer']);
  }
  addnewFolder(folderview, subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }

  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.file;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  wanttosearch(){
   // localStorage.setItem('pdfviewerlink',  "this.pdfUrl");
  
    window.open("https://blocdrive.com/blocdrivepdfviewer/#/pdfview/"+this.zzz.BlockId);
   // localStorage.storage = this.pdfUrl.storage
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.file
    this.videodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }

  Viewaudio(cc, videocontent) {
    this.audioplay = cc.file
    this.audiodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  CheckDownloadMultiple() {
    if (this.selectedSelected.length == 1) {
      if (this.selectedSelected[0].documentName != null) {
        this.DownloadFile(this.selectedSelected[0])
      }
      else if(this.selectedSelected[0].DocumentName != null) {
        this.DownloadFile(this.selectedSelected[0])
      }
      else if (this.selectedSelected[0].folderName != null) {
        this.DownloadFolder(this.selectedSelected[0])
      }
      else if (this.selectedSelected[0].FolderName != null) {
        this.DownloadFolder(this.selectedSelected[0])
      }
    }
    else {
      this.DownloadMultiple();
    }
  }

  // Download File
  // DownloadFile(data) {
  //   this.doctype = data.documentType
  //   console.log(data);
  //   const inputRequest = {
  //     Id: data.Id,
  //     AUID: this.uid,
  //     FolderId: data.folderId,
  //     FolderName: data.FolderName,
  //     BlockID: data.blockId,
  //     ipaddress: this.IPAddress,
  //     dnsip: this.dnsIP,
  //     browser: this.getBrowserName(),
  //     transaction: null,
  //     GUID:this.GUID
  //   }
  //   this.showper = true;
  //   this.generalprogressbar = true;
  //   this.folderCreating = true;
  //   this.getPercent(this.n);
  //   this.Creatingmsg = "Dowloading items within blockchain...";
  //   this.num = setInterval(() => {
  //     if (this.n <= 90) {
  //       this.Creatingmsg = "Dowloading items within blockchain...";
  //       this.n = this.n + this.getPercent(this.n);
  //       if (this.n == 90) {
  //         this.n = 90;
  //         clearInterval(this.num);
  //       }
  //     }
  //   }, 3000);
  //   this.userservice.downloadFile(inputRequest).subscribe((data: any) => {
  //     if (data != null) {
  //       this.n = 100;
  //       this.createdmsg = "Dowloaded items successfully within blockchain! "
  //       this.folderCreating = false;
  //       this.foldercreated = true;
  //       setTimeout(function () {
  //         this.folderCreating = false;
  //         this.foldercreated = false;
  //         this.generalprogressbar = false;
  //         this.errormsg = false;
  //         this.showper = false;
  //         this.n = 0;

  //       }.bind(this), 3000);

  //       this.downpath = data;
  //       this.seleteddownload = true;
  //       window.open(this.downpath);
  //       const audit = {
  //         TransactionName:"File Management",
  //        TransactionType:"Download",
  //        TransactionDesc:"File",
  //        DocumentType:this.doctype
  //        }
  //      this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
  //     }
  //     else {
  //       this.notify = "Something Went Wrong. Try again.!!"
  //       setTimeout(() => this.dismissalert = true, 100);
  //       setTimeout(function () {
  //         this.dismissalert = false;
  //       }.bind(this), 3000);
  //       this.GetAllFolders1();
  //       this.spinner.hide();
  //     }
  //   });

  // }

  //to download file by its original name
  abc(data1){
    this.DownloadFile(data1);
      }

  DownloadFile(data) {
    this.doctype = data.DocumentType
    console.log(data);
    const inputRequest = {
      Id: data.Id,
      AUID: this.uid,
      FolderId: data.FolderId,
      FolderName: data.FolderName,
      BlockID: data.BlockId,
      ipaddress: this.IPAddress,
      dnsip: this.dnsIP,
      browser: this.getBrowserName(),
      transaction: null,
      GUID:this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Dowloading items within blockchain...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Dowloading items within blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    this.userservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Dowloaded items successfully within blockchain! "
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n = 0;

        }.bind(this), 3000);

        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
        const audit = {
          TransactionName:"File Management",
         TransactionType:"Download",
         TransactionDesc:"File",
         DocumentType:this.doctype
         }
       this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
      }
      else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.GetAllFolders1();
        this.spinner.hide();
      }
    });

  }
//Due Diligence
addDueDeligence(folderviewdeligence, folderdeligence){
  this.modalService.open(folderdeligence).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;

  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.mm = this.dname;
  });
}


saveduedeligence() {
  var offset = new Date().getTimezoneOffset();
  var nd = 0.0;
  nd = -(parseFloat(offset.toString()));
  if (this.dname == null || this.dname == undefined || this.dname == "") {
    alert("Please add your Diligence name");
  }
  else {
    this.spinner.show();
    const inputRequest: AddNewFolder = {
      Id: 0,
      FolderID: "0",
      RoleId: this.roleid,
      FolderName: this.dname,
      UserId: this.uid,
      GUID:this.GUID,
      Code: "Diligence",
      ParentID: "0",
      Click: null,
      date: nd
    }
    this.userservice.adddiligence(inputRequest).subscribe((dat: any) => {
      if (dat == 1) {
        alert("Added your Diligence default folders successfully");
     
        this.GetAllFolders1();
      }
      else {
        alert("Something went wrong! Please try again")
      }
      this.spinner.hide();
      this.msg = "";
    });
  }
}


  // Download Multiple
  DownloadMultiple() {
    const inputRequest = {
      SelectedFiles: this.selectedSelected,
      Message: "Download",
      UserId: this.uid,
      ipaddress: this.IPAddress,
      dnsip: this.dnsIP,
      browser: this.getBrowserName(),
      transaction: null,
      GUID:this.GUID
    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Dowloading items within blockchain...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Dowloading items within blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    this.userservice.DownloadMultipleFolders(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Dowloaded items successfully within blockchain! "
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n = 0;

        }.bind(this), 3000);
        this.selectedSelected = [];
        this.GetAllFolders1();
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
        //this.spinner.hide();
      }
      else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.selectedSelected = [];
        this.GetAllFolders1();
        this.spinner.hide();
      }
    });

  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "Edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "Chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "Firefox";
      case agent.indexOf("safari") > -1:
        return "Safari";
      default:
        return "Other";
    }
  }

  onItemSelect(item: any) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
  onSelectAll(items: any) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
  onItemDeSelectAll(items: any) {
    var aa = this.selectedItems;
    this.selectedItems= [];

  }
  onItemDeSelect(items) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
}

