import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GmailmigrationService } from 'src/app/Shared/GmailMigration/gmailmigration.service';
import { MailboxVM } from 'src/app/ViewModels/Email';
import { SourceMail } from 'src/app/ViewModels/SourceMail';

@Component({
  selector: 'app-gmail-mails',
  templateUrl: './gmail-mails.component.html',
  styleUrls: ['./gmail-mails.component.css']
})
export class GmailMailsComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  GmailList: any;
  searchText: any;
  DescendingOrderList:any;
  AscendingOrderList:any;
  sourcemailid:any;
  p: number = 1;
  threadId: string;
  CurrentFiles:any;
  selectedSelected: any = [];
  DateTime: Date;
  generalprogressbar: boolean;
  folderCreating: boolean;
  q: number = 1;
  showper: boolean;
  public n: any = 20; num: any; 
  notify: string;
  constructor( private spinner: NgxSpinnerService,private datepipe: DatePipe,private gmailservice : GmailmigrationService,private route: Router,private router: ActivatedRoute) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");

    }
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.sourcemailid = params["id"];
      }
    });
    if(this.sourcemailid!=undefined){
      this.GetGmailListSourceMail(this.sourcemailid);
    }
   }

  ngOnInit(): void {
 

    this.router.params.subscribe(params => {
      const id = params['id']; // Or 'params.get('id')' if using paramMap
      this.handleIdChange(id);
    });

    if(this.sourcemailid!=undefined){
      this.GetGmailListSourceMail(this.sourcemailid);
    }
    else{
      this.GetGmailList();
    }

    
  }
  handleIdChange(id: string) {
    // Logic to handle changes in the 'id' parameter
    console.log('ID changed:', id);
    // You can add your logic here, e.g., call to a service based on the new ID
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.sourcemailid = params["id"];
      }
    });
    if(this.sourcemailid!=undefined){
      this.GetGmailListSourceMail(this.sourcemailid);
    }
  }

  Descendingorder(){
    this.GmailList=this.DescendingOrderList;
  }
  Ascendingorder(){
    this.GmailList=this.AscendingOrderList;
  }
  GetGmailListSourceMail(id:any){
    this.spinner.show();
    const inputRequest: SourceMail ={
      UID  :this.uid,
      SourceMailID:id
    }
    this.gmailservice.getGmaillistSourseMail(inputRequest).subscribe((respose: any) => {
      this.GmailList = respose.descendinglist;
      this.DescendingOrderList=respose.descendinglist;
      this.AscendingOrderList=respose.ascendingList;


      console.log(this.GmailList);
      
      this.spinner.hide(); 
    });
  }

  toggleSelect(data) {
    this.CurrentFiles = data;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
        }
        else {
          this.selectedSelected.push(data); 
        }
      }
    }

    // for (var i = 0; i < this.selectedSelected.length; i++) {
    //   if (this.selectedSelected[i].folderName != null) {
    //     this.sfolder = this.sfolder + 1;
    //   }
    //   else {
    //     this.sfiles = this.sfiles + 1;
    //   }
    // }


  
  }
  GetGmailList() {
    this.spinner.show();
    var info ={
      UID  :this.uid
    }
    this.gmailservice.getGmaillist(this.uid).subscribe((respose: any) => {
      this.GmailList = respose.descendinglist;
      this.DescendingOrderList=respose.descendinglist;
      this.AscendingOrderList=respose.ascendingList;


      console.log(this.GmailList);
      
      this.spinner.hide(); 
    });
  }
  singleDelete(data){
    this.selectedSelected.push(data);
    this.multidelete();
  }

  multidelete() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.notify = "Deleting Messages...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.notify = "Deleting Messages...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.uid,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.gmailservice.multideletemessages(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.notify = "Deleted Successfully...!"
        //this.GetInboxList();
        if(this.sourcemailid!=undefined){
          this.GetGmailListSourceMail(this.sourcemailid);
        }
        else{
          this.GetGmailList();
        }
        this.folderCreating = false;
        this.selectedSelected = [];
    
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        //this.GetInboxList();
        if(this.sourcemailid!=undefined){
          this.GetGmailListSourceMail(this.sourcemailid);
        }
        else{
          this.GetGmailList();
        }
    }
    else{
      this.selectedSelected = [];
      //this.GetInboxList();
      if(this.sourcemailid!=undefined){
        this.GetGmailListSourceMail(this.sourcemailid);
      }
      else{
        this.GetGmailList();
      }
 
    }
    });
  }
  getPercent(per) {
    return 10;
  }
  ReadMessage(data){
   this.route.navigate(['readgmail/'+ data.threadId ]);

 //window.open("http://localhost:4200/readgmail/"+data.threadId);
  }


}