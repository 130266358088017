import { Component, OnInit } from '@angular/core';
import { TaskService } from '../Shared/Task/task.service';
import { TaskType } from '../ViewModels/TaskType';
import { NgForm } from '@angular/forms';
import { taskviewmodel } from '../ViewModels/taskviewmodel';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-assign-task',
  templateUrl: './assign-task.component.html',
  styleUrls: ['./assign-task.component.css']
})
export class AssignTaskComponent implements OnInit {
  isLoggedIn: boolean;
  uid: string;
  username: string;
  Projectlistid: any;
  ProjectTasksDropDown: any;
  taskid: any;
  selecteduserid: any;
  userList: any;
  tasktypelist: any = [];
  selectedTaskType: any;
  public dismissalert = false;
  notify: string
  assigndate:any
  assigntime:any
  property:any
  Projects: any;
  DateTime: Date;
  files: any;
  roleid: string;
  mobileview: boolean;
  GUID: string;
  constructor(private taskservice: TaskService, private spinner:NgxSpinnerService,private auditortransactionservice:AuditorTransactionService,
    private userservice:UserDetailsService,private datepipe:DatePipe,private httpService:HttpClient) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {
    var tasktypeTask: TaskType = {
      TaskTypeId: 1,
      TaskTypeName: "Task"
    }
    this.tasktypelist.push(tasktypeTask);
    var tasktypeTestCase: TaskType = {
      TaskTypeId: 2,
      TaskTypeName: "Test Case"
    }
    this.tasktypelist.push(tasktypeTestCase);
    this.GetProjects();
    this.taskassignnewuserList();
  }
  GetProjects() {
    this.taskservice.getTeamProject(this.GUID).subscribe((response: any) => {
      this.Projects = response;
    })
  }
  onSelectedProject(id) {
    this.Projectlistid = id;
    this.spinner.show();
    this.taskservice.getprojecttasksforAssign(this.Projectlistid).subscribe((response: any) => {
      this.ProjectTasksDropDown = response;  
        this.spinner.hide();
    });
  }
  onSelectedtaskassign(id) {
    this.taskid = id;
  }
  onSelectedTaskType(id) {
    this.selectedTaskType = id;
  }
  onSelectUser(id) {
    this.selecteduserid = id;
  }
  taskassignnewuserList() {
    this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
      this.userList = Data;
    })
  }
  onChangeUploadDoc(event: any) {
        this.files = [].slice.call(event.target.files);
  }
  addTaskassign(form: NgForm) {
  
    if(this.Projectlistid==null || this.Projectlistid==undefined){
     alert("Please select Project")

   }
   else if(this.selectedTaskType==null || this.selectedTaskType==undefined){
     alert("Please select Task Type")

   }
   else if(this.taskid==null || this.taskid==undefined){
     alert("Please select Task")

   }
   else if(this.selecteduserid==null || this.selecteduserid==undefined){
     alert("Please select To User")

   }
   else if( this.files==undefined ||  this.files==null){
     alert("Please select atleast one file")
   }
   else{

 
   this.DateTime = new Date();
   let latest_date =this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
   const inputRequest: taskviewmodel = {
     ID: 0,
     ProjectName: this.Projectlistid,
     Type: this.selectedTaskType,
     Task: this.taskid,
     AssignTo: this.selecteduserid,
     AssignDate: form.controls["assigndate"].value,
     AssignedTime: form.controls["assigntime"].value,
     property: form.controls["property"].value,
     Date:latest_date
   }
   const frmData = new FormData();
   for (var i = 0; i < this.files.length; i++) {
     frmData.append("fileUpload", this.files[i]);
   }
   frmData.append("ProjectName", this.Projectlistid);
   frmData.append("Type", this.selectedTaskType);
   frmData.append("Task",  this.taskid);
   frmData.append("AssignTo", this.selecteduserid);
   frmData.append("AssignDate", form.controls["assigndate"].value);
   frmData.append("AssignedTime", form.controls["assigntime"].value);
   frmData.append("Property", form.controls["property"].value);
   frmData.append("Date",latest_date);
   frmData.append("UserId",this.uid);

   this.spinner.show();
   this.httpService.post('https://blocdrive.com/blocdriveapi/api/AssignTask/PostSaveAssignTask/', frmData).subscribe(
     data => {
     if (data != null) {
       this.notify = "Task assigned succesfully"
       const audit = {
        TransactionName:"Project Management",
        TransactionType:"Create",
        TransactionDesc:"Assign Task",
        }
       this.auditortransactionservice.AuditorDetailsProject(audit);
       this.Projectlistid=null
       this.selectedTaskType=null
       this.taskid=null
       this.selecteduserid=null
       setTimeout(() => this.dismissalert = true, 100);
       setTimeout(function () {
         this.dismissalert = false;
       }.bind(this), 3000);
               this.spinner.hide();
     }
     else {
       this.notify = "Something went wrong"
       setTimeout(() => this.dismissalert = true, 100);
       setTimeout(function () {
         this.dismissalert = false;
       }.bind(this), 3000);
               this.spinner.hide();
     }
   });
 }
}
  closealert(){
    this.dismissalert = false;
  }
}
