import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from 'src/app/ViewModels/Role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  readonly url="https://blocdrive.com/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  saveRole(data:Role){
    return this.http.post(this.url +"Role/PostSaveRole",data);
  }

  getAllRoles(id){
    return this.http.get(this.url +"Role/GetAllRoles/" + id);
  }
  getAllRoledata(id){
    return this.http.get(this.url +"Role/GetAllRoledata/" + id);
  }
  deleteRole(id: number){
    return this.http.get(this.url +"Role/GetDeleteRoleData/"+ id);
  }

  UpdateRole(data:Role){
    return this.http.post(this.url +"Role/PutRole",data);
  }
  getAllRolesName(id){
    return this.http.get(this.url +"Role/GetAllRolesName/" + id);
  }
}
