<app-nav-bar></app-nav-bar>
<br>
<div class="col-md-12 row">
    <div class="col-md-3">
        <app-composemail></app-composemail>
    </div>
    <div class="col-md-9">
        <div class="inbox-head">
            <div class="col-md-8">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                <br />
            </div>

        </div>
        <button class="btn btn-primary" style="height: 48px;" data-toggle="modal" data-target="#trashEmpty"
        mdbWavesEffect >Empty Trash</button>
        <div  style="font-weight: bold; cursor: pointer;" (click)="sortby()">
            Name
            <span class="fa fa-stack">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
            </span>
        </div>
        <table class="table table-hover">
            <thead>
                <!-- <tr id="theadstyle">
                                    <th id="textdisplay" (click)="sortby()">From</th>
                                    <th id="textdisplay">Subject</th>
                                    <th id="textdisplay" (click)="sortbydate()">Sent on</th>
                                </tr> -->
            </thead>
            <tbody style="background: #ecf0f1">
                <tr class="unread" id="{{ ret.Id }}" *ngFor="let ret of deleteList| filter: searchText |paginate: { itemsPerPage: 10, currentPage: p };index as i" style="cursor: pointer">
                    <td >
                        <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                    </td>

                    <td class="view-message dont-show" (click)="ReadMessage(ret)">
                        {{ ret.fromName }}
                    </td>

                    <td class="view-message" (click)="ReadMessage(ret)">
                        {{ ret.subject }}
                    </td>
                    <td class="view-message" (click)="ReadMessage(ret)">
                        {{ ret.date }}
                    </td>
                    <td (click)="restore(ret)">
                        <img src="assets/images/restore.png" data-toggle="tooltip" title="Restore" alt="" width="25px" />
                    </td>
                    <td (click)="singleDelete(ret)">
                        <img src="assets/images/permanentdel.png"  data-toggle="tooltip" title="permanently delete" alt="" width="25px" />
                    </td>
                </tr>
            </tbody>
        </table>
        <br /><br />
        <div class="padginationmedia">
        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
    </div>
        <br /><br />
        <br /><br />
    </div>

    <div class="modal modal-angular fade" id="trashEmpty" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Trash Empty</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
    
                <div class="modal-body text-center">
                    <h3 style="text-align: center">Are you sure to empty trash....??</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning" data-dismiss="modal" 
                        mdbWavesEffect>Close</button>
                    <button  data-dismiss="modal" type="submit" style="background-color: red" class="btn btn-danger" (click)="trashempty()"
                        mdbWavesEffect>Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>