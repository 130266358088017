import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailboxVM } from 'src/app/ViewModels/Email';
import { SourceMail } from 'src/app/ViewModels/SourceMail';

@Injectable({
  providedIn: 'root'
})
export class GmailmigrationService {
  readonly url="https://blocdrive.com/blocdriveapi/api/";
  constructor(private http:HttpClient) { }

  postgmailmigrate(id){
    return this.http.post("https://blocdrive.com/blocdrivegmailapi/api/" + "GmailMigration/Postgmailmigrate" , id);
  }

  getGmaillist(cid) {
    return this.http.get(this.url + "GmailMigration/Getallmailsfromgmail/" + cid);
  }

  getGmaillistSourseMail(datasource:SourceMail) {
    return this.http.post(this.url + "GmailMigration/Getallmailsfromgmailsourcemail" , datasource);
    
  }


  multideletemessages(det: MailboxVM) {
    return this.http.post(this.url+ "GmailMigration/MultiDeleteMessages", det);
  }

  getgmaildetails(cid) {
    return this.http.get(this.url + "GmailMigration/GetReadgmail/" + cid);
  }

  downloadgmailfile(id){
    return this.http.get(this.url + "GmailMigration/PostDownloadgmailfile/" + id);
  }
}