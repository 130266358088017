import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InternationalForm } from 'src/app/ViewModels/InternationalForm';
import { IndividualForm } from 'src/app/ViewModels/IndividualForm';
import { CorparateForm } from 'src/app/ViewModels/CorparateForm';
import { GovernmentForm } from 'src/app/ViewModels/GovernmentForm';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowService {
  readonly url = "https://blocdrive.com/blocdriveapi/api/";

  constructor(private http:HttpClient) { }
  
 

  getGenders(){
    return this.http.get(this.url +"WorkFlowMasterData/GetGenderMasters");
  }
  getAllMaritalStatus(){
    return this.http.get(this.url +"WorkFlowMasterData/Getmaritalstatus");
  }
  getAllRelatedPerson(){
    return this.http.get(this.url +"WorkFlowMasterData/Getrelatedpersontype");
  }
  getAllCitizenship(){
    return this.http.get(this.url +"WorkFlowMasterData/GetAllCitizenship");
  }
  getAllOccupation(){
    return this.http.get(this.url +"WorkFlowMasterData/GetAlloccupation");
  }
  getAllResidentialStatus(){
    return this.http.get(this.url +"WorkFlowMasterData/GetResidentialstats");
  }
  getCorporateSubcategories(){
    return this.http.get(this.url +"WorkFlowMasterData/GetCorporateSubcategories");
  }
  getSubcategories(){
    return this.http.get(this.url +"WorkFlowMasterData/GetSubcategories");
  }
  SaveInternationalCustomer(data:InternationalForm){
    return this.http.post(this.url +"CustomerRegistration/PostInternationalCustomer",data);
  }
  SaveIndividualCustomer(data:IndividualForm){
    return this.http.post(this.url +"CustomerRegistration/PostIndividualCustomer",data);
  }
  SaveCorporateCustomer(data:CorparateForm){
    return this.http.post(this.url +"CustomerRegistration/PostCustomerCorporateDetails",data);
  }
  SaveGovernmentCustomer(data:GovernmentForm){
    return this.http.post(this.url +"CustomerRegistration/PostCustomerGovernmentDetails",data);
  }
  getInbox(id){
    return this.http.get(this.url + 'MailBox/GetInbox/' + id);
  }
  getDeletedItem(id){
    return this.http.get(this.url + 'MailBox/GetDeletedItems/' + id);
  }
  getSentItem(id){
    return this.http.get(this.url + 'MailBox/GetSendItems/' + id);
  }
  GetOutWardData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostOutwardbyMailId",data);
  }
  GetWorkAllocateData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostAllocateWorkData",data);
  }
  GetGenaralTransactionData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostGenaralTransactionByMailId",data);
  }
  GetInwardData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostInwardByMailId",data);
  }
  GetFileNumberGenerationData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostFileData",data);
  }
  GetFolderCreationData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostFolderData",data);
  }
  GetLeaveData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostLeaveData",data);
  }
  GetTaskData(data){
    return this.http.post(this.url +"WorkflowDataProvider/PostTaskData",data);
  }
  GetExhibit(data){
    return this.http.post(this.url +"Examinations/PostExhibitsDetails",data);
  }
  getWorkflowStagesforfolder(id){
    return this.http.get(this.url + 'WorkflowDataProvider/GetWorkflowStageMaster/' + id);
  }
  getWorkflowStagesforfile(id){
    return this.http.get(this.url + 'WorkflowDataProvider/GetWorkflowStageMaster/' + id);
  }
  getWorkflowStagesforot(id){
    return this.http.get(this.url + 'Custom/GetWorkflowStageMaster/' + id);
  }
  GetFriendRequests(id){
    return this.http.get(this.url + 'FriendLists/GetFriendRequestList/' + id);

  }
  GetRecievedFriendRequest(id){
    return this.http.get(this.url + 'FriendLists/Getrecievedfriendrequrest/' + id);

  }
  GetSentFriendRequest(id){
    return this.http.get(this.url + 'FriendLists/Getsentfriendrequrest/' + id);

  }
  AcceptFriendequest(data){
    return this.http.post(this.url +"FriendLists/AcceptRequest",data);

  }
  GetRecievedFileRequest(id){
    return this.http.get(this.url + 'Recipient/GetIncomingRequests/' + id);

  }
}
