import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { accountactivate, ClientDetails } from 'src/app/ViewModels/clientcreation';
import { Signup } from 'src/app/ViewModels/Signup';

@Injectable({
  providedIn: 'root'
})
export class ClientapprovalService {
  readonly url="https://blocdrive.com/blocdriveapi";
  constructor(private http: HttpClient) { }

  getAllClients(id){
    return this.http.get(this.url +"/api/ClientRegistration/GetAllClients/" + id);
  }
  savenewconsultant(data:ClientDetails){
    return this.http.post(this.url +"/api/ClientRegistration/PostSaveConsaltant",data);
  }
  getclientdetailsdashboard(id:any) {
    return this.http.get(this.url + "/api/AuditorTransaction/GetClientTransaction/"+ id);
  }
  getclientdetails(){
    return this.http.get(this.url + "/api/ClientRegistration/GetAllClientsDetails/");
  }
  saveDetailsClient(data: Signup) {
    return this.http.post(this.url + "/api/ClientRegistration/RegisterClient", data);
  }
  getclientlogindetails(id:any){
    return this.http.get(this.url + "/api/ClientRegistration/GetAllClientSessionDetails/"+ id);
  }

  getchechusers(id){
    return this.http.get(this.url +"/api/ClientRegistration/GetcheckClients/" + id);
  }

  deactiveateuser(data:accountactivate){
    return this.http.post(this.url +"/api/ClientRegistration/PostaccountactiveMails/",data);
  }

  savenewClient(data:ClientDetails){
    return this.http.post(this.url +"/api/ClientRegistration/PostSaveClient",data);
  }
  getclientdata(id:any) {
    return this.http.get(this.url + "/api/UploadDocument/GetClientFiledata/"+ id);
  }

}
