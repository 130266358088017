<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<form method="post" id="form1" name="{{CardDetails.formName}}" ngNoForm action="https://secure.payu.in/_payment">
    <div class="container" style="background-color:rgb(101, 95, 160);padding:50px">
        <input type="hidden" name="{{CardDetails.valpair[0].name}}" value="{{CardDetails.valpair[0].value}}">
        <input type="hidden" name="{{CardDetails.valpair[1].name}}" value="{{CardDetails.valpair[1].value}}">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h3>Amount</h3>
                <input type="" name="{{CardDetails.valpair[2].name}}" value="{{CardDetails.valpair[2].value}}" readonly>
            </div>
            <div class="col-lg-6">

                <h3>Business</h3>
                <input type="" name="{{CardDetails.valpair[3].name}}" value="{{CardDetails.valpair[3].value}}" readonly>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h3>Name</h3>
                <input type="" name="{{CardDetails.valpair[4].name}}" value="{{CardDetails.valpair[4].value}}" readonly>
            </div>
            <div class="col-lg-6">
                <h3>Mobile Number</h3>
                <input type="" name="{{CardDetails.valpair[5].name}}" value="{{CardDetails.valpair[5].value}}" >
            </div>
        </div>

        <div class="col-lg-12">
            <div class="col-lg-6">
                <h3>Email</h3>
                <input type="" name="{{CardDetails.valpair[6].name}}" value="{{CardDetails.valpair[6].value}}" readonly>
            </div>
            <div class="col-lg-6">
                <button id="send" class="btn-info img-rounded" style="margin-top:80px" (click)="StartTimer()" type="submit" formtarget="_blank">Please Confirm </button>
            </div>
        </div>

        <input type="hidden" name="{{CardDetails.valpair[7].name}}" value="{{CardDetails.valpair[7].value}}">
        <input type="hidden" name="{{CardDetails.valpair[8].name}}" value="{{CardDetails.valpair[8].value}}">
        <input type="hidden" name="{{CardDetails.valpair[9].name}}" value="{{CardDetails.valpair[9].value}}">
        <input type="hidden" name="{{CardDetails.valpair[10].name}}" value="{{CardDetails.valpair[10].value}}">

        <input type="hidden" name="OpenPayu-Signature" value="sender=145227;algorithm=SHA-256;signature={{CardDetails.valpair[10].value}}">
        <hr />

        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>

    </div>


</form>

<b style="text-align:center">{{paymentMessage}}</b>
<div class="col-md-12" *ngIf="continue">
    <div class="col-md-6"></div>
    <div class="col-md-6">
        <button class="btn btn-primary" style="margin-top:20px" (click)="gotopost()">Continue </button>
    </div>
</div>