import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ProjectVM } from 'src/app/ViewModels/ProjectVM';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'src/app/Shared/Project/project.service';
import { ClientService } from 'src/app/Shared/Client/client.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { Priority } from 'src/app/ViewModels/Priority';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-project-registration',
  templateUrl: './project-registration.component.html',
  styleUrls: ['./project-registration.component.css']
})
export class ProjectRegistrationComponent implements OnInit {
  DateTime: Date;
  selecteduser: any;
  selectedclient: any;
  selectedpriority: any;
  dismissalert: boolean;
  notify: string;
  ClientList: any=[];
  estimatedcost: any
  projectname: any
  projectdesc: any
  purpose: any
  projectcode: any
  startdate: any
  objectives: any
  estimatedtime: any
  UserList: any=[];
  prioritylist: any = [];
  files: any=[];
  isLoggedIn: boolean;
  uid: string;
  username: string;
  ProjectList: any;
  DeleteName: any;
  deleteid: any;
  editprojectname: any;
  editprojectdesc: any;
  editpurpose: any;
  editstartdate: any;
  editobjectives: any;
  editestimatedtime: any;
  editestimatedcost: any;
  createdon: any;
  editpriority: any;
  edituserid: any;
  editclentid: any;
  editprojectcode: any;
  editid: any;
  converttostring: any;
  converteddate: any;
  closeResult: string;
  p: number = 1;
  showhidevalue: boolean=false;
  problockid: any;
  uniqeprojid: any;
  Updatefiles: any=[];
  ViewDoc: boolean;
  ProjectFileList: any;
  downpath: any;
  roleid: string;
  mobileview: boolean;
  GUID: string;
  constructor(private datepipe: DatePipe,private auditortransactionservice:AuditorTransactionService, private spinner: NgxSpinnerService, private projectservice: ProjectService,
    private clientService: ClientService, private userservice: UserDetailsService, private httpService: HttpClient,
    private modalService: NgbModal, private datePipe: DatePipe,private router:Router) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();

  }

  ngOnInit(): void {
    this.Clients();
    this.Users();
    this.Projects();
    var prioritymajor: Priority = {
      PriorityId: 1,
      PriorityName: "Major"
    }
    this.prioritylist.push(prioritymajor);
    var prioritycritical: Priority = {
      PriorityId: 2,
      PriorityName: "Critical"
    }
    this.prioritylist.push(prioritycritical);
    var priorityminor: Priority = {
      PriorityId: 3,
      PriorityName: "Minor"
    }
    this.prioritylist.push(priorityminor);
  }
  onSelectUser(id) {
    this.selecteduser = id;
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  onSelectClient(id) {
    this.selectedclient = id;
  }
  onSelectPriority(id) {
    this.selectedpriority = id;
  }
  onChangeUploadDoc(event: any) {
    this.files = [].slice.call(event.target.files);
  }
  onChangeUploadDocForUpdate(event: any) {
    this.Updatefiles = [].slice.call(event.target.files);
  }
  SaveProject(form: NgForm) {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
    const inputRequest: ProjectVM = {
      ID: 0,
      ProjectName: form.controls["projectname"].value,
      ProjectDescription: form.controls["projectdesc"].value,
      PurposeOfProject: form.controls["purpose"].value,
      Priority: this.selectedpriority,
      ProjectCode: form.controls["projectcode"].value,
      ProjectLead: this.selecteduser,
      StartDate: form.controls["startdate"].value,
      Objectives: form.controls["objectives"].value,
      EstimstedTime: form.controls["estimatedtime"].value,
      EstimatedCost: form.controls["estimatedcost"].value,
      Client: this.selectedclient,
      CreatedOn: latest_date,
      GUID:this.GUID
    }
    const frmData = new FormData();
    if(this.files.length==0){
      alert("Please Select atleast One File!!")
    }
    else{
    for (var i = 0; i < this.files.length; i++) {
      frmData.append("fileUpload", this.files[i]);
    }
    frmData.append("ProjectName", inputRequest.ProjectName);
    frmData.append("ProjectDescription", inputRequest.ProjectDescription);
    frmData.append("PurposeOfProject", inputRequest.PurposeOfProject);
    frmData.append("Priority", inputRequest.Priority);
    frmData.append("ProjectCode", inputRequest.ProjectCode);
    frmData.append("ProjectLead", inputRequest.ProjectLead);
    frmData.append("StartDate", inputRequest.StartDate);
    frmData.append("Objectives", inputRequest.Objectives);
    frmData.append("EstimstedTime", inputRequest.EstimstedTime);
    frmData.append("EstimatedCost", inputRequest.EstimatedCost);
    frmData.append("Client", inputRequest.Client);
    frmData.append("CreatedOn", inputRequest.CreatedOn);
    frmData.append("UserId", this.uid);
    frmData.append("GUID", inputRequest.GUID);

    this.spinner.show();
    this.httpService.post('https://blocdrive.com/blocdriveapi/api/ProjectRegistration/PostSaveProject/', frmData).subscribe(
      data => {
        if (data == "success") {
          this.notify = "Project added successfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Create",
            TransactionDesc:"Project",
  
            }
            this.auditortransactionservice.AuditorDetailsProject(audit);
          this.selectedclient = null
          this.selecteduser = null
          this.files=[];
          this.projectname="";
          this.projectdesc="";
          this.purpose="";
          this.projectcode="";
          this.selectedpriority=null;
          this.startdate=null;
          this.objectives="";
          this.estimatedtime="";
          this.estimatedcost="";
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
        } else {
          this.notify = "Something went wrong. Try again.!!"
          this.selectedclient = null
          this.selecteduser = null
          this.files=[]
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
        }
      });
    }
  }
  closealert() {
    this.dismissalert = false;
  }
  Clients() {
    // this.spinner.show();
    this.clientService.getAllClients(this.GUID).subscribe((Data: any) => {
      this.ClientList = Data;
      this.spinner.hide();
    });
  }
  Track(data){
    localStorage.setItem("TrackId",data.blockId)
    this.router.navigate(['tracking/'])
  }
  Users() {
    this.spinner.show();
    this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  Projects() {
    this.spinner.show();
    this.projectservice.getParticularAllProjects(this.GUID).subscribe((Data: any) => {
      this.ProjectList = Data;
      this.spinner.hide();

    })
  }
  EditProject(updatedata, updateproject) {
    this.editid = updatedata.id
    this.editprojectname = updatedata.projectName
    this.editprojectdesc = updatedata.projectDescription
    this.editpurpose = updatedata.purposeOfProject
    this.editpriority = updatedata.priorityId
    this.edituserid = updatedata.projectLead
    this.editprojectcode = updatedata.projectCode
    this.editstartdate = updatedata.startDate
    this.editobjectives = updatedata.objectives
    this.editestimatedtime = updatedata.estimstedTime
    this.converttostring = this.datePipe.transform(this.editstartdate, 'yyyy-MM-dd');
    this.converteddate = this.converttostring.toString();
    this.editestimatedcost = updatedata.estimatedCost
    this.editclentid = updatedata.client
    this.createdon = updatedata.createdOn;
    this.problockid = updatedata.blockId,
    this.uniqeprojid = updatedata.uniqueProjectId
    this.modalService.open(updateproject).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  deleteProject(deletedata, deleteproject) {
    this.DeleteName = deletedata.projectName;
    this.deleteid = deletedata.uniqueProjectId;
    this.modalService.open(deleteproject).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  UpdateProject() {
    if (this.selectedclient == null || this.selectedclient == undefined) {
      this.selectedclient = this.editclentid;
    }
    if (this.selecteduser == null || this.selecteduser == undefined) {
      this.selecteduser = this.edituserid;
    }
    const inputRequest: ProjectVM = {
      ID: this.editid,
      ProjectName: this.editprojectname,
      ProjectDescription: this.editprojectdesc,
      PurposeOfProject: this.editpurpose,
      Priority: this.editpriority,
      ProjectCode: this.editprojectcode,
      ProjectLead: this.selecteduser,
      StartDate: this.editstartdate,
      Objectives: this.editobjectives,
      EstimstedTime: this.editestimatedtime,
      EstimatedCost: this.editestimatedcost,
      Client: this.selectedclient,
      CreatedOn: this.createdon,
      GUID:this.GUID
    }
    this.spinner.show();
    const frmData = new FormData();
  
    for (var i = 0; i < this.Updatefiles.length; i++) {
      frmData.append("fileUpload", this.Updatefiles[i]);
    }
    frmData.append("ProjectName", inputRequest.ProjectName);
    frmData.append("ProjectDescription", inputRequest.ProjectDescription);
    frmData.append("PurposeOfProject", inputRequest.PurposeOfProject);
    frmData.append("Priority", inputRequest.Priority);
    frmData.append("ProjectCode", inputRequest.ProjectCode);
    frmData.append("ProjectLead", inputRequest.ProjectLead);
    frmData.append("StartDate", inputRequest.StartDate);
    frmData.append("Objectives", inputRequest.Objectives);
    frmData.append("EstimstedTime", inputRequest.EstimstedTime);
    frmData.append("EstimatedCost", inputRequest.EstimatedCost);
    frmData.append("Client", inputRequest.Client);
    frmData.append("CreatedOn", inputRequest.CreatedOn);
    frmData.append("UserId", this.uid);
    frmData.append("UniqueProjectId",this.uniqeprojid);
    frmData.append("GUID", inputRequest.GUID);

    this.spinner.show();
    this.httpService.post('https://blocdrive.com/blocdriveapi/api/AddProject/PostUpdateProject/', frmData).subscribe(
      data => {
        if (data == "success") {
          this.notify = "Project updated successfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Update",
            TransactionDesc:"Project",
  
            }
            this.auditortransactionservice.AuditorDetailsProject(audit);
          this.selectedclient = null
          this.selecteduser = null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();

        } else {
          this.notify = "Something went wrong. Try again.!!"
          this.selectedclient = null
          this.selecteduser = null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
          this.Projects();

        }
      });
  }
  DeleteProjectRow() {
    this.spinner.show();
    var deleteuserid = this.deleteid + "," + this.uid;
    this.projectservice.deleteProject(deleteuserid).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Project deleted successfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Delete",
            TransactionDesc:"Project",
  
            }
            this.auditortransactionservice.AuditorDetailsProject(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
          this.Projects();

        } else {
          this.notify = "Something went wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
          this.Projects();

        }
      });
  }
  GetUser(userid) {
    for (let i = 0; i < this.UserList.length; i++) {
      if (userid == this.UserList[i].guid) {
        return this.UserList[i].userName;
      }
    }
  }
  GetClient(cid) {
    for (let i = 0; i < this.ClientList.length; i++) {
      if (cid == this.ClientList[i].id) {
        return this.ClientList[i].clientName;
      }
    }
  }
  viewallprojects(){
    this.showhidevalue=true
  }
  ViewProjectFiles(id){
    this.spinner.show();
    var piduserid =id.uniqueProjectId + "," + this.GUID;
    this.projectservice.getProjectFiles(piduserid).subscribe((Data: any) => {
      this.ProjectFileList = Data;
      this.spinner.hide();
    })
    this.ViewDoc=true;
  }
  gotopdfViewForAssignTask(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.file);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
   localStorage.setItem('IsAssignTaskDoc', "true");
    this.router.navigate(['projectdocs/'])
  }
  DownloadFile(ret) {
    const inputRequest: DownloadFileVM = {
      Id: null,
      AUID: this.uid,
      FolderId: null,
      FolderName:null,
      BlockID: ret.blockId,
      GUID:this.GUID
    }
    this.spinner.show();
    this.projectservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
        this.spinner.hide();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
    });
  
  }
}