import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssignRightService {
  readonly _urls="https://blocdrive.com/blocdriveapi/api/";

  constructor(private http:HttpClient ) { }
  saveUserRight(userRight) {
    return this.http.post(this._urls + "AssignRightMaster/PostSaveRights", userRight);
  }
  getUserRightdata(id) {
    return this.http.get(this._urls + "AssignRightMaster/GetAllAssignRight/" + id);
  }
  getUserRights(id) {
    return this.http.get(this._urls + "AssignRightMaster/GetAllRights/" + id);
  }

  UpdateUserRightRow(id){
    return this.http.post(this._urls + "AssignRightMaster/PostUpdateRights" , id);
  }

  deleteRightdata(id:number){
    return this.http.get(this._urls +"AssignRightMaster/GetDeleteRightsData/"+ id);
  }
}
