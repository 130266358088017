import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SendMail } from 'src/app/ViewModels/SendMail';

@Injectable({
  providedIn: 'root'
})
export class MessageService1 {
  readonly url="https://blocdrive.com/blocdriveapi/";

  constructor(private http: HttpClient,) { }

  getInboxlist(cid) {
    return this.http.get(this.url + "/api/Workflow1/GetInbox/" + cid);
  }

  getSentitemslist(cid) {
    return this.http.get(this.url + "/api/Workflow1/GetSentItemsList/" + cid);
  }

  getDeleteitemslist(cid) {
    return this.http.get(this.url + "/api/Workflow1/GetDeletedItemsList/" + cid);
  }

  // WorkflowDataProvider/GetDeletedItemsList

  getUsers(cid) {
    return this.http.get(this.url + "/api/FriendList/GetFriendList/" + cid);
  }


  SendMail(data: SendMail) {
    return this.http.post(this.url + "/api/Mail/PostMailForm", data);
  }

  getmailsentlist(mailid){
    return this.http.get(this.url +"/api/Workflow1/GetMailUserList/"+ mailid);
  }
  getreplaylist(data){
    return this.http.post(this.url +"/api/Workflow1/PostReplayList", data);
  }
  
  gettouserimages(mailid){
    return this.http.post(this.url +"/api/Workflow1/GetToUserImagesList" ,mailid);
  }

  getallmails(data) {
    return this.http.post(this.url + "/api/Workflow1/Postgetmailinthread", data);
  }
  getComposedMailDetails(mid) {
    return this.http.get(this.url + "/api/Workflow1/GetComposedMail/" + mid);
  }
  getallComposedMailDetails(mid) {
    return this.http.get(this.url + "/api/Workflow1/GetallComposedMail/" + mid);
  }
  getSentMailDetails(mid) {
    return this.http.get(this.url + "/api/Workflow1/GetSentMailDetails/" + mid);
  }

  deletemail(tid) {
    return this.http.delete(this.url + "/api/Workflow1/PostDeleteMail/" + tid);
  }

  getDocumentdetailsformessages(id){
    return this.http.get(this.url + "/api/Workflow1/GetDocumentDetailsforMessages/"+ id);
  }

  // gettouserslist(data: ReqData) {
  //   return this.http.post(this.url + "/api/Workflow1/GettousersList", data);
  // }
  // multireadmessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiReadMessages", det);
  // }

  // multiunreadmessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiUnReadMessages", det);
  // }

  // multiarchivemessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiArchiveMessages", det);
  // }

  // multideletemessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiDeleteMessages", det);
  // }

  // SendComposedMail(det: SendNewMail) {
  //   return this.http.post(this.url + "/api/GoogleExtension/PostMyDocs", det);
  // }
  // multiPermanentlydeletemessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiPermanentlyDeleteMessages", det);
  // }

  // multiRestoremessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiRestoreMessages", det);
  // }

  // getArchivedlist(cid) {
  //   return this.http.get(this.url + "/api/Workflow1/GetArchivedItemList/" + cid);
  // }

  // multiDeleteArchivedmessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiDeleteArchivedMessages", det);
  // }

  // multiUnArchivedmessages(det: MailboxVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/MultiUnArchivedMessages", det);
  // }
  
  // unreadmessage(det: ReadMsgVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/PostUnReadMessage", det);
  // }

  // archivemessage(det: ReadMsgVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/PostArchiveMessage", det);
  // }

  // deletemessage(det: ReadMsgVM) {
  //   return this.http.post(this.url + "/api/InboxFunctions/PostDeleteMessage", det);
//}
}
