import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDetails } from 'src/app/ViewModels/UserDetails';
import { UserId } from 'src/app/ViewModels/UserId';
import { IndividualChatDetails } from 'src/app/ViewModels/IndividualChatDetails';
import { Friend } from 'src/app/ViewModels/Friend';
import { Delete } from 'src/app/VIewmodels/Delete';
import { ShareFileViaMailVM } from 'src/app/ViewModels/ShareFileViaMailVM';
import { ImportFromMailVW } from 'src/app/ViewModels/ImportFromMailVM';
import { Search } from 'src/app/ViewModels/Search';
import { SavepaymentDetails } from 'src/app/ViewModels/SavePaymentDet';
import { addresslist } from 'src/app/ViewModels/addresslist';
import { PayU } from 'src/app/ViewModels/Payment';
import { Plan } from 'src/app/ViewModels/plan';
import { OtpViewModel } from 'src/app/ViewModels/OtpViewModel';
import { MobileViewModel } from 'src/app/ViewModels/MobileViewModel';
import { ChangePassword } from 'src/app/ViewModels/ChangePassword';
import { ContactForm } from 'src/app/ViewModels/ContactForm';
import { SupportVM } from 'src/app/ViewModels/SupportVM';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';
import { paymentVM } from 'src/app/ViewModels/paymentVM';
import { PayVM } from 'src/app/ViewModels/PayVM';
import { RestoredataVM } from 'src/app/ViewModels/RestoredataVM';
import { ShareMulti } from 'src/app/ViewModels/ShareMulti';
import { MoveMultiFolder } from 'src/app/ViewModels/MoveMultiFolder';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { Email } from 'src/app/ViewModels/Email';
import { Signup } from 'src/app/ViewModels/Signup';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { AppGlobals } from 'src/app/app.global';
import { AddFolderMigrateVM } from 'src/app/ViewModels/FolderMigrateVM';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  getCurrentUSerImage: any;

  readonly url="https://blocdrive.com/blocdriveapi/api/";
  readonly planurl = "http://localhost:44387/api/";

  constructor(private http:HttpClient,private _urls:AppGlobals) { }
  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded;', 'No-Auth': 'True' });
    return this.http.post(this.url + '/token', data, { headers: reqHeader });
  }
  saveUser(data:UserDetails){
    return this.http.post(this.url +"User/PostSaveUser",data);
  }
  getsubfolders(id) {
    return this.http.get(this.url + "UploadDocument/Getdocument/" + id);
  }
  getsubfoldersinsharing(id) {
    return this.http.get(this.url + "UploadDocument/Getdocumentinsharing/" + id);
  }
  getAllUsers(id){
    return this.http.get(this.url +"User/GetAllUsers/" + id);
  }
  getUserWebsite(id){
    return this.http.get(this.url +"User/GetUserCompanyWebsite/" + id);
  }
  getAllComapnyUsers(){
    return this.http.get(this.url +"User/GetAllCompanyUsers/");

  }

  postsigndata(data){
    return this.http.post(this.url + "User/PostEmailSign",data)
  }
  posteditsigndata(data){
    return this.http.post(this.url + "User/PostEditEmailSign",data)
  }
  getsigns(id){
    return this.http.get(this.url +"User/GetEmailSign/" + id);
  }
  // getRights(id){
  //   return this.http.get(this.url +"User/GetRightTypes/" + id);
  // }
  getRights(){
    return this.http.get(this.url +"User/GetRightTypelist/");

  }
  deactiveuser(id: number){
    return this.http.get(this.url +"User/GetDeactiveuser/"+ id);
  }
  getuserRights(id){
    return this.http.get(this.url +"User/GetUserRight/" + id);

  }
  getbackdata(pid) {
    return this.http.get(this.url + "UploadDocument/GetBackdocument/" + pid);
  }
  getfolders(id) {
    return this.http.get(this.url + "FolderMasters/GetallFolder/" + id);
  }
  UpdateFolder(data: AddNewFolder) {
    return this.http.post(this.url + "FolderMasters/PostUpdateContent", data);
  }
  addNewFolder(data: AddNewFolder) {
    return this.http.post(this.url + "FolderMasters/PostFolderMaster", data);
  }
  deleteUser(id: number){
    return this.http.get(this.url +"User/GetDeleteUserData/"+ id);
  }
  deletefolder(aa) {
    return this.http.post(this.url + "FolderMasters/PostDeleteContent", aa);
  }
  UpdateUser(data:UserDetails){
    return this.http.post(this.url +"User/PutUser",data);
  }
  friendlist(id) {
  return this.http.get(this.url + "FriendLists/GetFriendList/" + id);
}
GetFriendRequests(id){
  return this.http.get(this.url + 'FriendLists/GetFriendRequestList/' + id);
}
SaveFrienddetails(data: Friend) {
  return this.http.post(this.url + "FriendLists/PostFriendList", data);
}

DeleteFiles(data: Delete) {
  return this.http.post(this.url + "FolderMasters/PostDeleteFiles", data);
}

sharefileviamail(data: ShareFileViaMailVM) {
  return this.http.post(this.url + "ShareFilesViaMail/ShareFilesThroughMail", data);

}
importfilefrommail(data: ImportFromMailVW) {
  return this.http.post(this.url + "ShareFilesViaMail/ImportFilesFromMail", data);

}

// GetAllTransactions(id) {
//   return this.http.get(this.url + "Contract/GetTransactions/" + id);
// }

SearchFiles(data: Search) {
  return this.http.post(this.url + "Search/PostSearch", data);
}
AcceptReq(f) {
  return this.http.post(this.url + "FriendLists/AcceptRequest", f);
}

getpath(id) {
  return this.http.get(this.url + "Path/getpath/" + id);
}
updateretailstores(id) {
  return this.http.get(this.url + "ReturnedOrders/Updateretailstores/" + id);
}
getPayuPlans() {
  return this.http.get(this.url + "Payment/GetPlanList");
}

SaveOrderDetails(aaa: SavepaymentDetails) {
  return this.http.post(this.url + "Payment/GetOrderPayments", aaa);
}

getorderpayment(mm: addresslist) {
  return this.http.post(this.url + "Payment/GetOrderDetailsforPayment", mm);
}

getpaymentstatus(aa: PayU) {
  return this.http.post(this.url + "Payment/GetPaymentStatus", aa);
}

UpdatePlan(id) {
  return this.http.post(this.planurl + "PlansTypeMasters/PutPlansTypeMaster/", id);
}
DeletePlan(id) {
  return this.http.delete(this.planurl + "PlansTypeMasters/DeletePlansTypeMaster/" + id);
}
SavePlan(data: Plan) {
  return this.http.post(this.planurl + "PlansTypeMasters/PostPlansTypeMaster", data);
}
PlantList() {
  return this.http.get(this.planurl + "PlansTypeMasters/GetPlansTypeMasters");
}
getAllCustomers() {
  return this.http.get(this.url + "AuManagement/GetA_U_Management");
}
getAllCustomersApplication() {
  return this.http.get(this.url + "AuManagement/GetA_U_Management");
}
// GetCustomerTransaction() {
//   return this.http.get(this.url + "Contract/GetAllTransactions");
// }
getProfileDetails(data) {
  return this.http.get(this.url + "AuManagement/GetEnterpriseUserDetail?id=" + data);
}
// autocall() {
//   return this.http.get(this.url + 'ShareFilesViaMail/GetCheckChainAsync/');
// }
// UploadEmailFiles(mail: EmailMessageVM) {
//   return this.http.post(this.url + "ShareFilesViaMail/TestUpload", mail);

// }

postVerifyOTP(data:OtpViewModel){
  return this.http.post(this.url + "Account/OTPValidation", data);
}

verifyEmail(num:MobileViewModel){
  return this.http.post(this.url + "Signup/MobileValidation", num);
}

postchangePassword(pwd:ChangePassword){
  return this.http.put(this.url + "Signup/UpdateForgotPassword", pwd);
}

VerifyOTP(pwd:MobileViewModel){
  return this.http.post(this.url + "Signup/ForgotPswdOTPValidation", pwd);
}

postchangePasswordAfterLogin(pwd:ChangePassword){
  return this.http.post(this.url + "AuManagement/PostUserInfoforResetpw", pwd);
}

getfoldersbydate(uid){
  return this.http.get(this.url + "FolderMasters/GetallFoldersByDate/" + uid);
}

getfoldersbyname(uid){
  return this.http.get(this.url + "FolderMasters/GetallFolderByName/" + uid);
}

getsubfoldersbydate(id) {
  return this.http.get(this.url + "UploadDocument/GetdocumentbyDate/" + id);
}

getsubfoldersbyname(id) {
  return this.http.get(this.url + "UploadDocument/GetdocumentbyName/" + id);
}

getCategories()
{
  return this.http.get(this.url + "AuRoleMaster/GetCategories");
}

savecontactform(user: ContactForm) {
  return this.http.post(this.url + "AuRoleMaster/PostContactForm", user);
}
SaveSupportRequest(data: SupportVM) {
  return this.http.post(this.url + "Account/PostCustomerRequest", data);
}
downloadFolder(data:DownloadFolderVM){
  return this.http.post(this.url + "DownloadFolderorFile/PostDownloadFolder", data);
}
postBuyPlan(data: paymentVM) {
  return this.http.post(this.url + "StripePayment/PostBuyPlan", data);
}

getMonthList(){
  return this.http.get(this.url + 'AuRoleMaster/GetMonthList' );
}

getYearList(){
  return this.http.get(this.url + 'AuRoleMaster/GetYearList' );
}
GetPlanAmount(data: PayVM) {
  return this.http.post(this.url + "StripePayment/GetplanAmount", data);
}

getallpaymentlist(id){
  return this.http.get(this.url + 'AuRoleMaster/GetPaymentList/' + id);
}

getBillIngormation(id){
  return this.http.get(this.url + 'StripePayment/GetBillInformation/' + id);
}

Getalldeletetrns(id){
  return this.http.get(this.url + 'FolderMasters/GetAllDeletedData/' + id);
}
PostRestoredata(data: RestoredataVM){
  return this.http.post(this.url + "FolderMasters/PostRestoredata", data);
}

//due diligence


adddiligence(id){
  return this.http.post(this.url+"Account/addnewDiligence",id);
  }


//Chat
GetToUser(UserId) {
return this.http.get(this.url + "UserChat1/GetTouserDetails/"+ UserId);
}

saveuserChat(ChatDetail) {
return this.http.post(this.url + "ChatContract/PostUserChat", ChatDetail);
}

GetAllUsersExceptMe(data: UserId) {
return this.http.post(this.url + "UserChat1/GetAllUserExceptMe", data);
}
GetAllFriendsExceptMe(data) {
  return this.http.get(this.url + "UserChat1/GetAllUserExceptMe/" + data);
  }
// getCurrentUSerImage(id) {
//   return this.http.get(this.url + "UploadImage/" + id);
// }

GetChatforParticularUser(id) {
return this.http.get(this.url + "ChatContract/GetChatmsg/" + id);
}

GetIndividualChatRecivedDetails(individual: IndividualChatDetails) {
return this.http.post(this.url + "ChatContract/RecivedIndividualChatDetails/", individual);
}

SharemultiFiles(data: ShareMulti) {
return this.http.post(this.url + "Mulit/MultiShareFiles", data);
}
MoveMultiFileFolders(data: MoveMultiFolder) {
return this.http.post(this.url + "Mulit/PostMoveMultiFolderOrDocument", data);
}
addExsistingMainFolderAndShare(data:FolderShareToUers){
return this.http.post(this.url + "ShareFileFolder/PostExsistingMainFolderShareTOUsers", data);
}
getAllSharingfolders(id) {
return this.http.get(this.url + "ShareFileFolder/GetallSharingFolder/" + id);
}
withdraw(id){
return this.http.post(this.url+"ShareAllWithdraw/withdraw",id);
}
addMultigMainFolderAndShare(data: MultiFolderShareVM) {
return this.http.post(this.url + "ShareFileFolder/PostMultyMainFolderShareTOUsers", data);
}
shareExsistingSubFolder(data:FolderShareToUers){
return this.http.post(this.url + "ShareFileFolder/PostExsistingSubFolderShareTOUsers", data);
}

DeleteMore(data: DeleteMulit) {
return this.http.post(this.url + "Mulit/PostDeleteMore", data);
}
shareall(data: any){
return this.http.post(this.url+'ShareFileFolder/shareall',data);
}

shareOnlyFile(data:ShareOnlyFileVM){
return this.http.post(this.url + "ShareFileFolder/PostSharingFileOnly", data);
}
shareOnlyFileinShare(data:ShareOnlyFileVM){
  return this.http.post(this.url + "ShareFileFolder/PostSharingFileOnlyinShare", data);
  }
LoginVerification(data:OtpViewModel){
return this.http.post(this.url + "LoginTest/LoginVerification", data);
}

refer(data:any){
return this.http.post(this.url+"refer/postrefer",data);
}

redeemdata(data:any){

return this.http.get(this.url+"refer/getStorage/"+data);
}
redeemcash(data:any){
return this.http.post(this.url+"refer/getAmount",data);
}

getblocks(data:any){
return this.http.get(this.url+"refer/getblocks/"+data);
}

getAllUsersForRefer(){
return this.http.get(this.url+"refer/getAllUsers");
}
getrefers(){
return this.http.get(this.url+"refer/getrefers");

}
updateProfile(dat:any){
return this.http.post(this.url+"Account/uploadProfilePic",dat)
}
getFileCount(id){
return this.http.get(this.url + "UploadDocument/GetFilesUploaded/"+id);
}

sendotp(id){
const idd:Email={
  Email:id
}
return this.http.post(this.url+"Account/forgotpassword/",idd);
}

otpvalid(dat){
return this.http.post(this.url+"Account/resetPasswordvalid",dat);
}

addclient(id){
return this.http.post(this.url+"Account/addnewClient",id);
}


acceptfriendrequest(id){
return this.http.get(this.url+"Account/AcceptFriend/"+id);
}

chacbackup(id){
return this.http.get(this.url+"ChatContract/chatbackupfiles/"+id);
}

checkInstall(id){
return this.http.get(this.url+'Account/checkInstall/'+id);
}

getfolderssearch(id) {
return this.http.post(this.url + "FolderMasters/Searchdata" ,id);
}
saveDetails(data: Signup) {
  return this.http.post(this.url + "account/register", data);
}
getroles(){
return this.http.get(this.url + "Account/getroles");
}
DownloadMultipleFolders(data) {
  return this.http.post(this.url + "DownloadFolderorFile/PostDownloadMultiFolder", data);
}
downloadFile(data) {
  return this.http.post(this.url + "DownloadFolderorFile/PostDownloadSingleFile", data);
}
DeleteMultiSharedFiles(data) {
  return this.http.post(this.url + "Mulit/PostDeleteMoreinSharing", data);
}

SaveinMPF(data) {
  return this.http.post(this.url + "UploadFile/PostSavefilesinMPF", data);
}
postaddnewebers(id:any) {
  return this.http.post(this._urls.dmsurl + "/api/UploadFile/PostAddNewMembers" , id);
}
getpfoldernames(id) {
  return this.http.get(this._urls.dmsurl + "/api/FolderCreation/GetFoldernames/" + id);
}
gelallver(id) {
  return this.http.post(this.url + "Version/Allversionfiles",  id);
}
Getmigrate(individual: AddFolderMigrateVM) {
  return this.http.post(this.url + "IndividualMigration/ReceiveMigrateFolder" , individual);
  }
  saveUserClient(data:UserDetails){
    return this.http.post(this.url +"User/PostSaveUserClient",data);
  }

  deletechat(id) {
    return this.http.get(this._urls.dmsurl + "/api/ChatContract/DeleteChat/" + id);
  }


}
