<header class="masthead1 xyz" id="footer">
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="footer__widget">
                        <a href="#" class="brand">
                            <img src="../assets/img/blocdrive1footer.svg" loading="lazy" class="logo" alt="Blocdrive" />
                        </a>
                        <p>Blocdrive Private Limited</p>
                    </div>
                    <ul class="footer__widget-network">
                        <li><a href="https://twitter.com/Blocdrive1" aria-label="Twitter" class="footer__widget-network-link"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/blocdrive-blockchain-a8710b1b8/" aria-label="LinkedIn" class="footer__widget-network-link"><i class="fa fa-linkedin"></i></a></li>
                        <li><a href="https://www.facebook.com/srichid.technology/" aria-label="Facebook" class="footer__widget-network-link"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/bloc_drive/" aria-label="Instagram" class="footer__widget-network-link"><i class="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer__widget">
                        <ul class="footer__widget-linklist">
                            <li><a href="tel:+91-80-26850251">+91-80-26850251</a></li>
                            <li><a href="mailto:support@blocdrive.com">support&#64;blocdrive.com</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer__widget">
                        <ul class="footer__widget-linklist">
                            <li><a href="/aboutus">About Us</a></li>
                            <li><a href="/privacy">Privacy</a></li>
                            <li><a href="/termsofservice">Terms of Service</a></li>
                            <li><a href="/howto">Support</a></li>
                            <li><a href="/faq">FAQ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="footer__subfooter">
                        <div class="row">
                            <div class="col-md-6 text-left">
                                <ul class="footer__subfooter-liststyle">
                                    <li>Blocdrive Private Limited © 2024</li>
                                </ul>
                            </div>
                            <div class="col-md-6 text-right">
                                <ul class="footer__subfooter-liststyle">
                                    <li>All Rights Reserved</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</header>