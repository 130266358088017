import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AddprojectComponent } from './addproject/addproject.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CountryComponent } from './Masters/country/country.component';
import { RoleComponent } from './Masters/role/role.component';
import { DesignationComponent } from './Masters/designation/designation.component';
import { DepartmentComponent } from './Masters/department/department.component';
import { MenuMasterComponent } from './Masters/menu-master/menu-master.component';
import { ERegistrationComponent } from './eregistration/eregistration.component';
import { ClientComponent } from './Masters/client/client.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { UserComponent } from './Masters/user/user.component';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './project/project.component';
import { HomeComponent } from './home/home.component';
import { ProjectChangeComponent } from './Masters/project-change/project-change.component';
import { ProjectModuleComponent } from './Masters/project-module/project-module.component';
import { AssignTaskComponent } from './assign-task/assign-task.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { TaskViewComponent } from './task-view/task-view.component';
import { FileViewComponent } from './file-view/file-view.component';
import { ClientRegistrationComponent } from './Workflow/client-registration/client-registration.component';
import { ProjectRegistrationComponent } from './Workflow/project-registration/project-registration.component';
import { ProjectDocumentViewComponent } from './project-document-view/project-document-view.component';
import { InwardComponent } from './Workflow/inward/inward.component';
import { WorkFlowTrackingComponent } from './Workflow/work-flow-tracking/work-flow-tracking.component';
import { WorkAllotmentComponent } from './Workflow/work-allotment/work-allotment.component';
import { InboxComponent } from './Workflow/Mail/inbox/inbox.component';
import { SentItemsComponent } from './Workflow/Mail/sent-items/sent-items.component';
import { DeletedItemsComponent } from './Workflow/Mail/deleted-items/deleted-items.component';
import { OutwardComponent } from './Workflow/outward/outward.component';
import { GeneralTransactionFormComponent } from './Workflow/general-transaction-form/general-transaction-form.component';
import { FilePageforMailBoxComponent } from './Workflow/file-pagefor-mail-box/file-pagefor-mail-box.component';
import { FolderCreationComponent } from './Workflow/folder-creation/folder-creation.component';
import { LeaveAplicationComponent } from './Workflow/leave-aplication/leave-aplication.component';
import { OutwardedProjectsComponent } from './Workflow/outwarded-projects/outwarded-projects.component';
import { WorkFlowHistoryComponent } from './Workflow/work-flow-history/work-flow-history.component';
import { AuditReportComponent } from './audit-report/audit-report.component';
import { BonlineDashboardComponent } from './bonline-dashboard/bonline-dashboard.component';
import { AllfoldersComponent } from './BSafe/USRM/AllFolders/allfolders/allfolders.component';
import { AllfilesComponent } from './BSafe/USRM/AllFiles/allfiles.component';
import { FileRequestsComponent } from './BSafe/newComponents/file-requests/file-requests.component';
import { AcceptfriendComponent } from './BSafe/newComponents/acceptfriend/acceptfriend.component';
import { ChatComponent } from './BSafe/chat/chat.component';
import { SharefileComponent } from './BSafe/newComponents/sharefile/sharefile.component';
import { LogintouploadfilesComponent } from './BSafe/newComponents/logintouploadfiles/logintouploadfiles.component';
import { UploadfileforrequestComponent } from './BSafe/newComponents/uploadfileforrequest/uploadfileforrequest.component';
import { DeletedfilesComponent } from './BSafe/deletedfiles/deletedfiles.component';
import { HostEventComponent } from './host-event/host-event.component';
import { JoinMeetingComponent } from './join-meeting/join-meeting.component';
import { HostJoinComponent } from './host-join/host-join.component';
import { PDFViewerComponent } from './Workflow/pdfviewer/pdfviewer.component';
import { IndregisterComponent } from './indregister/indregister.component';
import { PlansComponent } from './payu/plans/plans.component';
import { CompComponent } from './editorraju/comp/comp.component';
import { GotoroutingComponent } from './gotorouting/gotorouting.component';
import { PrivacyComponent } from './BSafe/newComponents/privacy/privacy.component';
import { TermsofserviceComponent } from './BSafe/newComponents/termsofservice/termsofservice.component';
import { SupportComponent } from './BSafe/support/support.component';
import { NewfaqComponent } from './BSafe/newComponents/newfaq/newfaq.component';
import { HelppageComponent } from './BSafe/newComponents/helppage/helppage.component';
import { PayuComponent } from './payu/payu/payu.component';
import { UserProfileComponent } from './BSafe/USRM/user-profile/user-profile.component';
import { ForgotpwdComponent } from './BSafe/newComponents/forgotpwd/forgotpwd.component';
import { InwardPreviewComponent } from './Workflow/inward-preview/inward-preview.component';
import { WorkAllotmentPreviewComponent } from './Workflow/work-allotment-preview/work-allotment-preview.component';
import { OutwardPreviewComponent } from './Workflow/outward-preview/outward-preview.component';
import { FriendRequestsComponent } from './friend-requests/friend-requests.component';
import { WorkflowMasterComponent } from './Masters/workflow-master/workflow-master.component';
import { WorkFlowStateMasterComponent } from './Masters/work-flow-state-master/work-flow-state-master.component';
import { AssignRightMasterComponent } from './Masters/assign-right-master/assign-right-master.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SharingSubfolderComponent } from './BSafe/newComponents/sharing-subfolder/sharing-subfolder.component';
import { SetupPageComponent } from './setup-page/setup-page.component';
import { SetupPageDataComponent } from './setup-page-data/setup-page-data.component';
import { MinorDepeartmentComponent } from './Masters/minor-depeartment/minor-depeartment.component';
import { WorkFlowStageComponent } from './Masters/work-flow-stage/work-flow-stage.component';
import { WorkflowTypeComponent } from './Masters/workflow-type/workflow-type.component';
import { SuperadmindashboardComponent } from './superadmindashboard/superadmindashboard.component';
import { ComposemailComponent } from './Mail/composemail/composemail.component';
import { InboxMailsComponent } from './Mail/inbox-mails/inbox-mails.component';
import { SentMailsComponent } from './Mail/sent-mails/sent-mails.component';
import { DeleteMailsComponent } from './Mail/delete-mails/delete-mails.component';
import { ArchiveMailsComponent } from './Mail/archive-mails/archive-mails.component';
import { ReadMailComponent } from './Mail/read-mail/read-mail.component';
import { CkycregistrationComponent } from './ckycregistration/ckycregistration.component';
import { MsloginComponent } from './mslogin/mslogin.component';
import { DocumentViewerComponent } from './E_Signature/document-viewer/document-viewer.component';
import { EsignRegistrationComponent } from './E_Signature/esign-registration/esign-registration.component';
import { ExtInboxComponent } from './Extension/ext-inbox/ext-inbox.component';
import { ExtDeleteComponent } from './Extension/ext-delete/ext-delete.component';
import { ExtReadComponent } from './Extension/ext-read/ext-read.component';
import { ExtsentmailsComponent } from './Extension/extsentmails/extsentmails.component';
import { ExtcomposemailComponent } from './Extension/extcomposemail/extcomposemail.component';
import { MsviewComponent } from './MS_View/msview/msview.component';
import { FooterComponent } from './footer/footer.component';
import { DovViewerforMobileComponent } from './dov-viewerfor-mobile/dov-viewerfor-mobile.component';
import { SupportchatService } from './Shared/SupportChat/supportchat.service';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { DocVersionComponent } from './doc-version/doc-version.component';
import { ClientapprovalComponent } from './clientapproval/clientapproval.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { ClientdetailsComponent } from './clientdetails/clientdetails.component';
import { AuditordashboardComponent } from './auditordashboard/auditordashboard.component';
import { MailGateWayComponent } from './mail-gate-way/mail-gate-way.component';
import { ProjectTeamsComponent } from './project-teams/project-teams.component';
import { DataViewComponent } from './Analytics/data-view/data-view.component';
import { FileUploadOptionComponent } from './Analytics/file-upload-option/file-upload-option.component';
import { ProjectCreationComponent } from './Analytics/project-creation/project-creation.component';
import { BranchComponent } from './Masters/branch/branch.component';
import { AuditorviewComponent } from './auditorview/auditorview.component';
import { AddnewuserComponent } from './Masters/addnewuser/addnewuser.component';
import { UserlocationMasterComponent } from './Masters/userlocation-master/userlocation-master.component';
import { SmartcontractlistComponent } from './smartcontractlist/smartcontractlist.component';
import { ApiconsumerregistrationComponent } from './apiconsumerregistration/apiconsumerregistration.component';
import { GroupComponent } from './Masters/group/group.component';
import { HomedesignComponent } from './homedesign/homedesign.component';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { GmailMailsComponent } from './Mail/gmail-mails/gmail-mails.component';
import { ReadGmailComponent } from './Mail/read-gmail/read-gmail.component';
import { ExtensionPrivacyPolicyComponent } from './extension-privacy-policy/extension-privacy-policy.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  {
    path: 'addproject',
    component: ProjectComponent,
    canActivate: [AuthGuard] 
  },
  { path:'pdfviw',
    component:PdfViewerComponent,
    canActivate: [AuthGuard] 
  },
  
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'country',
    component: CountryComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'role',
    component: RoleComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'designation',
    component: DesignationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'menus',
    component: MenuMasterComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'registration',
    component: ERegistrationComponent,
  },
  {
    path: 'client',
    component: ClientComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'auditortransactiondetails',
    component: AuditordashboardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'addtask',
    component: AddTaskComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'userdetails',
    component: UserComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'addnewusers',
    component: AddnewuserComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:id',
    component: LoginComponent
  },
  {
    path: 'hhome',
    component: HomeComponent,
  },

  {
    path: 'projectchange',
    component: ProjectChangeComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'projectmodule',
    component: ProjectModuleComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'assigntask',
    component: AssignTaskComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'admindashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'projectview',
    component: ProjectViewComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'userdashboard',
    component: UserDashboardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'taskview',
    component: TaskViewComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'fileview',
    component: FileViewComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'clientregistration',
    component: ClientRegistrationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'projectregistration',
    component: ProjectRegistrationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'projectdocs',
    component: ProjectDocumentViewComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'inward',
    component: InwardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'tracking',
    component: WorkFlowTrackingComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'workallotment',
    component: WorkAllotmentComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'inbox',
    component: InboxComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'sentitems',
    component: SentItemsComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'deleteditems',
    component: DeletedItemsComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'outward',
    component: OutwardComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'generaltrascationform',
    component: GeneralTransactionFormComponent,
    canActivate: [AuthGuard] 
  },

  // -----------------
  {
    path: 'inboxfile',
    component: FilePageforMailBoxComponent,
    canActivate: [AuthGuard] 
  },

  {
    path: 'folder',
    component: FolderCreationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'leave',
    component: LeaveAplicationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'task',
    component: FolderCreationComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'myprojects',
    component: OutwardedProjectsComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'workflowhistory',
    component: WorkFlowHistoryComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'report/:id',
    component: AuditReportComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'bonlinedashboard',
    component: BonlineDashboardComponent,
    canActivate: [AuthGuard] 
  },

  {
    path: 'allfolders',
    component: AllfoldersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'allsubfolders/:id',
    component: AllfilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ver/:id',
    component: DocVersionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'filerequests',
    component: FileRequestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'friends',
    component: ChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'acceptfriendrequest/:id',
    component: AcceptfriendComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'shared',
    component: SharefileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sharedsubfolders/:id',
    component: SharingSubfolderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logintouploadfiles/:id',
    component: LogintouploadfilesComponent,

  },
  {
    path: 'delete',
    component: DeletedfilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'uploadfileforrequest/:id',
    component: UploadfileforrequestComponent,
   
  },
  {
    path: 'hostevent',
    component: HostEventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'joinmeeting/:id',
    component: JoinMeetingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hostjoin',
    component: HostJoinComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inwardpdfview',
    component: PDFViewerComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'indregister',
    component: IndregisterComponent,
  },
  {
    path: 'payuplans/:id',
    component: PlansComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'payment',
    component: PayuComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'editfileview',
    component: CompComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gotorouting',
    component: GotoroutingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'termsofservice',
    component: TermsofserviceComponent,
  },
  {
    path: 'howto',
    component: SupportComponent,
  },
  {
    path: 'faq',
    component: NewfaqComponent,
  },
  {
    path: 'help',
    component: HelppageComponent,
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotpwdComponent,
  },
  {
    path: 'inwardpreview',
    component: InwardPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workallotmentpreview',
    component: WorkAllotmentPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'outwardpreivew',
    component: OutwardPreviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'friendrequest',
    component: FriendRequestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workflowmaster',
    component: WorkflowMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workflowstatemaster',
    component: WorkFlowStateMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assignrightmaster',
    component: AssignRightMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'aboutus',
    component: AboutUsComponent,
  },
  {
    path: 'setup',
    component: SetupPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'setupdata/:id',
    component: SetupPageDataComponent
  },
  {
    path: 'subleveldepartment',
    component: MinorDepeartmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workflowstage',
    component: WorkFlowStageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'workflowtype',
    component: WorkflowTypeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Superadmin',
    component: SuperadmindashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'composemsg',
    component: ComposemailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inboxmails',
    component: InboxMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'readmsg/:id',
    component: ReadMailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sentmails',
    component: SentMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'listsmartcontract',
    component: SmartcontractlistComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'del',
    component: DeleteMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive',
    component: ArchiveMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'CKYCR',
    component: CkycregistrationComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'msview',
  //   component: MsloginComponent
  // },
  {
    path: 'viewer',
    component: DocumentViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'esignreg',
    component: EsignRegistrationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'extinboxmails',
    component: ExtInboxComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'delextmails',
    component: ExtDeleteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'readextmsg',
    component: ExtReadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sentextmails',
    component: ExtsentmailsComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'composeextmsg',
    component: ExtcomposemailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'msview',
    component: MsviewComponent,
   
  },
  {
    path: 'support',
    component: SupportChatComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'footer',
    component: FooterComponent
  },
  {
    path: 'mobviewer/:id',
    component: DovViewerforMobileComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'clientapproval/:id',
    component: ClientapprovalComponent
  },
  {
    path: 'clieantdashboard',
    component: ClientdashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'clientdetails/:id',
    component: ClientdetailsComponent
  },
  {
    path: 'Gateway',
    component: MailGateWayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Team',
    component: ProjectTeamsComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"createproject",
    component:ProjectCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"addInformation",
    component:FileUploadOptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"viewRawDataset",
    component:DataViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"Branch",
    component:BranchComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"auditorview",
    component:AuditorviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"userlocation",
    component:UserlocationMasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"apiconsumerreg",
    component:ApiconsumerregistrationComponent
  },
  {
    path:"group",
    component:GroupComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"home",
    component:HomedesignComponent
  },
  {
    path:"gmailmail",
    component:GmailMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"gmailmail/:id",
    component:GmailMailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"readgmail/:id",
    component:ReadGmailComponent,
    canActivate: [AuthGuard]
  },
  {
    path:"extensionPrivacyPolicy",
    component:ExtensionPrivacyPolicyComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
