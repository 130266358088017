import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  readonly url = "https://blocdrive.com/blocdriveapi/api/";

  constructor(private http:HttpClient) { }
  getCIDetails(data){
    return this.http.post(this.url +"Audit/PostCreatedItemsData",data);
  }
  getDIDetails(data){
    return this.http.post(this.url +"Audit/PostDeletedItemsData",data);
  }
  getEIDetails(data){
    return this.http.post(this.url +"Audit/PostEditedItemsData",data);
  }
  getUserSessionDetails(data){
    return this.http.post(this.url +"Audit/PostUserSessionData",data);
  }
  getUserTransactionDetails(data){
    return this.http.post(this.url +"Audit/PostUserTransactionData",data);
  }
  getDownloadedTransactionDetails(data){
    return this.http.post(this.url +"Audit/PostDownloadedItemsData",data);
  }
  getWorkflowTransactionDetails(data){
    return this.http.post(this.url +"Audit/PostWorkflowItemsData",data);
  }
}
