import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuMaster } from 'src/app/ViewModels/MenuMaster';

@Injectable({
  providedIn: 'root'
})
export class MenuMasterService {

  readonly url="https://blocdrive.com/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  saveMenu(data:MenuMaster){
    return this.http.post(this.url +"MenuMaster/PostSaveMenuMaster",data);
  }
  getAllMenus(id){
    return this.http.get(this.url +"MenuMaster/GetAllMenus/" + id);
  }
  getAllMenudata(id){
    return this.http.get(this.url +"MenuMaster/GetAllMenuMaster/" + id);
  }
  deleteMenu(id: number){
    return this.http.get(this.url +"MenuMaster/GetDeleteMenuMasterData/"+ id);
  }

  UpdateMenu(data:MenuMaster){
    return this.http.post(this.url +"MenuMaster/PutMenuMaster",data);
  }
  getusermenus(id){
    return this.http.get(this.url +"MenuMaster/GetUserMenus/"+ id);
  }
  getadmindashboardmenus(id){
    return this.http.get(this.url +"MenuMaster/GetAdminDahboardMenus/"+ id);
  }
  logoutsession(id){
    return this.http.get(this.url +"MenuMaster/GetLogoutSession/"+ id);
  }
  getbsafemenus(){
    return this.http.get(this.url +"MenuMaster/GetBsafeMenus");
  }
  getpmtmenus(id){
    return this.http.get(this.url +"MenuMaster/GetPMTMenus/" + id);

  }
  downloadFile(){
    return this.http.get(this.url + "DownloadFolderorFile/GetDownloadBSAFESyncFile");
  }

  downloadFileBlockDrive(){
    return this.http.get(this.url + "DownloadFolderorFile/GetDownloadBlockDrive");
  }

  downloadFileportable(){
    return this.http.get(this.url + "DownloadFolderorFile/GetDownloadBSAFEPortableFile");
  }
  downloadGoogleDriveFiles(){
    return this.http.get(this.url + "DownloadFolderorFile/GetDownloadGoogleDriveFiles");

  }
  getAllSetupList(id){
    return this.http.get(this.url +"MenuMaster/GetSetupMenus/" + id);

  }
  getdrivemenus(){
    return this.http.get(this.url +"MenuMaster/GetDriveMenus");
  }
 getmailenus(){
  return this.http.get(this.url +"MenuMaster/GetMailboxMenus");
}

getAllUsers(id){
  return this.http.get(this.url +"User/GetEnterprisedetails/" + id);
}
}
