import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CountryService } from '../Shared/Country/country.service';
import { EnterPriseRegisterService } from '../Shared/EnterPriseRegister/enter-prise-register.service';
import { EmailValidation } from '../ViewModels/EmailValidation';
import { EnterPriseRegister } from '../ViewModels/EnterPriseRegister';

@Component({
  selector: 'app-apiconsumerregistration',
  templateUrl: './apiconsumerregistration.component.html',
  styleUrls: ['./apiconsumerregistration.component.scss']
})

  export class ApiconsumerregistrationComponent implements OnInit {


    CountryList: any;
    senddata: {};
  
    EnterpriseForm = new FormGroup({
      cname: new FormControl(),
      noemp: new FormControl(),
      fname: new FormControl(),
      sname: new FormControl(),
      Surname: new FormControl(),
      email: new FormControl(),
      staddress: new FormControl(),
      staddress2: new FormControl(),
      city: new FormControl(),
      pincode: new FormControl(),
      state: new FormControl(),
      phnno: new FormControl(),
      username: new FormControl(),
      password: new FormControl(),
      cpassword: new FormControl(),
      otp: new FormControl(),
  
    });
    noemp: any
    sname: any
    countryid: any;
    submitted = false;
    validtrue = false;
    comname: any;
    IsConfirm:any;
    noofemp: any;
    fname: any;
    cname: any
    surname: any;
    Surname: any
    email: any;
    staddress: any;
    staddress2: any;
    city: any;
    pincode: any;
    state: any;
    phnno: any;
    cpassword: any;
    username: any;
    checkemail: string;
    checkphone: string;
    checkpincode: string;
    password: any;
    files1: any;
    SelectedFiles: any;
    validfile: boolean;
    DateTime: Date;
    alldata: void;
    firstdata: string;
    seconddata: string;
    credentials: string;
    public dismissalert = false;
    notify: string
    validateemail: any;
    mailresult: any;
    validmail: boolean = false;
    validateusername: any;
    usernameresult: any;
    validusername: boolean;
    mobileview: boolean;
    website: string
    websiteresult: any;
    validwebsite: boolean;
    enableotp: boolean=false;
    otp: any;
    validotp: boolean=false;
    displayotpmsg: string;
    otpresult: any;
    validatedotp: boolean=false;
    useremail: any;
    userimage: any;
    signatureverfiyid: Object;
    SignatureFileTwo: any;
    SignatureFile: any;
    imageverfiyid: Object;
  
  
    imageres: Object;
    sigres: Object;
    files2: any;
    signatureurl: string | ArrayBuffer;
    ImageFile: any;
    croppedImage: any;
    imageChangedEvent: any;
    uimg: any;
    imageurltwo: string | ArrayBuffer;
    signatureurltwo: string | ArrayBuffer;
    confirm: boolean;
    verification: boolean=false;
    pictureTaken: any;
  
    get f() { return this.EnterpriseForm.controls; }
    constructor(private spinner: NgxSpinnerService, private countryService: CountryService, private router: Router,
      private enterpriseservice: EnterPriseRegisterService, private formBuilder: FormBuilder, private datepipe: DatePipe, private httpService: HttpClient,
      private alerts: AlertsService) {
      this.isMobileMenu();
      this.EnterpriseForm = formBuilder.group({
        cname: ['', Validators.required],
        noemp: ['', Validators.required],
        fname: ['', Validators.required],
        sname: ['', Validators.required],
        Surname: new FormControl(),
        email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
        // email: ['', [Validators.required, Validators.email]],
        staddress: ['', Validators.required],
        staddress2: ['', Validators.required],
        city: ['', Validators.required],
        pincode: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
        state: ['', Validators.required],
        phnno: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
        username: ['', Validators.required],
        password: ['', Validators.required],
        cpassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)]],
        website: ['', Validators.required],
        otp: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
  
      })
    }
    ngOnInit(): void {
      $(document).ready(function () {
  
        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
  
        $(".next").click(function () {
          var value = localStorage.getItem("validateval")
          if (value == "true") {
  
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();
  
            //Add Class Active
            $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
  
            //show the next fieldset
            next_fs.show();
            //hide the current fieldset with style
            current_fs.animate({ opacity: 0 }, {
              step: function (now) {
                // for making fielset appear animation
                opacity = 1 - now;
  
                current_fs.css({
                  'display': 'none',
                  'position': 'relative'
                });
                next_fs.css({ 'opacity': opacity });
              },
              duration: 600
            });
            localStorage.setItem("validateval", "false");
          }
        });
  
        $(".previous").click(function () {
          localStorage.setItem("validateval", "true");
  
          current_fs = $(this).parent();
          previous_fs = $(this).parent().prev();
  
          //Remove class active
          $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
  
          //show the previous fieldset
          previous_fs.show();
  
          //hide the current fieldset with style
          current_fs.animate({ opacity: 0 }, {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;
  
              current_fs.css({
                'display': 'none',
                'position': 'relative'
              });
              previous_fs.css({ 'opacity': opacity });
            },
            duration: 600
          });
        });
  
        $('.radio-group .radio').click(function () {
          $(this).parent().find('.radio').removeClass('selected');
          $(this).addClass('selected');
        });
  
        $(".submit").click(function () {
          return false;
        })
  
      });
      this.Countries();
    }
    isMobileMenu() {
      if ($(window).width() > 991) {
        this.mobileview = false;
      }
      else {
        this.mobileview = true;
      }
    };
    Countries() {
      this.spinner.show();
      this.countryService.getAllCountries().subscribe((Data: any) => {
        this.CountryList = Data;
        this.spinner.hide();
      })
    }
    onSelectCountry(id) {
      this.countryid = id;
    }
    Validatewebsite() {
      if (!this.website.includes('http')) {
        this.website = "http://" + this.website
      }
      var WebsiteValidation = {
        Website: this.website
      }
      this.spinner.show();
      this.enterpriseservice.validatewebsite(WebsiteValidation).subscribe((Data: any) => {
        this.websiteresult = Data;
        this.spinner.hide();
  
        if (this.websiteresult == true) {
          localStorage.setItem("validateval", "true")
          this.validwebsite = false;
  
        }
        else {
          localStorage.setItem("validateval", "false")
          this.validwebsite = true;
        }
        this.spinner.hide();
  
      })
  
    }
    // Validatemail() {
    //   this.validateemail = this.EnterpriseForm.controls["email"].value;
    //   this.spinner.show();
    //   const data: EmailValidation = {
    //     Email: this.validateemail
    //   }
    //   this.enterpriseservice.validateemail(data).subscribe((Data: any) => {
    //     this.mailresult = Data;
    //     if (this.mailresult == "exist") {
    //       localStorage.setItem("validateval", "false")
    //       this.validmail = true;
    //     }
    //     else {
    //       localStorage.setItem("validateval", "true")
    //       this.validmail = false;
  
    //     }
    //     this.spinner.hide();
  
    //   })
    // }
  
  
      //identity verification
  
    
      verifysignature() {
        if (this.SignatureFile != null && this.SignatureFileTwo != null) {
    
          var uid = localStorage.getItem("userId");
          const frmData = new FormData();
          frmData.append("userId", uid);
    
          frmData.append("fileUpload", this.SignatureFile);
          frmData.append("fileUploadTwo", this.SignatureFileTwo);
          // frmData.append("capimg", this.sigimg);
    
          this.spinner.show();
          //this.httpService.post('http://localhost:8540/api/User/verifysignature/', frmData).subscribe(
            this.httpService.post('http://localhost:8540/api/User/verifysignature/', frmData).subscribe(
              data => {
    
              //call flask api just like face recognition
              if (data != 0) {
                this.signatureverfiyid = data
                this.httpService.get('http://localhost:4200/flaskapp/sigmatchfin/' + data+",blocdrive").subscribe(
                  resp => {
                    localStorage.setItem("validateval", "true");
                    localStorage.setItem("validatesign", "true")
                    console.log("Python Response Data",resp)
                    this.sigres = resp;
                    alert("Verified Successfully!!");
                    this.spinner.hide();
    
                  },
                  (err: HttpErrorResponse) => {
                      // Show error, if any.
                   this.getsignverifieddetail(data);
                   localStorage.setItem("validatesign", "false");
                      localStorage.setItem("validateval", "true")
                      this.spinner.hide();
                  });
                  
              }
              else {
                alert("Signature did not match..!! ");
              }
            },
            (err: HttpErrorResponse) => {
              alert("Something went wrong. Please try again..!! ");
              console.log(err.message);    // Show error, if any.
            });
    
        }
        else {
          alert("Select your image and Sign");
        }
      }
    
      getsignverifieddetail(id)
      {
        this.enterpriseservice.Getsignverifieddet(id).subscribe((data => {
          this.spinner.hide()
          if(data == true)
          {
            this.sigres = "Matched";
            alert("Verified Successfully!!");
            localStorage.setItem("validateval", "true");
            localStorage.setItem("validatesign", "true")
    
            // alert(data);
          }
          else
          {
            localStorage.setItem("validatesign", "false");
            alert("Not Matched");
    
            // alert(data);
          }
         
        }));
      }
      onChangeforSignature(event: any) {
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.SignatureFile = fileslistone;
        localStorage.setItem("validateval", "true");
        localStorage.setItem("validatesign", "false");
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
            this.signatureurl = event.target.result;
          }
        }
      }
      onChangeforSignatureTwo(event: any) {
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.SignatureFileTwo = fileslistone;
        localStorage.setItem("validateval", "true")
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
            this.signatureurltwo = event.target.result;
          }
        }
      }
    
      imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.ImageFile = this.croppedImage;
        this.SignatureFileTwo = this.croppedImage;
        localStorage.setItem("validateval", "true")
        localStorage.setItem("userverification", "false");
      }
      onChangeforImage(event: any) {
        document.getElementById("openpopup").click();
        this.imageChangedEvent= event;
        var fileslistone = "";
        this.files2 = [].slice.call(event.target.files);
        console.log(this.files2);
        fileslistone = this.files2[0];
    
        this.ImageFile = fileslistone;
        this.SignatureFileTwo = fileslistone;
        localStorage.setItem("validateval", "true")
        localStorage.setItem("userverification", "false");
        if (event.target.files && event.target.files[0]) {
          var reader1 = new FileReader();
    
          reader1.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader1.onload = (event) => { // called once readAsDataURL is completed
            this.imageurltwo = event.target.result;
          }
        }
      }
      handleFileInput1(files: File) {
        this.uimg = files[0];
      }
      public seconds: number;
      private trigger: Subject<void> = new Subject<void>();
    
      // latest snapshot
      public webcamImage: WebcamImage = null;
    
      public triggerSnapshot(): void {
        this.spinner.show()
        this.seconds = 3;
        setTimeout(() => {
          this.seconds = 2;
          setTimeout(() => {
            this.seconds = 1
            setTimeout(() => {
              this.trigger.next();
              this.seconds = null;
            }, 2000)
          }, 2000)
        }, 2000)
        this.spinner.hide();
    
      }
  
      public handleImage(webcamImage: WebcamImage): void {
        console.info('received webcam image', webcamImage);
        this.webcamImage = webcamImage;
        console.log(webcamImage);
        this.pictureTaken.emit(webcamImage);
      }
    
      public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
      }
    
      verifyimage() {
        const frmData = new FormData();
        frmData.append("image", this.ImageFile);
        frmData.append("capimg", this.webcamImage.imageAsBase64);
    
        this.spinner.show();
        // this.httpService.post('http://localhost:8540/api/User/verifyimage/', frmData).subscribe(
        this.httpService.post('http://localhost:8540/api/User/verifyimage/', frmData).subscribe(
          data => {
    
            if (data != 0) {
              this.imageverfiyid = data
  
              
              this.httpService.get('http://localhost:4200/flaskapp/outputfin/' + data+",blocdrive")
              .subscribe(
                data => {
                  localStorage.setItem("validateval", "true");
                  localStorage.setItem("userverification", "true");
                  console.log("Python Response Data",data)
                  this.imageres = data;
                  alert("Verified Successfully!!");
                  this.spinner.hide();
                },
                (err: HttpErrorResponse) => {
                  // Show error, if any.
               this.getimgverifieddetail(data);
                  localStorage.setItem("validateval", "true");
                  localStorage.setItem("userverification", "false");
    
              });
            }
            else {
              alert("Faces do not match!! Something went wrong. Please try again..!! ");
            }
          },
          
          (err: HttpErrorResponse) => {
            alert("Something went wrong. Please try again..!! ")
            console.log(err.message);    // Show error, if any.
          });
    
    
      }
      onSelectIsConfirm(event) {
        if (event.target.checked) {
          this.confirm = true;
          this.verification=true;
        }
        else {
          this.confirm = false;
          this.verification=false;
        }
      }
      getimgverifieddetail(id)
      {
        this.enterpriseservice.Getimgverifieddet(id).subscribe((data => {
          this.spinner.hide()
          if(data == true)
          {
            this.imageres = "Matched";
            // alert(data);
            alert("Verified Successfully!!");
            localStorage.setItem("validateval", "true")
            localStorage.setItem("userverification", "true");
    
          }
          else
          {
            localStorage.setItem("userverification", "false");
            this.imageres = "Not Matched";
            // alert(data);
            alert("Not Matched");
    
          }
         
        }));
      }
    Validatemail() {
      this.validateemail = this.EnterpriseForm.controls["email"].value;
  
    //   if(this.validateemail.includes('gmail.com') || this.validateemail.includes('email.com') ||this.validateemail.includes('yahoo.com') || this.validateemail.includes('hotmail.com') || this.validateemail.includes('aol.com') || this.validateemail.includes('outlook.com')){
    //     alert("Please Enter Corporate Mail!")
    //   }
    //  else{
  
      this.validmail = false
      if (this.validateemail.includes('@')) {
        this.senddata = {
          Email: this.validateemail,
          MobileNumber: null,
          UserName: this.fname,
          FirstName: this.fname
        }
      }
      else {
        this.senddata = {
          MobileNumber: this.validateemail,
          Email: null,
          UserName: this.fname,
          FirstName: this.fname
        }
      }
      this.spinner.show();
  
      this.enterpriseservice.validateemailormobile(this.senddata).subscribe((Data: any) => {
        this.spinner.hide();
        this.mailresult = Data;
        if (this.mailresult == "exist") {
          this.validmail = true;
          setTimeout(() => {
            this.validmail = false;
  
          }, 2000);
          this.enableotp = false;
        }
        else {
          this.validmail = false;
          this.enableotp = true;
  
        }
        this.spinner.hide();
  
      })
    
  }
    Validateotp() {
      this.validateemail = this.EnterpriseForm.controls["email"].value;
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.spinner.show();
      var data = {
        MobileorEmail: this.validateemail,
        OTP: this.otp,
        CreatedOn: latest_date,
        UserId: null
      }
      this.enterpriseservice.ValidateOTPRegistration(data).subscribe((response: any) => {
        this.spinner.hide();
        this.otpresult = response;
        if (response == '0') {
          alert("Email/Mobile Validated Successfully!")
          localStorage.setItem("validateval", "true")
          this.validotp = false;
          this.validatedotp=true;
        }
  
        else if (response == '1') {
          alert("Incorrect OTP")
          localStorage.setItem("validateval", "false")
          this.validotp = true;
          this.displayotpmsg = "Incorrect OTP"
          this.validatedotp=false;
  
        }
        else if (response == '2') {
          alert("OTP has been expired")
          localStorage.setItem("validateval", "false")
          this.validotp = true;
          this.displayotpmsg = "OTP has been expired"
          this.validatedotp=false;
  
        }
  
        this.spinner.hide();
  
      })
    }
    Validateusername() {
      this.validateusername = this.EnterpriseForm.controls["username"].value;
      this.spinner.show();
      const data: EmailValidation = {
        Email: this.validateusername
      }
      this.enterpriseservice.validateusername(data).subscribe((Data: any) => {
        this.usernameresult = Data;
        if (this.usernameresult == "exist") {
          this.validusername = true;
        }
        else {
          this.validusername = false;
  
        }
        this.spinner.hide();
  
      })
    }
    Validateone() {
      this.submitted = true;
      this.comname = this.EnterpriseForm.controls["cname"].value;
      this.noofemp = this.EnterpriseForm.controls["noemp"].value;
      this.fname = this.EnterpriseForm.controls["fname"].value;
      this.surname = this.EnterpriseForm.controls["sname"].value;
      this.email = this.EnterpriseForm.controls["email"].value;
      this.checkemail = this.EnterpriseForm.controls["email"].status;
      if (this.EnterpriseForm.invalid && (this.comname == undefined || this.noofemp == undefined || this.fname == undefined || this.surname == undefined || this.email == undefined || this.comname == "" || this.noofemp == "" || this.fname == "" || this.surname == "" || this.email == "" || this.checkemail == "INVALID")) {
        localStorage.setItem("validateval", "false")
      }
      else {
        this.Validatemail();
        //localStorage.setItem("validateval", "true")
        
      }
    }
    Validatethree() {
      this.submitted = true;
      this.staddress = this.EnterpriseForm.controls["staddress"].value;
      this.staddress2 = this.EnterpriseForm.controls["staddress2"].value;
      this.city = this.EnterpriseForm.controls["city"].value;
      this.pincode = this.EnterpriseForm.controls["pincode"].value;
      this.state = this.EnterpriseForm.controls["state"].value;
      this.checkpincode = this.EnterpriseForm.controls["pincode"].status;
  
      if (this.EnterpriseForm.invalid && (this.staddress == undefined || this.staddress2 == undefined || this.city == undefined || this.pincode == undefined || this.state == undefined 
        || this.staddress == "" || this.staddress2 == "" || this.city == "" || this.pincode == "" || this.state == "" || this.checkphone == "INVALID" || this.checkpincode == "INVALID")) {
        localStorage.setItem("validateval", "false")
        return;
      }
      else {
        localStorage.setItem("validateval", "true")
      }
    }
    Validatefour() {
      this.submitted = true;
      this.username = this.EnterpriseForm.controls["username"].value;
      this.cpassword = this.EnterpriseForm.controls["cpassword"].value;
      this.password = this.EnterpriseForm.controls["password"].value;
  
      if (this.EnterpriseForm.invalid && (this.password == undefined || this.password == "" || this.username == undefined || this.cpassword == undefined || this.username == "" || this.cpassword == "")) {
        localStorage.setItem("validateval", "false")
        return;
      }
      else if (this.cpassword != this.password) {
        alert("Password do not match!!")
        localStorage.setItem("validateval", "false")
  
      }
      else {
        localStorage.setItem("validateval", "true")
      }
    }
    RegisterUser() {
      if (this.email.includes('@')){
        this.phnno=null
        this.useremail=this.email
      }
      else{
        this.phnno= this.email
        this.useremail=null
  
      }
      var value = localStorage.getItem("validateval")
      if (value == "true") {
        const frmData = new FormData();
        this.spinner.show();
        this.DateTime = new Date();
        let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
        const inputRequest: EnterPriseRegister = {
          Id: 0,
          EnterPriseID: null,
          CompanyName: this.comname,
          NoOfEmployees: this.noofemp,
          Country: this.countryid,
          FirstName: this.fname,
          Surname: this.surname,
          Email: this.useremail,
          Logo: this.SelectedFiles,
          StreetaddressLineOne: this.staddress,
          StreetaddressLineTwo: this.staddress2,
          City: this.city,
          Pincode: this.pincode,
          State: this.state,
          PhoneNumber: this.phnno,
          Username: this.username,
          Password: this.password,
          date: latest_date
        }
        this.firstdata = this.comname + "," + this.noofemp + "," + this.countryid + "," + this.fname + "," + this.surname + "," + this.useremail + "," + this.website;
        this.seconddata = this.staddress + "," + this.staddress2 + "," + this.city + "," + this.pincode + "," + this.state + "," + this.phnno;
        this.credentials = this.username + "," + this.password + "," + latest_date;
        frmData.append("firstdata", this.firstdata);
        frmData.append("seconddata", this.seconddata);
        frmData.append("credentials", this.credentials);
        frmData.append("APIConsumer", "Yes");
        frmData.append("logo", this.SelectedFiles);  
        this.httpService.post('https://blocdrive.com/blocdriveapi/api/EnterPriseRegistration/PostEnterPriseRegistration/', frmData).subscribe(
  
          data => {
            this.spinner.hide();
            this.router.navigate(['/login']);
          });
      }
      else {
        return
      }
    }
    onDrop(event: any) {
  
      var fileslist = "";
      var files = event;
  
      this.files1 = files;
      if (this.files1.length == 1 && (this.files1[0].type == "image/png" || this.files1[0].type == "image/jpg" || this.files1[0].type == "image/jpeg")) {
        for (let k = 0; k < this.files1.length; k++) {
          if (this.files1[k].type == "") {
            alert("Please Upload only Files")
            this.files1 = "";
          }
          fileslist = this.files1[k].name + ",";
        }
  
        this.SelectedFiles = fileslist;
        localStorage.setItem("validateval", "true")
        this.validfile = true;
      }
      else {
        localStorage.setItem("validateval", "false")
        this.validfile = false;
      }
    }
    onChange(event: any) {
      var fileslist = "";
      this.files1 = [].slice.call(event.target.files);
      console.log(this.files1);
      fileslist = this.files1[0];
  
      this.SelectedFiles = fileslist;
      localStorage.setItem("validateval", "true")
  
    }
  }