import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GmailmigrationService } from 'src/app/Shared/GmailMigration/gmailmigration.service';

@Component({
  selector: 'app-read-gmail',
  templateUrl: './read-gmail.component.html',
  styleUrls: ['./read-gmail.component.css']
})
export class ReadGmailComponent implements OnInit  {
  @ViewChild('contentToConvert', { static: false }) contentToConvert: ElementRef;


  uid: string;
  threadid: any;
  gmaildata: any;
  zzz: any;
  current_url: any;
  pdfUrl: any;
  closeResult: string;
  msg: any;
  m: any;
  imgdisplay: any;
  audioplay: any;
  date: any;
  audiodate: any;
  videoplay: any;
  videodate: any;
  datee: Date;
  pdfByteArray: any;
  byteArray: number[];
  dashboard: any;
  subject: any;
  allattachments: any;
  public sanitizedBody: SafeHtml;

  public safeHtmlContent: SafeHtml;
  constructor(private router: ActivatedRoute,private spinner: NgxSpinnerService,private gmailservice : GmailmigrationService,
    private modalService: NgbModal,private domSanitizer: DomSanitizer) {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.threadid = params["id"];
      }
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
    }
   }



  ngOnInit(): void {
    this.GetGmailDetais();
  }

  GetGmailDetais() {
    this.spinner.show();
    this.gmailservice.getgmaildetails(this.threadid).subscribe((respose: any) => {
      this.gmaildata = respose;
      this.subject = this.gmaildata[0].subject;
      // this.sanitizedBody = this.sanitizeEmailBody(this.gmaildata.subject);

      const formattedContent = this.convertToHtml(this.gmaildata[0].body);

      // Bypass Angular's security to trust the HTML content
      this.safeHtmlContent = this.domSanitizer.bypassSecurityTrustHtml(formattedContent);
      console.log(respose);
      this.spinner.hide();
    });
  }
  convertToHtml(text: string): string {
    return text.replace(/\r\n/g, '<br>');
  }
  sanitizeEmailBody(body: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(body);
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.filebloblink);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.filebloblink;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.filebloblink
    this.videodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }

  Viewaudio(cc, videocontent) {
    this.audioplay = cc.filebloblink
    this.audiodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }

  maildwnld(){
    if (this.dashboard) {
      const divElement = this.dashboard.nativeElement;
      const divContent = divElement.innerHTML;
  
      // Convert the string content to a byte array with a specific encoding (e.g., UTF-8)
      const encoder = new TextEncoder();
      const byteArray = encoder.encode(divContent);
  
      // Now, 'byteArray' contains the content of the <div> element as a byte array
      console.log(byteArray);


      const signinfo={
        Mailbyte : byteArray,
        FileName : "downloadfile.pdf"
    }

      this.gmailservice.downloadgmailfile(signinfo).subscribe((respose: any) => {
        this.gmaildata = respose;
      
  
        console.log(respose);
        this.spinner.hide();
      });

    }
  }
//   async toPdf() {
//     const dashboard = document.getElementById('dashboard');

//     const dashboardHeight = dashboard.clientHeight;
//     const dashboardWidth = dashboard.clientWidth;
//     const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

//     domtoimage.toPng(dashboard, options).then((imgData) => {
//          const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
//          const imgProps = doc.getImageProperties(imgData);
//          const pdfWidth = doc.internal.pageSize.getWidth();
//          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//          doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//          doc.save('Dashboard for hyperpanels.pdf');
//          this.pdfByteArray = doc.output('arraybuffer');
//       console.log(this.pdfByteArray);

//       const arrayBuffer = this.pdfByteArray; // Your ArrayBuffer

//       // Convert ArrayBuffer to a Uint8Array
//       const uint8Array = new Uint8Array(arrayBuffer);
      
//       // Convert Uint8Array to a regular JavaScript array if needed
//       this.byteArray = Array.from(uint8Array);
//       console.log(this.byteArray);

//       const frmData = new FormData();
//       frmData.append("FileBlobOne", this.pdfByteArray);
//   //    const arrayBuffer =  this.pdfByteArray;
  
//       // Convert ArrayBuffer to JSON
//       const jsonData = JSON.stringify(arrayBuffer);
  
//       // Now you can work with jsonData or send it to an API, for example
//       console.log(jsonData);
       
// //       const arrayBuffer = this.pdfByteArray;
// // const textDecoder = new TextDecoder('utf-8'); // You may need to adjust the encoding based on your data.
// // const jsonString = textDecoder.decode(arrayBuffer);
// // console.log(jsonString)
//     });
   
//   }

  // async toPdf() {
  //   try {
  //     const dashboard = document.getElementById('dashboard');
  
  //     const dashboardHeight = dashboard.clientHeight;
  //     const dashboardWidth = dashboard.clientWidth;
  //     const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };
  
  //     const imgData = await domtoimage.toPng(dashboard, options);
  
  //     const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
  //     const imgProps = doc.getImageProperties(imgData);
  //     const pdfWidth = doc.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
  //     doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     doc.save('Dashboard for hyperpanels.pdf');
  //     this.pdfByteArray = doc.output('arraybuffer');
  //     console.log(this.pdfByteArray);
  
  //     const arrayBuffer = this.pdfByteArray; // Your ArrayBuffer
  
  //     // Convert ArrayBuffer to a Uint8Array
  //     const uint8Array = new Uint8Array(arrayBuffer);
  
  //     // Convert Uint8Array to a regular JavaScript array if needed
  //     this.byteArray = Array.from(uint8Array);
  //     console.log(this.byteArray);
  
  //     const frmData = new FormData();
  //     frmData.append("FileBlobOne", this.pdfByteArray);
  
  //     // Convert ArrayBuffer to JSON
  //     const jsonData = JSON.stringify(arrayBuffer);
  
  //     // Now you can work with jsonData or send it to an API, for example
  //     console.log(jsonData);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }



  



  // public convetToPDF()
  // {
  // var data = document.getElementById('dashboard');
  // html2canvas(data).then(canvas => {
  // // Few necessary setting options
  // var imgWidth = 208;
  // var pageHeight = 295;
  // var imgHeight = canvas.height * imgWidth / canvas.width;
  // var heightLeft = imgHeight;
  
  // const contentDataURL = canvas.toDataURL('image/png')
  // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  // var position = 0;
  // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  // pdf.save('new-file.pdf'); // Generated PDF
  // });
  // }


  // public convetToPDF()
  // {
  //   const data = document.getElementById('dashboard');
    
  //   // Function to check if all images are loaded
  //   function areAllImagesLoaded(images: HTMLImageElement[]): boolean {
  //       return images.every((img) => img.complete);
  //   }

  //   // Get all images within the 'dashboard' element
  //   const images: HTMLImageElement[] = Array.from(data.getElementsByTagName('img'));

  //   // Check if all images are loaded, and capture the content when they are
  //   function captureWhenImagesLoaded() {
  //       if (areAllImagesLoaded(images)) {
  //           html2canvas(data).then((canvas) => {
  //               // Your existing code for generating the PDF here...
  //               var imgWidth = 208;
  //               var pageHeight = 295;
  //               var imgHeight = canvas.height * imgWidth / canvas.width;
  //               var heightLeft = imgHeight;
  //               const contentDataURL = canvas.toDataURL('image/png');
  //               let pdf = new jspdf('p', 'mm', 'a4');
  //               var position = 0;
  //               pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //               pdf.save('new-file.pdf');
  //           });
  //       } else {
  //           // If not all images are loaded, wait for a moment and check again
  //           setTimeout(captureWhenImagesLoaded, 100);
  //       }
  //   }
  // }



//   public convetToPDF1()
// {
// var data = document.getElementById('contentToConvert');
// html2canvas(data).then(canvas => {
// // Few necessary setting options
// var imgWidth = 208;
// var pageHeight = 295;
// var imgHeight = canvas.height * imgWidth / canvas.width;
// var heightLeft = imgHeight;

// const contentDataURL = canvas.toDataURL('image/png')
// let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
// var position = 0;
// pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
// pdf.save('new-file.pdf'); // Generated PDF
// });
// }

//  maildwnld(){
//   var data = document.getElementById('print-section');
//   this.spinner.show();
//   html2canvas(data).then(canvas => {
//     var imgWidth = 100;
//     var imgHeight = canvas.height * imgWidth / canvas.width;
//     const contentDataURL = canvas.toDataURL('image/jpg')
//     var signinfo = {
//       Mailbyte : contentDataURL,
//       FileName : "downloadfile.pdf"
//     }
//       this.gmailservice.downloadgmailfile(signinfo).subscribe((respose: any) => {
//         this.gmaildata = respose;  
//         console.log(respose);
//         this.spinner.hide();
//       });

//     })
//   }

 captureAndDownload() {
  const screenshotDiv = document.getElementById('screenshotDiv');
  const captureButton = document.getElementById('captureButton');

  // if (!screenshotDiv || !captureButton) {
  //     console.error('Elements not found.');
  //     return;
  // }

  // Create a canvas element to render the screenshot
  const canvas = document.createElement('canvas');
  canvas.width = screenshotDiv.offsetWidth;
  canvas.height = screenshotDiv.offsetHeight;

  const context = canvas.getContext('2d');

  // // Draw the contents of the div onto the canvas
  // context.drawImage(screenshotDiv, 0, 0, canvas.width, canvas.height);

  // Convert the canvas content to a data URL (base64-encoded image)
  const dataURL = canvas.toDataURL('image/png'); // You can specify other image formats

  // Create a download link for the image
  const a = document.createElement('a');
  a.href = dataURL;
  a.download = 'screenshot.png'; // Specify the desired file name and extension

  // Simulate a click on the download link to trigger the download
  a.click();
}


// // downloads web page along with images using html extension
// print1 (printSectionId: any) {
//   var innerContents = document.getElementById(printSectionId).innerHTML;

//   // Create a blob containing the innerContents
//   var blob = new Blob([innerContents], { type: 'text/html' });

//   // Create a URL for the blob
//   var url = URL.createObjectURL(blob);

//   // Create an anchor element to trigger the download
//   var a = document.createElement('a');
//   a.href = url;
//   a.download = "dd.html"; // Specify the desired file name (e.g., "myFile.html")

//   // Programmatically click the anchor element to trigger the download
//   a.click();

// };



// downloads web page using pdf extension
// print1 (printSectionId: any) {
// var innerContents = document.getElementById(printSectionId).innerHTML;

// const content = this.contentToConvert.nativeElement;

// html2canvas(content).then(canvas => {
//   const imgData = canvas.toDataURL('image/png');
//   const pdf = new jsPDF('p', 'mm', 'a4');
//   pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
//   pdf.save('your-document.pdf');
// });
// }


// downloads web page using html extension
async print2 (printSectionId: any) {
  var innerContents = document.getElementById(printSectionId).innerHTML;

  // Create a blob containing the innerContents
  var blob = new Blob([innerContents], { type: 'text/html' });

  // Create a URL for the blob
  var url = URL.createObjectURL(blob);

  // Create an anchor element to trigger the download
  var a = document.createElement('a');
  a.href = url;
  a.download = this.subject + ".html"; // Specify the desired file name (e.g., "myFile.html")

  // Programmatically click the anchor element to trigger the download
  a.click();
  this.gmailservice.downloadgmailfile(this.threadid).subscribe((respose: any) => {
            this.allattachments = respose;  
            
            for(var i = 0; i < this.allattachments.length; i++){
              for(var j = 0; j < this.allattachments[i].attachments.length; j++){
               
                  // Code to execute after the timeout
              
                  window.open(this.allattachments[i].attachments[j].filebloblink,'_blank');
                 console.log("comming insides")
              }
              
            }
          
            console.log(respose);
        //    this.spinner.hide();
          });
    
};







// //pdf file is downloading but the image  is not displaying within them
// print2() {
//   const content = this.contentToConvert.nativeElement;

//   html2canvas(content).then(canvas => {
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF('p', 'mm', 'a4');
//     const width = pdf.internal.pageSize.getWidth();
//     const height = pdf.internal.pageSize.getHeight();
//     pdf.addImage(imgData, 'PNG', 0, 0, width, height);
//     pdf.save('your-document.pdf');
//   });
// }
}
