import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../Shared/MessageService';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';
import { Router } from '@angular/router';
import { ReportService } from '../Shared/Report/report.service';
import { WorkAllotmentService } from '../Shared/WorkAllotment/work-allotment.service';
import * as $ from 'jquery';
import { RoleService } from '../Shared/Role/role.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  @Input() inputstringcountry;
  message: any;
  isLoggedIn: boolean;
  subscription: Subscription;
  Menus: any;
  roleid: any;
  MenuList: any;
  MasterList: any;
  username: string;
  uid: string;
  ReportList: any
  FromDate: any;
  ToDate: any;
  BsafeMenus: any;
  mailMenus:any;
  installedb:any=false
  downpath: any;
  seleteddownload: boolean;
  PMTMenus: any;
  Menudata: any;
  drives: any;
  EnterPriseId: string;
  Enterprise: any;
  showaudit: boolean;
  RoleList: any;
  firstname: string;
  apiconsumer: string;
  apiconsumer1: boolean;
  constructor(private messageService: MessageService, private spinner: NgxSpinnerService,
    private menuservice: MenuMasterService, private router: Router, private reportservice: ReportService,
    private workallotmentservice: WorkAllotmentService,private roleService: RoleService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.roleid = localStorage.getItem("role");
      this.uid = localStorage.getItem("userId");
      this.message = true;
      this.isLoggedIn = true;
      this.username = localStorage.getItem("UserFullName");
      this.EnterPriseId = localStorage.getItem("EnterPriseId");
      this.firstname = localStorage.getItem("FirstName");
      this.apiconsumer = localStorage.getItem("IsApiConsumer");
      if(this.apiconsumer == "true"){
        this.apiconsumer1 = true;
      }
      this.GetAllMenus();
      this.GetBsafeMenus();
      this.GetPMTMenus();
      this.GetMailboxMenus();
      this.GetDriveMenus();
      this.GetEnterprise();
      this.Roles();
    }
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message.text == "true") {
        this.isLoggedIn = true;
      }
    });
  }
;
  ngOnInit(): void {
     
  }
  GotoVideoCall(){
    this.router.navigate(['/hostevent']);
  }
  GetAllMenus() {
    this.spinner.show();
    this.menuservice.getusermenus(this.roleid).subscribe((response: any) => {
      this.Menus = response;
      this.MenuList = this.Menus.menusList;
      this.MasterList = this.Menus.masterList;
      this.Menudata = this.Menus.Menudata;
      this.spinner.hide();

    })
  }
  Roles() {
    this.spinner.show();
    this.roleService.getAllRolesName(this.roleid).subscribe((Data: any) => {
       this.RoleList = Data;
      if(this.RoleList == "Auditor"){
        this.showaudit = true;
      }
      else {
        this.showaudit = false;
      } 
      this.spinner.hide();
    });
  }

  GetEnterprise() {
    this.spinner.show();
    this.menuservice.getAllUsers(this.EnterPriseId).subscribe((Data: any) => {
      this.Enterprise = Data;
      this.spinner.hide();
    });
  }


  GetBsafeMenus() {
    this.spinner.show();
    this.menuservice.getbsafemenus().subscribe((response: any) => {
      this.BsafeMenus = response;
          this.spinner.hide();
    })
  }
  GetPMTMenus() {
    this.spinner.show();
    this.menuservice.getpmtmenus(this.roleid).subscribe((response: any) => {
      this.PMTMenus = response;
          this.spinner.hide();
    })
  }
  GetMailboxMenus() {
    this.spinner.show();
    this.menuservice.getmailenus().subscribe((response: any) => {
      this.mailMenus = response;
          this.spinner.hide();
    })
  }

  GetDriveMenus() {
    this.spinner.show();
    this.menuservice.getdrivemenus().subscribe((response: any) => {
      this.drives = response;
          this.spinner.hide();
    })
  }
  Logout() {
    this.isLoggedIn = false;
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.removeItem("employee")
    localStorage.removeItem('userToken');
    localStorage.clear();
    this.spinner.show();
    this.menuservice.logoutsession(this.uid).subscribe((data: any) => {
      this.spinner.hide();
    });
    this.router.navigate(['/home']);
  }
  ShowDetails(menu) {
    this.router.navigate(['report/' + menu]);
  }
  togglem(){

  }
  DownloadFile() {
   
       // this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/blocdrive.exe";
       // this.downpath="https://accountdatastorage.blob.core.windows.net/pictures/blocdrive.exe";
       //this.downpath="https://accountdatastorage.blob.core.windows.net/pictures/BlocDrive%20Installer.zip";
       this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/SrichidDrive%20Installer.zip";
        this.seleteddownload = true;
        window.open(this.downpath);
     
  
  }

  DownloadMigrateFile()
  {
   // this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/SrichidMigrate%20Installer.zip";

    this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/task-photo-550a7000-9c85-4bca-b240-cfa3769d66b3.zip";
    this.seleteddownload = true;
    window.open(this.downpath);
  }
  DownloadSSDNasdrivefile(){
    // this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/SrichidMigrate%20Installer.zip";

    this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/task-photo-70bd5e8f-94a6-47b8-a47f-10b3077bcbc0.zip";
    this.seleteddownload = true;
    window.open(this.downpath);
 }

 DownloadGmailMigratefile(){
   this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/GmailMigration.zip";

 // this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/task-photo-57d120d4-45eb-4c56-ba73-aec15fb6ca5c.zip";
  this.seleteddownload = true;
  window.open(this.downpath);
 }


  DownloadGoogleDriveFiles(){
    this.menuservice.downloadGoogleDriveFiles().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });

    }
  
  DownloadFilePortable() {
    this.menuservice.downloadFileportable().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });

    }
}
