import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatDetail } from 'src/app/ViewModels/ChatDetail';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { Router, ActivationEnd, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalServiceService } from 'src/app/Shared/Modalpopup/modal-service.service';
import { UserId } from 'src/app/ViewModels/UserId';
import { IndividualChatDetails } from 'src/app/ViewModels/IndividualChatDetails';
import { NgForm } from '@angular/forms';
import { Friend } from 'src/app/ViewModels/Friend';
import * as $ from 'jquery'
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  b: any[] = [1, 2, 8, 5];

  className: string;
  chatdetail: ChatDetail;
  Users: any = [];
  userid: any;
  isLoggedIn: boolean;
  fullName: string;
  touserid: any;
  chatopen: boolean;
  IndividualChatDetail: any;
  uid: boolean;
  chattoid: any;
  chatvar: any;
  chattouserid: any;
  chaatto: any;
  aaaaa: ChatDetail;
  IndividualChatRecivedDetails: any;
  IndividualChatSentDetails: any;
  UserImage: any;
  BMid: string;
  touser: any;
  currentuser: string;
  data: any;
  chtnm: any;
  cnnTableData: any = [];
  closeResult: string;
  accid: any;
  m: any;
  Friends: any;
  accountid: string;
  message: any;
  GUID: string;
  displyfromuser: any;
  bothguids: string;
  public dismissalert = false;
  notify: string;
  chatfromname: any;
  chatfromguid: any;

  constructor(
    private userservice: UserDetailsService,
    private router: Router,
    private modalService1: ModalServiceService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,private auditortransactionservice:AuditorTransactionService
  ) {
    this.route.queryParams.subscribe((repsonse: any) => {
      if (repsonse['action'] === "addfriend") {
        setTimeout(() => {
          var node = document.getElementById("menuadd");
          if (node) {
            node.click();
          }
          else {
            setTimeout(() => {
              var node = document.getElementById("menuadd");
              if (node) {
                node.click();
              }
            }, 2000);
          }
        }, 1000);
      }
    })
    // this.spinner.hide();
    window.scrollTo(0, 0);
    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.userid = localStorage.getItem('userId');
      this.accountid = localStorage.getItem('userId');
      this.GUID = localStorage.getItem("GUID");

    }
    this.isLoggedIn = true;
    //this.userid = Number(localStorage.getItem("userId"));
    this.currentuser = localStorage.getItem('UserFullName');
    this.BMid = localStorage.getItem('userId');
    setTimeout(() => {
      //<<<---    using ()=> syntax
      this.GetDetails();
    }, 3000);
  }
  frndrqst = false;
  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    } else {
      this.frndrqst = true;
    }
  }
  scrollFunction() {
    if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
      document.getElementById('nb').style.top = '0';
    } else {
      document.getElementById('nb').style.top = '-50px';
    }
    this.data.sort(
      (a, b) =>
        new Date(b.CREATE_TS).getTime() - new Date(a.CREATE_TS).getTime()
    );
  }

  ngOnInit() {
    this.GetUsers();
    this.loadChatDetail();
    this.GetDetails();
    setTimeout(() => {
      var footer = document.getElementById('footer');
      if (footer.style.display == 'none') {
        footer.style.display = 'block';
      }
    }, 1000);
    $(document).ready(function () {
      $('#action_menu_btn').click(function () {
        $('.action_menu').toggle();
      });
    });
  }

  OnSelectUser(id) {
    this.touserid = id;
    this.userservice.GetToUser(this.touserid).subscribe((respose: any) => {
      this.touser = respose;
    });
  }
  messa: any;
  SaveUserChat() {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: this.currentuser,
      ChatTo: this.touserid,
      ChatToName: this.touser.userName,
      ChatMessage: this.messa,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.touserid,
      GUID: this.GUID
    };
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
//......        alert('Message sent successfully!');
        this.loadChatDetail();
        const audit = {
          TransactionName:"Chat",
          TransactionType:"Send",
          TransactionDesc:"Message",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.messa = '';
      }
    });
  }

  GetUsers() {
    // const inputRequest: UserId = {
    //   userid: this.userid,
    //   GUID: this.GUID

    // };
    this.userservice
      .GetAllFriendsExceptMe(this.GUID)
      .subscribe((respose: any) => {
        this.Users = [];
        var Users = respose.friendsList;
        for (let i = 0; i < Users.length; i++) {
          if (Users[i]['friendGUID'] == this.GUID) {
            Users[i]['friendGUID'] = Users[i]['userGUID'];
          }
          this.Users.push(Users[i]);
        }
        console.log(this.Users);
      });
  }

  GetCurrentUserImage() {
    this.userservice
      .getCurrentUSerImage(this.GUID)
      .subscribe((respose: any) => {
        this.UserImage = respose;
      });
  }

  loadChatDetail() {
    this.userservice
      .GetChatforParticularUser(this.GUID)
      .subscribe((chat: any) => {
        this.chatdetail = chat;
      });
  }
  backup() {
    this.spinner.show();
    this.bothguids=this.GUID + "," + this.chattouserid;
    this.userservice.chacbackup(this.bothguids).subscribe((data: any) => {
      if (data == 1) {
        alert(
          'Your chat messages back up was successfull !! Find it in Safe -> My Objects -> Chat Backups'
        );
      } 
      else if(data == 11){
        this.spinner.hide();
      }
      else {
        alert('Something went wrong!!! Please try again after sometime');
      }
      this.spinner.hide();
      // if (data == 1) {
      //   alert(
      //     'Your chat messages back up was successfull ! Find it in Safe -> My Objects -> Chat Backups'
      //   );
      // } else {
      //   alert('Something went wrong!!! Please try again after sometime');
      // }
      // this.spinner.hide();

    });
  }
  OpenChatWindow(chat) {
    if(this.currentuser==chat.chatFromName){
      this.displyfromuser=chat.toName
    }
    else{
      this.displyfromuser=chat.chatFromName

    }
    this.chatvar = chat;
    this.chattouserid = chat.chatFrom;
    this.chaatto = chat.guid;
    this.chatopen = true;
    const inputRequest: IndividualChatDetails = {
      Chatid: chat.id,
      ChatFrom: chat.chatFrom,
      ChatTo: chat.guid,
    };
    this.userservice
      .GetIndividualChatRecivedDetails(inputRequest)
      .subscribe((data: any) => {
        // this.IndividualChatRecivedDetails = data.recivedchatdetails;
        this.IndividualChatSentDetails = data;
        console.log(this.IndividualChatSentDetails);
        for (let i = 0; i < this.IndividualChatSentDetails.length; i++) {
          if (
            this.IndividualChatSentDetails[i].chatFromName != this.currentuser
          ) {
            console.log(this.IndividualChatSentDetails);
            this.chtnm = this.IndividualChatSentDetails[i].chatFromName;
            break;
          }
        }

        if (this.GUID == chat.chatTo) {
          this.uid = true;
        }
      });
  }


  Deletechat(deletedata, delchat) {
    this.chatfromname = deletedata.chatFromName;
    this.chatfromguid = deletedata.chatFrom;
     this.modalService.open(delchat).result.then(
       (result) => {
         this.closeResult = `Closed with: ${result}`;
       },
       (reason) => {
         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       }
     );
   }
 
 
   Deletegrouprow() {
     this.spinner.show();
     this.userservice.deletechat(this.chatfromguid).subscribe((data: any) => {
       if (data == "Success") {
         this.notify = "Chat deleted successfully";
        
         setTimeout(() => (this.dismissalert = true), 100);
         setTimeout(
           function () {
             this.dismissalert = false;
           }.bind(this),
           3000
         );
      window.location.reload();
         this.spinner.hide();
       } else {
         this.notify = "Something Went Wrong. Try again.!!";
         setTimeout(() => (this.dismissalert = true), 100);
         setTimeout(
           function () {
             this.dismissalert = false;
           }.bind(this),
           3000
         );
         this.loadChatDetail();
         this.spinner.hide();
       }
     });
   }



  goto() {
    this.router.navigate(['chat']);
  }
  SendIndividualMessage(form: NgForm) {
    const inputRequest: ChatDetail = {
      Chatid: 0,
      ChatFrom: this.userid,
      ChatFromName: '',
      ChatTo: this.chaatto,
      ChatToName: '',
      ChatMessage: form.controls['individualchatmessage'].value,
      IsEnabled: true,
      ChatFromUId: this.BMid,
      ChatToUserUId: this.chattouserid,
      GUID: this.GUID
    };
    this.aaaaa = inputRequest;
    this.userservice.saveuserChat(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.backup();
        this.OpenChatWindow(this.chatvar);
        (<HTMLInputElement>(
          document.getElementById('individualchatmessage')
        )).value = '';
      
      }
      const audit = {
        TransactionName:"Chat",
        TransactionType:"Send",
        TransactionDesc:"Message",

        }
        this.auditortransactionservice.AuditorTransactionDetailsChat(audit);
      // const audit = {
      //   TransactionName:"Chat",
      //   TransactionType:"Send",
      //   TransactionDesc:"Message",

      //   }
      //   this.auditortransactionservice.AuditorTransactionDetailsChat(audit);
    });
  }

  CloseChatWindow() {
    this.chatopen = false;
  }
  frndslist: any;
  GetDetails() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      var idd = this.accid;
      if (idd) {
        for (let i = 0; i < this.frndslist.length; i++) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + ' has accepted your friend request');
              this.accid = '';
            }
            this.GetUsers();
          }
        }
      } else {
        this.GetUsers();
      }
      this.spinner.hide();
    });
  }

  addFriend(addfriendcontent) {
    this.modalService.open(addfriendcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.accid;
      }
    );
  }
  savefrienddetails() {
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID: this.GUID

    };
    localStorage.accid = this.accid;
    this.spinner.show();
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == 'SameUser') {
        alert('Please enter your Friend MailId!!');
        this.GetDetails();
      } else if (data == 'alreadyfriends') {
        alert('You are already friends!!');
        this.GetDetails();
      } else if (data == 'NoUserFound') {
        alert('We are sorry ' + this.accid + ' is not using Blocdrive!!');
        this.GetDetails();
      } else if (data == 'SentRequest') {
        alert('You have already sent or recived request from this user!!');
        this.GetDetails();
      } else if (data == '1') {
        alert('Friend request sent successfully!');
        this.GetDetails();
      } else {
        alert('Something went wrong');
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = '';
    });
  }
  acceptrequest(f) {
    this.userservice.AcceptReq(f).subscribe((data: any) => {
      alert('Accepted successfully');
      // this.route.navigate(['/allfolders']);
      this.GetDetails();
    });
  }

  ViewDocument(bb, pdfcontent) {
    this.modalService.open(pdfcontent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = 'yvtfcfu';
      }
    );
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService1.close(id);
  }
}