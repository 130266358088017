import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Department } from 'src/app/ViewModels/Department';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  readonly url="https://blocdrive.com/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  saveDepartment(data:Department){
    return this.http.post(this.url +"Department/PostSaveDepartment",data);
  }
  getAllDepartmentdata(id){
    return this.http.get(this.url +"Department/Getdepartmentdata/" + id);
  }

  
  getDesignationsdata(id){
    return this.http.get(this.url +"Designation/GetDesignationdata/" + id);
  }
  getAllDepartments(id){
    return this.http.get(this.url +"Department/GetAllDepartments/" + id);
  }

  deleteDepartment(id: number){
    return this.http.get(this.url +"Department/GetDeleteDepartmentData/"+ id);
  }

  UpdateDepartment(data:Department){
    return this.http.post(this.url +"Department/PutDepartment",data);
  }
  getParticularDepart(id: number){
    return this.http.get(this.url +"Department/GetParticularDepartments/"+ id);
  }
}
