import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { ChangePassword } from 'src/app/ViewModels/ChangePassword';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  profile: any;
  isLoggedIn: boolean;
  uid: string;
  accid: string;
  closeResult: string;
  m: string;


  userImage: any;
  p1: any;
  p2: any;
  displayUID: string;
  IsEnterprise: boolean;
  GUID: string;
  logoexist: boolean;
  photoexist: boolean;
  emailexist: boolean;
  phoneexist: boolean;
  EnterPriseId: string;
  Enterprise: boolean;
  storagedata: string;
  profpic: any;
  constructor(private userservice: UserDetailsService, private router: Router, private modalService: NgbModal
    , private spinner: NgxSpinnerService,private httpService: HttpClient) {
    this.spinner.show();
    this.displayUID = localStorage.getItem("userId");
    window.scrollTo(0, 0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.accid = localStorage.getItem("uid");
      this.GUID=localStorage.getItem("GUID");
      this.EnterPriseId=localStorage.getItem("EnterPriseId");
      
      if (this.EnterPriseId == "null") {
        this.IsEnterprise = true;
        this.storagedata = "20 GB";
        this.photoexist = true;
      }
      else {
        this.Enterprise = true;
        this.IsEnterprise = false;
        this.storagedata = "50 GB";
        this.logoexist = true;
      }
    }
  }

  ngOnInit() {
    this.GetProfileDetails();
    setTimeout(() => {
      var dataid = document.getElementById("storage");
      if (dataid) {
        dataid.style.display = "none";
      }
      var cashid = document.getElementById("cashid");
      if (cashid) {
        cashid.style.display = "none";
      }
    }, 100);

    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer)
        if (footer.style.display == "none") {
          footer.style.display = "block";
        }
    }, 1000);
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }
  GetProfileDetails() {
    this.userservice.getProfileDetails(this.GUID).subscribe((respose: any) => {
      this.profile = respose;
      // if(this.profile.userImage==null){
      //   this.logoexist=true
      // }
      // else{
      //   this.photoexist=true
      // }
      // if(this.profile.phoneNumber==null){
      //   this.emailexist=true
      // }
      // else{
      //   this.phoneexist=true
      // }
      localStorage.points = this.profile.points;
      if (this.profile.points == null) {
        this.profile.points = 0;
        localStorage.points = 0;

      }
      localStorage.totalRegistrations = this.profile.totalRegistrations;

      if (this.profile.totalRegistrations == null) {
        this.profile.totalRegistrations = 0;
        localStorage.totalRegistrations = 0;

      }
      this.spinner.hide();

    });
  }

  changePassword() {
    this.router.navigate(['/changepswd']);
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.userImage = btoa(binaryString);
    this.userservice.updateProfile(this.userImage).subscribe((data: any) => {
      if (data == "1") {
        alert()
      }
    })
  }

  ViewDocument(bb, pdfcontent) {

    this.modalService.open(pdfcontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = "yvtfcfu";
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ChangePassword(form: NgForm) {
    this.p1 = form.controls["passwordone"].value;
    this.p2 = form.controls["passwordtwo"].value;
    if (this.p1 != this.p2) {
      alert("Enter Password Correctly")
    }
    else {

      const inputRequest: ChangePassword = {
        userId: localStorage.userId,
        Password: form.controls["passwordone"].value,
        ConfirmPassword: form.controls["passwordtwo"].value,
      }
      this.spinner.show()
      this.userservice.postchangePasswordAfterLogin(inputRequest).subscribe(
        (data: any) => {
          if (data == null) {
            alert("Your Password Was Successfully Changed");
            this.spinner.hide()

            this.router.navigate(['/profile']);
          } else {
            alert("Something Went Wrong. Try again.!!");
            window.location.reload();
          }
        });
    }
  }
  redeemdata() {
    document.getElementById("storage").style.display = "block";
  }
  redeemd() {
    this.userservice.redeemdata(this.displayUID).subscribe((data: any) => {
      localStorage.storage = data;
      alert("Storage has been upgraded");
      location.reload();
    });
  }
  redeemcash() {
    document.getElementById("cashid").style.display = "block";
  }
  redeemcashok() {
    this.router.navigate(['/redeemcash']);
  }
  handleFileInput2(files: File) {
    this.profpic = files[0];
  }
  saveprofilepic() {
 
    const frmData = new FormData();
    frmData.append('fileUpload', this.profpic);
    frmData.append('EnterPriseId', this.EnterPriseId);
    frmData.append('userId', this.uid);

    this.httpService
      .post('https://blocdrive.com/blocdriveapi/api/AuManagement/updateprofilepic/', frmData)
      .subscribe(
        (data) => {
          // SHOW A MESSAGE RECEIVED FROM THE WEB API.

          if (data == '1') {
            //document.getElementById("cancelUpload").click();

            alert('File Uploaded Successfully');
            this.GetProfileDetails();
            // this.();
          } else {
            alert('Something went wrong. Please try again ! ');
          }
        },
        (err: HttpErrorResponse) => {
          alert('Something went wrong. Please try again ! ');
          console.log(err.message); // Show error, if any.
        }
      );
  }








}